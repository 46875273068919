import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PassEurope from '../components/Pass/PassEurope';
import PassDubai from '../components/Pass/PassDubai';
import PassLondon from '../components/Pass/PassLondon';
import Cookies from 'js-cookie';
import { axiosClient } from '../constants';

function PassView() {
  const params = useParams();
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    if (Cookies.get("USER_TOKEN")) {
      const token = Cookies.get("USER_TOKEN");

      axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } }).then(resp => {
        setOrderData(resp.data.data);
      }).catch(error => {
        console.log(error);
      })

    }
  }, []);

  if (params.passType === "europe") return <PassEurope orderData={orderData} />
  else if (params.passType === "dubai") return <PassDubai orderData={orderData} />
  else if (params.passType === "london") return <PassLondon orderData={orderData} />
  else return null;
}

export default PassView