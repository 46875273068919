import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../constants';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default function Signup2({ setStep, input1, setInput1 }) {
  const [profileImg, setProfileImg] = useState(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `$( ".input-field__countrycode" ).selectmenu();`;
    document.body.appendChild(script);
  }, []);

  const onChangeHandl = (e) => {
    setInput1({
      ...input1,
      [e.target.name]: e.target.value
    })
  }
  const register = async (e) => {
    e.preventDefault();
    if (input1.email === '') {
      toast.error('Please Enter Email!!!')
      return
    }
    if (input1.phone === '') {
      toast.error('Please Enter Phone Number!!!')
      return
    }
    if (input1.password != input1.rpassword) {
      toast.error('Passwords does not matched!!!');
      return
    }
    try {
      const data = new FormData();
      data.append("email", input1.email);
      data.append("phone", input1.phone);
      data.append("password", input1.password);
      data.append("first_name", input1.fName);
      data.append("last_name", input1.lName);
      data.append("date_of_birth", input1.dob);
      data.append("gender", input1.gender);
      data.append("sponsor_address", input1.referralEmail);
      data.append("account_type", input1.account_type);
      if(profileImg){
        data.append("profile_pic", profileImg);
      }

      const res = await axiosClient.post("/accounts/signup", data, { headers: { "Content-Type": "multipart/form-data" }});
      Cookies.set("OTP_TIME", JSON.stringify(new Date()));
      toast.success("OTP successfully sent to you email and mobile.");
      setStep(4);
    } catch (error) {
      if (error?.response?.data?.email) {
        toast.error(error?.response?.data?.email[0]);
      } else if (error?.response?.data?.phone) {
        toast.error(error?.response?.data?.phone[0]);
      }
    }
  }

  const onProfilePicChange = (evt) => {
    if (evt.target.files?.length > 0) {
      setProfileImg(evt.target.files[0]);
    }
  }

  return (
    <>
      <div>
        <div className="bg-setup bg-setup--login">
          <div className="blobs" />
        </div>
        <div className="main-page">
          <div className="container signup-documentation">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a href="/">
                  <img src="/assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="sponsor sponsor-center">
              <div className="sponsor__left signup-5 d-flex justify-content-center align-items-center">
                <button className='profile-pic-button'>
                  <div className='inner-border position-relative'>
                    <div className='cards123__bg' />
                    {
                      profileImg &&
                      <button onClick={() => { setProfileImg(null); document.getElementById("profile_input").value = ""; }}><svg width='32' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                    }
                    <button className='main-img border-0 position-relative' onClick={() => { document.getElementById("profile_input").click() }}>
                      {
                        profileImg ?
                          <>
                            <img src={URL.createObjectURL(profileImg)} />
                          </> :
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="13" r="3" stroke="#ffffff" stroke-width="1.5"></circle> <path d="M3.0001 12.9999C3.0001 10.191 2.99995 8.78673 3.67407 7.77783C3.96591 7.34107 4.34091 6.96607 4.77767 6.67423C5.78656 6.00011 7.19103 6.00011 9.99995 6.00011H14C16.8089 6.00011 18.2133 6.00011 19.2222 6.67423C19.659 6.96607 20.034 7.34107 20.3258 7.77783C21 8.78673 21.0001 10.191 21.0001 12.9999C21.0001 15.8088 21.0001 17.2133 20.326 18.2222C20.0341 18.6589 19.6591 19.0339 19.2224 19.3258C18.2135 19.9999 16.809 19.9999 14.0001 19.9999H10.0001C7.19117 19.9999 5.78671 19.9999 4.77782 19.3258C4.34106 19.0339 3.96605 18.6589 3.67422 18.2222C3.44239 17.8752 3.29028 17.4815 3.19049 16.9999" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M18 10H17.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M14.5 3.5H9.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                      }
                    </button>
                  </div>
                </button>
                <input type="file" accept='image/*' onChange={onProfilePicChange} style={{ display: 'none' }} id="profile_input" />
                {/* </div> */}
              </div>
              <div className="sponsor__right">
                <div className="w-100 mx-auto sponsor__right__inner">
                  <div className="auth-logo signup-logo-header">
                    <img src="./assets/images/header-logo.svg" alt="logo" />
                  </div>
                  <div className="sign-up-header mt-2 signup-text-header">
                    <p className="fw500">
                      Help Us Identify You.
                    </p>
                  </div>
                  <form className="mt-4 form signup-form" onSubmit={register}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="input-text-outer mb-3">
                          <div className="input-bg"><div className="input-bg-inner" /></div>
                          <div className="input-field">
                            <span className="input-field__prefix"><i className="icon icon-user" /></span>
                            <input type="text" placeholder="First Name" name='fName' value={input1.fName} onChange={onChangeHandl} required/>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="input-text-outer mb-3">
                          <div className="input-bg"><div className="input-bg-inner" /></div>
                          <div className="input-field">
                            <span className="input-field__prefix"><i className="icon icon-user" /></span>
                            <input type="text" placeholder="Last Name" name='lName' value={input1.lName} onChange={onChangeHandl} required/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix"><img src="./assets/images/icons/email.svg" /></span>
                        <input type="text" placeholder="Email" value={input1.email} onChange={(e) => setInput1({ ...input1, email: e.target.value })} required/>
                      </div>
                    </div>
                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix"><img src="../assets/images/icons/phone.svg" /></span>
                        <span className="input-field__prefix">
                          <select className="input-field__countrycode">
                            <option data-countrycode="GB" value={44} selected>UK (+44)</option>
                            <option data-countrycode="US" value={1}>USA (+1)</option>
                            <option data-countrycode="DZ" value={213}>Algeria (+213)</option>
                            <option data-countrycode="AD" value={376}>Andorra (+376)</option>
                            <option data-countrycode="AO" value={244}>Angola (+244)</option>
                            <option data-countrycode="AI" value={1264}>Anguilla (+1264)</option>
                            <option data-countrycode="AG" value={1268}>Antigua &amp; Barbuda (+1268)</option>
                            <option data-countrycode="AR" value={54}>Argentina (+54)</option>
                            <option data-countrycode="AM" value={374}>Armenia (+374)</option>
                            <option data-countrycode="AW" value={297}>Aruba (+297)</option>
                            <option data-countrycode="AU" value={61}>Australia (+61)</option>
                            <option data-countrycode="AT" value={43}>Austria (+43)</option>
                            <option data-countrycode="AZ" value={994}>Azerbaijan (+994)</option>
                            <option data-countrycode="BS" value={1242}>Bahamas (+1242)</option>
                            <option data-countrycode="BH" value={973}>Bahrain (+973)</option>
                            <option data-countrycode="BD" value={880}>Bangladesh (+880)</option>
                            <option data-countrycode="BB" value={1246}>Barbados (+1246)</option>
                            <option data-countrycode="BY" value={375}>Belarus (+375)</option>
                            <option data-countrycode="BE" value={32}>Belgium (+32)</option>
                            <option data-countrycode="BZ" value={501}>Belize (+501)</option>
                            <option data-countrycode="BJ" value={229}>Benin (+229)</option>
                            <option data-countrycode="BM" value={1441}>Bermuda (+1441)</option>
                            <option data-countrycode="BT" value={975}>Bhutan (+975)</option>
                            <option data-countrycode="BO" value={591}>Bolivia (+591)</option>
                            <option data-countrycode="BA" value={387}>Bosnia Herzegovina (+387)</option>
                            <option data-countrycode="BW" value={267}>Botswana (+267)</option>
                            <option data-countrycode="BR" value={55}>Brazil (+55)</option>
                            <option data-countrycode="BN" value={673}>Brunei (+673)</option>
                            <option data-countrycode="BG" value={359}>Bulgaria (+359)</option>
                            <option data-countrycode="BF" value={226}>Burkina Faso (+226)</option>
                            <option data-countrycode="BI" value={257}>Burundi (+257)</option>
                            <option data-countrycode="KH" value={855}>Cambodia (+855)</option>
                            <option data-countrycode="CM" value={237}>Cameroon (+237)</option>
                            <option data-countrycode="CA" value={1}>Canada (+1)</option>
                            <option data-countrycode="CV" value={238}>Cape Verde Islands (+238)</option>
                            <option data-countrycode="KY" value={1345}>Cayman Islands (+1345)</option>
                            <option data-countrycode="CF" value={236}>Central African Republic (+236)</option>
                            <option data-countrycode="CL" value={56}>Chile (+56)</option>
                            <option data-countrycode="CN" value={86}>China (+86)</option>
                            <option data-countrycode="CO" value={57}>Colombia (+57)</option>
                            <option data-countrycode="KM" value={269}>Comoros (+269)</option>
                            <option data-countrycode="CG" value={242}>Congo (+242)</option>
                            <option data-countrycode="CK" value={682}>Cook Islands (+682)</option>
                            <option data-countrycode="CR" value={506}>Costa Rica (+506)</option>
                            <option data-countrycode="HR" value={385}>Croatia (+385)</option>
                            <option data-countrycode="CU" value={53}>Cuba (+53)</option>
                            <option data-countrycode="CY" value={90392}>Cyprus North (+90392)</option>
                            <option data-countrycode="CY" value={357}>Cyprus South (+357)</option>
                            <option data-countrycode="CZ" value={42}>Czech Republic (+42)</option>
                            <option data-countrycode="DK" value={45}>Denmark (+45)</option>
                            <option data-countrycode="DJ" value={253}>Djibouti (+253)</option>
                            <option data-countrycode="DM" value={1809}>Dominica (+1809)</option>
                            <option data-countrycode="DO" value={1809}>Dominican Republic (+1809)</option>
                            <option data-countrycode="EC" value={593}>Ecuador (+593)</option>
                            <option data-countrycode="EG" value={20}>Egypt (+20)</option>
                            <option data-countrycode="SV" value={503}>El Salvador (+503)</option>
                            <option data-countrycode="GQ" value={240}>Equatorial Guinea (+240)</option>
                            <option data-countrycode="ER" value={291}>Eritrea (+291)</option>
                            <option data-countrycode="EE" value={372}>Estonia (+372)</option>
                            <option data-countrycode="ET" value={251}>Ethiopia (+251)</option>
                            <option data-countrycode="FK" value={500}>Falkland Islands (+500)</option>
                            <option data-countrycode="FO" value={298}>Faroe Islands (+298)</option>
                            <option data-countrycode="FJ" value={679}>Fiji (+679)</option>
                            <option data-countrycode="FI" value={358}>Finland (+358)</option>
                            <option data-countrycode="FR" value={33}>France (+33)</option>
                            <option data-countrycode="GF" value={594}>French Guiana (+594)</option>
                            <option data-countrycode="PF" value={689}>French Polynesia (+689)</option>
                            <option data-countrycode="GA" value={241}>Gabon (+241)</option>
                            <option data-countrycode="GM" value={220}>Gambia (+220)</option>
                            <option data-countrycode="GE" value={7880}>Georgia (+7880)</option>
                            <option data-countrycode="DE" value={49}>Germany (+49)</option>
                            <option data-countrycode="GH" value={233}>Ghana (+233)</option>
                            <option data-countrycode="GI" value={350}>Gibraltar (+350)</option>
                            <option data-countrycode="GR" value={30}>Greece (+30)</option>
                            <option data-countrycode="GL" value={299}>Greenland (+299)</option>
                            <option data-countrycode="GD" value={1473}>Grenada (+1473)</option>
                            <option data-countrycode="GP" value={590}>Guadeloupe (+590)</option>
                            <option data-countrycode="GU" value={671}>Guam (+671)</option>
                            <option data-countrycode="GT" value={502}>Guatemala (+502)</option>
                            <option data-countrycode="GN" value={224}>Guinea (+224)</option>
                            <option data-countrycode="GW" value={245}>Guinea - Bissau (+245)</option>
                            <option data-countrycode="GY" value={592}>Guyana (+592)</option>
                            <option data-countrycode="HT" value={509}>Haiti (+509)</option>
                            <option data-countrycode="HN" value={504}>Honduras (+504)</option>
                            <option data-countrycode="HK" value={852}>Hong Kong (+852)</option>
                            <option data-countrycode="HU" value={36}>Hungary (+36)</option>
                            <option data-countrycode="IS" value={354}>Iceland (+354)</option>
                            <option selected data-countrycode="IN" value={91}>India (+91)</option>
                            <option data-countrycode="ID" value={62}>Indonesia (+62)</option>
                            <option data-countrycode="IR" value={98}>Iran (+98)</option>
                            <option data-countrycode="IQ" value={964}>Iraq (+964)</option>
                            <option data-countrycode="IE" value={353}>Ireland (+353)</option>
                            <option data-countrycode="IL" value={972}>Israel (+972)</option>
                            <option data-countrycode="IT" value={39}>Italy (+39)</option>
                            <option data-countrycode="JM" value={1876}>Jamaica (+1876)</option>
                            <option data-countrycode="JP" value={81}>Japan (+81)</option>
                            <option data-countrycode="JO" value={962}>Jordan (+962)</option>
                            <option data-countrycode="KZ" value={7}>Kazakhstan (+7)</option>
                            <option data-countrycode="KE" value={254}>Kenya (+254)</option>
                            <option data-countrycode="KI" value={686}>Kiribati (+686)</option>
                            <option data-countrycode="KP" value={850}>Korea North (+850)</option>
                            <option data-countrycode="KR" value={82}>Korea South (+82)</option>
                            <option data-countrycode="KW" value={965}>Kuwait (+965)</option>
                            <option data-countrycode="KG" value={996}>Kyrgyzstan (+996)</option>
                            <option data-countrycode="LA" value={856}>Laos (+856)</option>
                            <option data-countrycode="LV" value={371}>Latvia (+371)</option>
                            <option data-countrycode="LB" value={961}>Lebanon (+961)</option>
                            <option data-countrycode="LS" value={266}>Lesotho (+266)</option>
                            <option data-countrycode="LR" value={231}>Liberia (+231)</option>
                            <option data-countrycode="LY" value={218}>Libya (+218)</option>
                            <option data-countrycode="LI" value={417}>Liechtenstein (+417)</option>
                            <option data-countrycode="LT" value={370}>Lithuania (+370)</option>
                            <option data-countrycode="LU" value={352}>Luxembourg (+352)</option>
                            <option data-countrycode="MO" value={853}>Macao (+853)</option>
                            <option data-countrycode="MK" value={389}>Macedonia (+389)</option>
                            <option data-countrycode="MG" value={261}>Madagascar (+261)</option>
                            <option data-countrycode="MW" value={265}>Malawi (+265)</option>
                            <option data-countrycode="MY" value={60}>Malaysia (+60)</option>
                            <option data-countrycode="MV" value={960}>Maldives (+960)</option>
                            <option data-countrycode="ML" value={223}>Mali (+223)</option>
                            <option data-countrycode="MT" value={356}>Malta (+356)</option>
                            <option data-countrycode="MH" value={692}>Marshall Islands (+692)</option>
                            <option data-countrycode="MQ" value={596}>Martinique (+596)</option>
                            <option data-countrycode="MR" value={222}>Mauritania (+222)</option>
                            <option data-countrycode="YT" value={269}>Mayotte (+269)</option>
                            <option data-countrycode="MX" value={52}>Mexico (+52)</option>
                            <option data-countrycode="FM" value={691}>Micronesia (+691)</option>
                            <option data-countrycode="MD" value={373}>Moldova (+373)</option>
                            <option data-countrycode="MC" value={377}>Monaco (+377)</option>
                            <option data-countrycode="MN" value={976}>Mongolia (+976)</option>
                            <option data-countrycode="MS" value={1664}>Montserrat (+1664)</option>
                            <option data-countrycode="MA" value={212}>Morocco (+212)</option>
                            <option data-countrycode="MZ" value={258}>Mozambique (+258)</option>
                            <option data-countrycode="MN" value={95}>Myanmar (+95)</option>
                            <option data-countrycode="NA" value={264}>Namibia (+264)</option>
                            <option data-countrycode="NR" value={674}>Nauru (+674)</option>
                            <option data-countrycode="NP" value={977}>Nepal (+977)</option>
                            <option data-countrycode="NL" value={31}>Netherlands (+31)</option>
                            <option data-countrycode="NC" value={687}>New Caledonia (+687)</option>
                            <option data-countrycode="NZ" value={64}>New Zealand (+64)</option>
                            <option data-countrycode="NI" value={505}>Nicaragua (+505)</option>
                            <option data-countrycode="NE" value={227}>Niger (+227)</option>
                            <option data-countrycode="NG" value={234}>Nigeria (+234)</option>
                            <option data-countrycode="NU" value={683}>Niue (+683)</option>
                            <option data-countrycode="NF" value={672}>Norfolk Islands (+672)</option>
                            <option data-countrycode="NP" value={670}>Northern Marianas (+670)</option>
                            <option data-countrycode="NO" value={47}>Norway (+47)</option>
                            <option data-countrycode="OM" value={968}>Oman (+968)</option>
                            <option data-countrycode="PW" value={680}>Palau (+680)</option>
                            <option data-countrycode="PA" value={507}>Panama (+507)</option>
                            <option data-countrycode="PG" value={675}>Papua New Guinea (+675)</option>
                            <option data-countrycode="PY" value={595}>Paraguay (+595)</option>
                            <option data-countrycode="PE" value={51}>Peru (+51)</option>
                            <option data-countrycode="PH" value={63}>Philippines (+63)</option>
                            <option data-countrycode="PL" value={48}>Poland (+48)</option>
                            <option data-countrycode="PT" value={351}>Portugal (+351)</option>
                            <option data-countrycode="PR" value={1787}>Puerto Rico (+1787)</option>
                            <option data-countrycode="QA" value={974}>Qatar (+974)</option>
                            <option data-countrycode="RE" value={262}>Reunion (+262)</option>
                            <option data-countrycode="RO" value={40}>Romania (+40)</option>
                            <option data-countrycode="RU" value={7}>Russia (+7)</option>
                            <option data-countrycode="RW" value={250}>Rwanda (+250)</option>
                            <option data-countrycode="SM" value={378}>San Marino (+378)</option>
                            <option data-countrycode="ST" value={239}>Sao Tome &amp; Principe (+239)</option>
                            <option data-countrycode="SA" value={966}>Saudi Arabia (+966)</option>
                            <option data-countrycode="SN" value={221}>Senegal (+221)</option>
                            <option data-countrycode="CS" value={381}>Serbia (+381)</option>
                            <option data-countrycode="SC" value={248}>Seychelles (+248)</option>
                            <option data-countrycode="SL" value={232}>Sierra Leone (+232)</option>
                            <option data-countrycode="SG" value={65}>Singapore (+65)</option>
                            <option data-countrycode="SK" value={421}>Slovak Republic (+421)</option>
                            <option data-countrycode="SI" value={386}>Slovenia (+386)</option>
                            <option data-countrycode="SB" value={677}>Solomon Islands (+677)</option>
                            <option data-countrycode="SO" value={252}>Somalia (+252)</option>
                            <option data-countrycode="ZA" value={27}>South Africa (+27)</option>
                            <option data-countrycode="ES" value={34}>Spain (+34)</option>
                            <option data-countrycode="LK" value={94}>Sri Lanka (+94)</option>
                            <option data-countrycode="SH" value={290}>St. Helena (+290)</option>
                            <option data-countrycode="KN" value={1869}>St. Kitts (+1869)</option>
                            <option data-countrycode="SC" value={1758}>St. Lucia (+1758)</option>
                            <option data-countrycode="SD" value={249}>Sudan (+249)</option>
                            <option data-countrycode="SR" value={597}>Suriname (+597)</option>
                            <option data-countrycode="SZ" value={268}>Swaziland (+268)</option>
                            <option data-countrycode="SE" value={46}>Sweden (+46)</option>
                            <option data-countrycode="CH" value={41}>Switzerland (+41)</option>
                            <option data-countrycode="SI" value={963}>Syria (+963)</option>
                            <option data-countrycode="TW" value={886}>Taiwan (+886)</option>
                            <option data-countrycode="TJ" value={7}>Tajikstan (+7)</option>
                            <option data-countrycode="TH" value={66}>Thailand (+66)</option>
                            <option data-countrycode="TG" value={228}>Togo (+228)</option>
                            <option data-countrycode="TO" value={676}>Tonga (+676)</option>
                            <option data-countrycode="TT" value={1868}>Trinidad &amp; Tobago (+1868)</option>
                            <option data-countrycode="TN" value={216}>Tunisia (+216)</option>
                            <option data-countrycode="TR" value={90}>Turkey (+90)</option>
                            <option data-countrycode="TM" value={7}>Turkmenistan (+7)</option>
                            <option data-countrycode="TM" value={993}>Turkmenistan (+993)</option>
                            <option data-countrycode="TC" value={1649}>Turks &amp; Caicos Islands (+1649)</option>
                            <option data-countrycode="TV" value={688}>Tuvalu (+688)</option>
                            <option data-countrycode="UG" value={256}>Uganda (+256)</option>
                            {/* <option data-countryCode="GB" value="44">UK (+44)</option> */}
                            <option data-countrycode="UA" value={380}>Ukraine (+380)</option>
                            <option data-countrycode="AE" value={971}>United Arab Emirates (+971)</option>
                            <option data-countrycode="UY" value={598}>Uruguay (+598)</option>
                            {/* <option data-countryCode="US" value="1">USA (+1)</option> */}
                            <option data-countrycode="UZ" value={7}>Uzbekistan (+7)</option>
                            <option data-countrycode="VU" value={678}>Vanuatu (+678)</option>
                            <option data-countrycode="VA" value={379}>Vatican City (+379)</option>
                            <option data-countrycode="VE" value={58}>Venezuela (+58)</option>
                            <option data-countrycode="VN" value={84}>Vietnam (+84)</option>
                            <option data-countrycode="VG" value={84}>Virgin Islands - British (+1284)</option>
                            <option data-countrycode="VI" value={84}>Virgin Islands - US (+1340)</option>
                            <option data-countrycode="WF" value={681}>Wallis &amp; Futuna (+681)</option>
                            <option data-countrycode="YE" value={969}>Yemen (North)(+969)</option>
                            <option data-countrycode="YE" value={967}>Yemen (South)(+967)</option>
                            <option data-countrycode="ZM" value={260}>Zambia (+260)</option>
                            <option data-countrycode="ZW" value={263}>Zimbabwe (+263)</option>
                          </select>
                        </span>
                        <input type="number" placeholder="Phone Number" maxLength={10} value={input1.phone} onChange={(e) => setInput1({ ...input1, phone: e.target.value })} required/>
                      </div>
                    </div>
                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 10V14M10.2676 11L13.7317 13M13.7314 11L10.2673 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M6.73241 10V14M4.99999 11L8.46409 13M8.46386 11L4.99976 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M17.2681 10V14M15.5356 11L18.9997 13M18.9995 11L15.5354 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C21.4816 5.82475 21.7706 6.69989 21.8985 8" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                        </span>
                        <input type="password" placeholder="Password" value={input1.password} onChange={(e) => setInput1({ ...input1, password: e.target.value })} required/>
                      </div>
                    </div>
                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 10V14M10.2676 11L13.7317 13M13.7314 11L10.2673 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M6.73241 10V14M4.99999 11L8.46409 13M8.46386 11L4.99976 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M17.2681 10V14M15.5356 11L18.9997 13M18.9995 11L15.5354 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C21.4816 5.82475 21.7706 6.69989 21.8985 8" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                        </span>
                        <input type="password" placeholder="Confirm Password" value={input1.rpassword} onChange={(e) => setInput1({ ...input1, rpassword: e.target.value })} required/>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="input-text-outer mb-3">
                          <div className="input-bg"><div className="input-bg-inner" /></div>
                          <div className="input-field">
                            <span className="input-field__prefix"><i className="icon icon-calendar" /></span>
                            <input type="date" placeholder="DOB" name="dob" value={input1.dob} onChange={onChangeHandl} required/>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="signup_detail mt-4 mb-3">
                          <div className="gender-checkboxes d-flex gap-3 justify-content-end">
                            <div className="check-container male-check">
                              <label className=" ">
                                <input className="form-check-input" name="gender" checked={input1.gender === "male"} onChange={onChangeHandl} value={"male"} type="radio" id="flexRadioDefault1" />
                                <span className="checkmark" />
                                <div className="fz16 fw400">
                                  Male
                                </div>
                              </label>
                            </div>
                            <div className="check-container male-check">
                              <label className=" ">
                                <input className="form-check-input" name="gender" checked={input1.gender === "female"} onChange={onChangeHandl} value={"female"} type="radio" id="flexRadioDefault2" />
                                <span className="checkmark" />
                                <div className="fz16 fw400">
                                  Female
                                </div>
                              </label>
                            </div>
                            <div className="check-container male-check">
                              <label className=" ">
                                <input className="form-check-input" name="gender" checked={input1.gender === "other"} onChange={onChangeHandl} value={"other"} type="radio" id="flexRadioDefault3" />
                                <span className="checkmark" />
                                <div className="fz16 fw400">
                                  Other
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="input-button-wrapper">
                      <button className="input-button">
                        <span className="input-button__inner">
                          <span className="input-button__text">Continue</span>
                          <span className="input-button__icon-outer">
                            <span className="input-button__icon-inner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
