import { APP_CONFIG, axiosClient } from '../../constants';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import useRazorpay from 'react-razorpay';

export default function PassLondon() {
  const Razorpay = useRazorpay();
  // const [show, setShow] = useState(false);
  const token = Cookies.get('USER_TOKEN');
  const data = Cookies.get("USER_DATA") ? JSON.parse(Cookies.get("USER_DATA")) : null;

  const buyPassWeb2 = async () => {
    let orderInfo = {};
    try {
      const headers = {
        "Authorization": "Token " + token
      }
      const apiRes = await axiosClient.post("/main/init-payment", {}, { headers });
      orderInfo = apiRes?.data?.data;

      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: "INR",
        name: "EnterX",
        description: "Test Transaction",
        image: window.location.href.replace("/pass", "") + "/assets/images/header-logo.svg",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClient.post("/main/payment-callback", response, { headers }).then((paymentCallbackResponse) => {
            const mReq = new FormData();
            mReq.append("step", "5");
            axiosClient.patch("/accounts/user-onboarding", mReq, { headers: { "Authorization": "Token " + token } }).then((response1) => {
              window.location.href = "/thankyoupass";
            }).catch(err1 => {
              toast.error("Step Added Failed!!!");
            })
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();

    } catch (error) {
      toast.error("Some Error in API!!!!");
    }
  }

  const buyPassWeb3 = async () => {
    const headers = {
      "Authorization": "Token " + token
    }
    try {
      const apiRes = await axiosClient.post("/main/init-payment", { is_meta_transaction: true }, { headers });
      const orderInfo = apiRes.data?.data;

      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const mAccounts = await provider.send("eth_requestAccounts", []);

        if (mAccounts?.length > 0) {
          const signer = provider.getSigner()
          const contract = new ethers.Contract(APP_CONFIG.SC_MAIN, APP_CONFIG.SC_MAIN_ABI, signer);
          const contract1 = new ethers.Contract(APP_CONFIG.SC_USDT, APP_CONFIG.SC_USDT_ABI, provider.getSigner());

          try {
            const usdtM = await contract1.allowance(mAccounts[0], APP_CONFIG.SC_MAIN);
            const amount1 = ethers.utils.parseEther("10000000");
            const amount = ethers.utils.parseEther("100");

            if (Number(usdtM) == 0) {
              const res1 = await contract1.approve(APP_CONFIG.SC_MAIN, amount1, { from: mAccounts[0] });
              await res1.wait();
              const res2 = await contract.Purchase(amount, orderInfo?.order_id, { from: mAccounts[0] });
              const trans = await res2.wait();
              console.log(trans);
              const apiReq = {
                transaction_id: orderInfo.order_id,
                web3_transaction_id: trans?.transactionHash
              }
              axiosClient.post("/main/create-order", apiReq, { headers }).then((response1) => {
                const mReq = new FormData();
                mReq.append("step", "5");
                axiosClient.patch("/accounts/user-onboarding", mReq, { headers }).then((response1) => {
                  window.location.href = "/thankyoupass";
                }).catch(err1 => {
                  toast.error("Step Added Failed!!!");
                })
              }).catch((error1) => {
                toast.error("Internal Serever Error in Create Order!!!")
              })
            } else {
              const res2 = await contract.Purchase(amount, orderInfo?.order_id, { from: mAccounts[0] });
              res2.wait();
              const trans = await res2.wait();
              const apiReq = {
                transaction_id: orderInfo.order_id,
                web3_transaction_id: trans?.transactionHash
              }
              axiosClient.post("/main/create-order", apiReq, { headers }).then((response1) => {
                const mReq = new FormData();
                mReq.append("step", "5");
                axiosClient.patch("/accounts/user-onboarding", mReq, { headers }).then((response1) => {
                  window.location.href = "/thankyoupass";
                }).catch(err1 => {
                  toast.error("Step Added Failed!!!");
                })
              }).catch((error1) => {
                toast.error("Internal Serever Error in Create Order!!!")
              })
            }
          } catch (error) {
            console.log(error, "2");
            toast.error("Web3 Transaction Error!!!");
          }
        }
      }
    } catch (error) {
      toast.error("Inter Server Error in /INIT-API");
    }
  }

  const removeHREF = () => {
    Cookies.remove("BackHREF");
  }

  return (
    <div>
      <div className="bg-setup">
        <div className="blobs">
        </div>
      </div>
      <div className="container position-relative">
        {/* Top actions btn row */}
        <div className="top-btns">
          <div className="top-btns__left">
            <a href={Cookies.get("BackHREF") ? Cookies.get("BackHREF") : "/pass"} onClick={removeHREF}>
              <img src="../assets/images/icons/back-icon.svg" alt="" />
            </a>
          </div>
        </div>
        <h1 className="page__title">The Best Way To Have Fun!</h1>
        <section className="passPage mt-4">
          <h2 className="passSectionCarouselTitle">LONDON <span className="separator">|</span> <span className="logo" /><img src="../assets/images/logo-white.svg" alt="" /></h2>
          <div className="passPage__offerText">
            <img src='/assets/images/offer.svg' height="150px" className='mx-auto mb-2' />
          </div>
          <div className="card111">
            <img className="card111__img" src="../assets/images/enterx-cards/card-red-london.svg" alt="..." />
            <img className="card111__barcode" src="../assets/images/enterx-cards/qr2.svg" alt="..." />
            <div className="card111__number">#000001</div>
            <div className="card111__code">Referral Code: BIG799BAOH01</div>
            <div className="card111__noOfSlots">03</div>
            <div className="card111__totalUsed">01</div>
            <div className="card111__lastUsed">March-30-2023</div>
            <div className="card111__validTill">March-30-2023</div>
            <div className="card111__starsRed"></div>
            <div className="card111__starsSilver">Direct Sales: 25</div>
            <div className="card111__starsGold">Direct Sales: 50</div>
            <div className="card111__starsPlatinum">Direct Sales: 100</div>
          </div>
          <div className="passPage__buttonDiv">
            <button className="input-button" data-bs-toggle="modal" data-bs-target="#comingSoonModal">
              <span className="input-button__inner">
                <span className="input-button__text">BUY NOW</span>
                <span className="input-button__icon-outer">
                  <span className="input-button__icon-inner">
                    <i className="icon icon-double-chevron-dark" />
                  </span>
                </span>
              </span>
            </button>
          </div>
          <div className="passPage__contentP mb-4">
            <p>Value- 100 USD (valid for 10 years)</p>
            <p>Refer 4 Friends and get your 100 USD back </p>
            <p>(Details on <a href="/levels" className="underlined text-red">Referral Benefits</a>)</p>
          </div>
          <h2 className="page__title-2 mb-4">What Do I Get?</h2>
          <div className="passPage__contentP mb-3">
            <p>Free entry at exclusive events in Dubai/UAE</p>
            <p>Discounts in Restaurants</p>
            <p>Complimentary- for the next 10 years, unlimited free entry to Club Tito'’'s, Goa (India)</p>
            <p>Red Carpet Entry to exclusive clubs and venues</p>
            <p>One bottle of Free Champagne every year</p>
            <p>Invitation to Private Yacht Party every year</p>
          </div>
          <h3 className="page__title-3 mb-3">Categories:-</h3>
          <h3 className="page__title-3 mb-3 ms-4">Levels</h3>
          <div className="pass__levels pass__levels--border mb-3">
            <div className="pass__levels-single">
              <a href="/levels/red" className="pass__levels-red-1 pass__levels-badge1">
                <div className="pass__levels-bg" />
                <img src="../assets/images/pass/pass-star-red.svg" alt="" />
                <div className="pass__levels-txt">
                  <div className="pass__levels-txt1">RED</div>
                </div>
              </a>
              <div className="pass__levels-red-2 pass__levels-badge2">
                Free Entry
              </div>
            </div>
            <div className="pass__levels-single">
              <a href="/levels/silver" className="pass__levels-silver-1 pass__levels-badge1">
                <div className="pass__levels-bg" />
                <img src="../assets/images/pass/pass-star-silver.svg" alt="" />
                <div className="pass__levels-txt">
                  <div className="pass__levels-txt1">Silver</div>
                  <div className="pass__levels-txt2">Direct Sales: 25</div>
                </div>
              </a>
              <div className="pass__levels-silver-2 pass__levels-badge2">
                Red Carpet Entry
              </div>
              <div className="pass__levels-silver-3 pass__levels-badge3">
                Complimentary Champagne
              </div>
            </div>
            <div className="pass__levels-single">
              <a href="/levels/gold" className="pass__levels-gold-1 pass__levels-badge1">
                <div className="pass__levels-bg" />
                <img src="../assets/images/pass/pass-star-gold.svg" alt="" />
                <div className="pass__levels-txt">
                  <div className="pass__levels-txt1">GOLD</div>
                  <div className="pass__levels-txt2">Direct Sales: 50</div>
                </div>
              </a>
              <div className="pass__levels-gold-2 pass__levels-badge2">
                Red Carpet Entry
              </div>
              <div className="pass__levels-gold-3 pass__levels-badge3">
                Complimentary Champagne
              </div>
              <div className="pass__levels-gold-4 pass__levels-badge4">
                Yacht Party
              </div>
            </div>
            <div className="pass__levels-single">
              <a href="/levels/platinum" className="pass__levels-platinum-1 pass__levels-badge1">
                <div className="pass__levels-bg" />
                <img src="../assets/images/pass/pass-star-platinum.svg" alt="" />
                <div className="pass__levels-txt">
                  <div className="pass__levels-txt1">Platinum</div>
                  <div className="pass__levels-txt2">Direct Sales: 100</div>
                </div>
              </a>
              <div className="pass__levels-platinum-2 pass__levels-badge2">
                Red Carpet Entry
              </div>
              <div className="pass__levels-platinum-3 pass__levels-badge3">
                Complimentary Champagne
              </div>
              <div className="pass__levels-platinum-4 pass__levels-badge4">
                Yacht Party
              </div>
              <div className="pass__levels-platinum-5 pass__levels-badge5">
                International Trip
              </div>
            </div>
          </div>
          <div className="passPage__contentP mb-5">
            <p>*conditions apply</p>
          </div>
        </section>
      </div>

      {/* Payment Modal */}
      <div className="modal fade modal--style1" id="paymentMethodModal" tabIndex={-1} aria-labelledby="paymentMethodModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="paymentMethodModalLabel">Select Payment Method</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center">
              <div className="row paymethod--row">
                <div className="col">
                  <button onClick={buyPassWeb3} className="border-0 bg-transparent paymethod paymethod1">
                    <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
                    <div className="paymethod__img">
                      <img src="../assets/images/metamask.svg" alt="" />
                    </div>
                  </button>
                </div>
                <div className="col">
                  <button onClick={buyPassWeb2} className="border-0 bg-transparent paymethod paymethod2">
                    <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
                    <div className="paymethod__img">
                      <img src="../assets/images/razorpay.svg" alt="" />
                    </div>
                  </button>
                </div>
              </div>
              <div>
                <p><b>Note :</b> </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Comming Soon Modal */}
      <div className="modal fade modal--style1" id="comingSoonModal" tabIndex={-1} aria-labelledby="comingSoonModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="paymentMethodModalLabel">Coming Soon!</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center">
              <p className="fz20 position-relative">We are coming soon in London, for now you can buy the pass for Dubai.</p>
              <button style={{ maxWidth: '300px' }} className="input-button mx-auto" onClick={() => window.location.href = "/pass/dubai"}>
                <span className="input-button__inner">
                  <span className="input-button__text">BUY NOW</span>
                  <span className="input-button__icon-outer">
                    <span className="input-button__icon-inner">
                      <i className="icon icon-double-chevron-dark" />
                    </span>
                  </span>
                </span>
              </button>
              <div className="text-center">
                <img className="coming-soon" src="../assets/images/onboarding2.svg" />
              </div>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}