import React from 'react'
import AdminTemplate from './AdminTemplate'
import { useState, useEffect } from 'react'
import { axiosClient } from '../../constants'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

export default function AdminAddOffer() {
  const headers = { Authorization: "Token " + Cookies.get("ADMIN_TOKEN"), "Content-Type": "multipart/form-data" }
  const [input, setInput] = useState({
    title: '',
    offer: '',
    location: '',
    datetime: '',
    description: ''
  })

  const onChangeHandl = (e) => {
    setInput(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const addFun = (e) => {
    e.preventDefault();
    const mData = {
      title: input.title,
      offer: input.offer,
      country: document.getElementById("my-country-offer-add").name,
      offer_type: input.offer_type,
      offer_date: input.datetime?.split("T")[0],
      offer_time: input.datetime?.split("T").length > 1 ? input.datetime?.split("T")[1] : '00:00',
      description: input.description,
      image: input.image
    }

    axiosClient.post("/main/merchant-offer", mData, { headers }).then(resp => {
      toast.success("Offer Added Successfully.");
      window.location.href = "/admin/offer";
    }).catch(err => {
      console.log('Error', err);
    })
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      $( ".input-field__countrycode" ).selectmenu();
      $( ".input-field__countrycode" ).on('selectmenuchange', function(a,b) {
        document.getElementById("my-country-offer-add").name = b.item.label.replace(/\s+/g,' ').trim();;
      });
      `;
    document.body.appendChild(script);
  }, []);

  return (
    <AdminTemplate active="offer">
      <div className="main-page h-100">
        <div className="container">
          {/* Top actions btn row */}
          <div className="top-btns position-relative">
            <div className="top-btns__left">
              <a href="/admin/offer">
                <img src="/assets/images/icons/back-icon.svg" alt="" />
              </a>
            </div>
          </div>
          {/* ===================== */}
          <form className="mt-4 form signup-form" onSubmit={addFun}>
            <div className="sign-up-header mt-2 text-center signup-text-header">
              <h1> Add Offer</h1>
            </div>
            <div className='container'>
              <div className='row'>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg width="24" fill="#ffffff" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1751 0v1920H169V0h1582Zm-115 112H290v9h-1v1678h1v20h1346V112Zm-234 235v321H514V347h888Z" fill-rule="evenodd"></path> </g></svg>
                      </span>
                      <input type="text" placeholder="Offer Title" name='title' value={input.title} onChange={onChangeHandl} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg width="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                      </span>
                      <select className="input-field__countrycode" name="India" onChange={onChangeHandl} id="my-country-offer-add">
                        <option data-countrycode="GB" value={44} selected>UK </option>
                        <option data-countrycode="US" value={1}>USA </option>
                        <option data-countrycode="DZ" value={213}>Algeria </option>
                        <option data-countrycode="AD" value={376}>Andorra </option>
                        <option data-countrycode="AO" value={244}>Angola </option>
                        <option data-countrycode="AI" value={1264}>Anguilla </option>
                        <option data-countrycode="AG" value={1268}>Antigua &amp; Barbuda </option>
                        <option data-countrycode="AR" value={54}>Argentina </option>
                        <option data-countrycode="AM" value={374}>Armenia </option>
                        <option data-countrycode="AW" value={297}>Aruba </option>
                        <option data-countrycode="AU" value={61}>Australia </option>
                        <option data-countrycode="AT" value={43}>Austria </option>
                        <option data-countrycode="AZ" value={994}>Azerbaijan </option>
                        <option data-countrycode="BS" value={1242}>Bahamas </option>
                        <option data-countrycode="BH" value={973}>Bahrain </option>
                        <option data-countrycode="BD" value={880}>Bangladesh </option>
                        <option data-countrycode="BB" value={1246}>Barbados </option>
                        <option data-countrycode="BY" value={375}>Belarus </option>
                        <option data-countrycode="BE" value={32}>Belgium </option>
                        <option data-countrycode="BZ" value={501}>Belize </option>
                        <option data-countrycode="BJ" value={229}>Benin </option>
                        <option data-countrycode="BM" value={1441}>Bermuda </option>
                        <option data-countrycode="BT" value={975}>Bhutan </option>
                        <option data-countrycode="BO" value={591}>Bolivia </option>
                        <option data-countrycode="BA" value={387}>Bosnia Herzegovina </option>
                        <option data-countrycode="BW" value={267}>Botswana </option>
                        <option data-countrycode="BR" value={55}>Brazil </option>
                        <option data-countrycode="BN" value={673}>Brunei </option>
                        <option data-countrycode="BG" value={359}>Bulgaria </option>
                        <option data-countrycode="BF" value={226}>Burkina Faso </option>
                        <option data-countrycode="BI" value={257}>Burundi </option>
                        <option data-countrycode="KH" value={855}>Cambodia </option>
                        <option data-countrycode="CM" value={237}>Cameroon </option>
                        <option data-countrycode="CA" value={1}>Canada </option>
                        <option data-countrycode="CV" value={238}>Cape Verde Islands </option>
                        <option data-countrycode="KY" value={1345}>Cayman Islands </option>
                        <option data-countrycode="CF" value={236}>Central African Republic </option>
                        <option data-countrycode="CL" value={56}>Chile </option>
                        <option data-countrycode="CN" value={86}>China </option>
                        <option data-countrycode="CO" value={57}>Colombia </option>
                        <option data-countrycode="KM" value={269}>Comoros </option>
                        <option data-countrycode="CG" value={242}>Congo </option>
                        <option data-countrycode="CK" value={682}>Cook Islands </option>
                        <option data-countrycode="CR" value={506}>Costa Rica </option>
                        <option data-countrycode="HR" value={385}>Croatia </option>
                        <option data-countrycode="CU" value={53}>Cuba </option>
                        <option data-countrycode="CY" value={90392}>Cyprus North </option>
                        <option data-countrycode="CY" value={357}>Cyprus South </option>
                        <option data-countrycode="CZ" value={42}>Czech Republic </option>
                        <option data-countrycode="DK" value={45}>Denmark </option>
                        <option data-countrycode="DJ" value={253}>Djibouti </option>
                        <option data-countrycode="DM" value={1809}>Dominica </option>
                        <option data-countrycode="DO" value={1809}>Dominican Republic </option>
                        <option data-countrycode="EC" value={593}>Ecuador </option>
                        <option data-countrycode="EG" value={20}>Egypt </option>
                        <option data-countrycode="SV" value={503}>El Salvador </option>
                        <option data-countrycode="GQ" value={240}>Equatorial Guinea </option>
                        <option data-countrycode="ER" value={291}>Eritrea </option>
                        <option data-countrycode="EE" value={372}>Estonia </option>
                        <option data-countrycode="ET" value={251}>Ethiopia </option>
                        <option data-countrycode="FK" value={500}>Falkland Islands </option>
                        <option data-countrycode="FO" value={298}>Faroe Islands </option>
                        <option data-countrycode="FJ" value={679}>Fiji </option>
                        <option data-countrycode="FI" value={358}>Finland </option>
                        <option data-countrycode="FR" value={33}>France </option>
                        <option data-countrycode="GF" value={594}>French Guiana </option>
                        <option data-countrycode="PF" value={689}>French Polynesia </option>
                        <option data-countrycode="GA" value={241}>Gabon </option>
                        <option data-countrycode="GM" value={220}>Gambia </option>
                        <option data-countrycode="GE" value={7880}>Georgia </option>
                        <option data-countrycode="DE" value={49}>Germany </option>
                        <option data-countrycode="GH" value={233}>Ghana </option>
                        <option data-countrycode="GI" value={350}>Gibraltar </option>
                        <option data-countrycode="GR" value={30}>Greece </option>
                        <option data-countrycode="GL" value={299}>Greenland </option>
                        <option data-countrycode="GD" value={1473}>Grenada </option>
                        <option data-countrycode="GP" value={590}>Guadeloupe </option>
                        <option data-countrycode="GU" value={671}>Guam </option>
                        <option data-countrycode="GT" value={502}>Guatemala </option>
                        <option data-countrycode="GN" value={224}>Guinea </option>
                        <option data-countrycode="GW" value={245}>Guinea - Bissau </option>
                        <option data-countrycode="GY" value={592}>Guyana </option>
                        <option data-countrycode="HT" value={509}>Haiti </option>
                        <option data-countrycode="HN" value={504}>Honduras </option>
                        <option data-countrycode="HK" value={852}>Hong Kong </option>
                        <option data-countrycode="HU" value={36}>Hungary </option>
                        <option data-countrycode="IS" value={354}>Iceland </option>
                        <option selected data-countrycode="IN" value={91}>India </option>
                        <option data-countrycode="ID" value={62}>Indonesia </option>
                        <option data-countrycode="IR" value={98}>Iran </option>
                        <option data-countrycode="IQ" value={964}>Iraq </option>
                        <option data-countrycode="IE" value={353}>Ireland </option>
                        <option data-countrycode="IL" value={972}>Israel </option>
                        <option data-countrycode="IT" value={39}>Italy </option>
                        <option data-countrycode="JM" value={1876}>Jamaica </option>
                        <option data-countrycode="JP" value={81}>Japan </option>
                        <option data-countrycode="JO" value={962}>Jordan </option>
                        <option data-countrycode="KZ" value={7}>Kazakhstan </option>
                        <option data-countrycode="KE" value={254}>Kenya </option>
                        <option data-countrycode="KI" value={686}>Kiribati </option>
                        <option data-countrycode="KP" value={850}>Korea North </option>
                        <option data-countrycode="KR" value={82}>Korea South </option>
                        <option data-countrycode="KW" value={965}>Kuwait </option>
                        <option data-countrycode="KG" value={996}>Kyrgyzstan </option>
                        <option data-countrycode="LA" value={856}>Laos </option>
                        <option data-countrycode="LV" value={371}>Latvia </option>
                        <option data-countrycode="LB" value={961}>Lebanon </option>
                        <option data-countrycode="LS" value={266}>Lesotho </option>
                        <option data-countrycode="LR" value={231}>Liberia </option>
                        <option data-countrycode="LY" value={218}>Libya </option>
                        <option data-countrycode="LI" value={417}>Liechtenstein </option>
                        <option data-countrycode="LT" value={370}>Lithuania </option>
                        <option data-countrycode="LU" value={352}>Luxembourg </option>
                        <option data-countrycode="MO" value={853}>Macao </option>
                        <option data-countrycode="MK" value={389}>Macedonia </option>
                        <option data-countrycode="MG" value={261}>Madagascar </option>
                        <option data-countrycode="MW" value={265}>Malawi </option>
                        <option data-countrycode="MY" value={60}>Malaysia </option>
                        <option data-countrycode="MV" value={960}>Maldives </option>
                        <option data-countrycode="ML" value={223}>Mali </option>
                        <option data-countrycode="MT" value={356}>Malta </option>
                        <option data-countrycode="MH" value={692}>Marshall Islands </option>
                        <option data-countrycode="MQ" value={596}>Martinique </option>
                        <option data-countrycode="MR" value={222}>Mauritania </option>
                        <option data-countrycode="YT" value={269}>Mayotte </option>
                        <option data-countrycode="MX" value={52}>Mexico </option>
                        <option data-countrycode="FM" value={691}>Micronesia </option>
                        <option data-countrycode="MD" value={373}>Moldova </option>
                        <option data-countrycode="MC" value={377}>Monaco </option>
                        <option data-countrycode="MN" value={976}>Mongolia </option>
                        <option data-countrycode="MS" value={1664}>Montserrat </option>
                        <option data-countrycode="MA" value={212}>Morocco </option>
                        <option data-countrycode="MZ" value={258}>Mozambique </option>
                        <option data-countrycode="MN" value={95}>Myanmar </option>
                        <option data-countrycode="NA" value={264}>Namibia </option>
                        <option data-countrycode="NR" value={674}>Nauru </option>
                        <option data-countrycode="NP" value={977}>Nepal </option>
                        <option data-countrycode="NL" value={31}>Netherlands </option>
                        <option data-countrycode="NC" value={687}>New Caledonia </option>
                        <option data-countrycode="NZ" value={64}>New Zealand </option>
                        <option data-countrycode="NI" value={505}>Nicaragua </option>
                        <option data-countrycode="NE" value={227}>Niger </option>
                        <option data-countrycode="NG" value={234}>Nigeria </option>
                        <option data-countrycode="NU" value={683}>Niue </option>
                        <option data-countrycode="NF" value={672}>Norfolk Islands </option>
                        <option data-countrycode="NP" value={670}>Northern Marianas </option>
                        <option data-countrycode="NO" value={47}>Norway </option>
                        <option data-countrycode="OM" value={968}>Oman </option>
                        <option data-countrycode="PW" value={680}>Palau </option>
                        <option data-countrycode="PA" value={507}>Panama </option>
                        <option data-countrycode="PG" value={675}>Papua New Guinea </option>
                        <option data-countrycode="PY" value={595}>Paraguay </option>
                        <option data-countrycode="PE" value={51}>Peru </option>
                        <option data-countrycode="PH" value={63}>Philippines </option>
                        <option data-countrycode="PL" value={48}>Poland </option>
                        <option data-countrycode="PT" value={351}>Portugal </option>
                        <option data-countrycode="PR" value={1787}>Puerto Rico </option>
                        <option data-countrycode="QA" value={974}>Qatar </option>
                        <option data-countrycode="RE" value={262}>Reunion </option>
                        <option data-countrycode="RO" value={40}>Romania </option>
                        <option data-countrycode="RU" value={7}>Russia </option>
                        <option data-countrycode="RW" value={250}>Rwanda </option>
                        <option data-countrycode="SM" value={378}>San Marino </option>
                        <option data-countrycode="ST" value={239}>Sao Tome &amp; Principe </option>
                        <option data-countrycode="SA" value={966}>Saudi Arabia </option>
                        <option data-countrycode="SN" value={221}>Senegal </option>
                        <option data-countrycode="CS" value={381}>Serbia </option>
                        <option data-countrycode="SC" value={248}>Seychelles </option>
                        <option data-countrycode="SL" value={232}>Sierra Leone </option>
                        <option data-countrycode="SG" value={65}>Singapore </option>
                        <option data-countrycode="SK" value={421}>Slovak Republic </option>
                        <option data-countrycode="SI" value={386}>Slovenia </option>
                        <option data-countrycode="SB" value={677}>Solomon Islands </option>
                        <option data-countrycode="SO" value={252}>Somalia </option>
                        <option data-countrycode="ZA" value={27}>South Africa </option>
                        <option data-countrycode="ES" value={34}>Spain </option>
                        <option data-countrycode="LK" value={94}>Sri Lanka </option>
                        <option data-countrycode="SH" value={290}>St. Helena </option>
                        <option data-countrycode="KN" value={1869}>St. Kitts </option>
                        <option data-countrycode="SC" value={1758}>St. Lucia </option>
                        <option data-countrycode="SD" value={249}>Sudan </option>
                        <option data-countrycode="SR" value={597}>Suriname </option>
                        <option data-countrycode="SZ" value={268}>Swaziland </option>
                        <option data-countrycode="SE" value={46}>Sweden </option>
                        <option data-countrycode="CH" value={41}>Switzerland </option>
                        <option data-countrycode="SI" value={963}>Syria </option>
                        <option data-countrycode="TW" value={886}>Taiwan </option>
                        <option data-countrycode="TJ" value={7}>Tajikstan </option>
                        <option data-countrycode="TH" value={66}>Thailand </option>
                        <option data-countrycode="TG" value={228}>Togo </option>
                        <option data-countrycode="TO" value={676}>Tonga </option>
                        <option data-countrycode="TT" value={1868}>Trinidad &amp; Tobago </option>
                        <option data-countrycode="TN" value={216}>Tunisia </option>
                        <option data-countrycode="TR" value={90}>Turkey </option>
                        <option data-countrycode="TM" value={7}>Turkmenistan </option>
                        <option data-countrycode="TM" value={993}>Turkmenistan </option>
                        <option data-countrycode="TC" value={1649}>Turks &amp; Caicos Islands </option>
                        <option data-countrycode="TV" value={688}>Tuvalu </option>
                        <option data-countrycode="UG" value={256}>Uganda </option>
                        <option data-countrycode="UA" value={380}>Ukraine </option>
                        <option data-countrycode="AE" value={971}>United Arab Emirates </option>
                        <option data-countrycode="UY" value={598}>Uruguay </option>
                        <option data-countrycode="UZ" value={7}>Uzbekistan </option>
                        <option data-countrycode="VU" value={678}>Vanuatu </option>
                        <option data-countrycode="VA" value={379}>Vatican City </option>
                        <option data-countrycode="VE" value={58}>Venezuela </option>
                        <option data-countrycode="VN" value={84}>Vietnam </option>
                        <option data-countrycode="VG" value={84}>Virgin Islands - British </option>
                        <option data-countrycode="VI" value={84}>Virgin Islands - US </option>
                        <option data-countrycode="WF" value={681}>Wallis &amp; Futuna </option>
                        <option data-countrycode="YE" value={969}>Yemen </option>
                        <option data-countrycode="YE" value={967}>Yemen </option>
                        <option data-countrycode="ZM" value={260}>Zambia </option>
                        <option data-countrycode="ZW" value={263}>Zimbabwe </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg width="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m20.749 12 1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path></g></svg>
                      </span>
                      <select placeholder="Offer Type" className='offer-type' name='offer_type' value={input.offer_type} onChange={onChangeHandl}>
                        <option value="Select Offer Type">Select Offer Type</option>
                        <option value="discount">Discount</option>
                        <option value="event">Event</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix"><i className="icon icon-calendar" /></span>
                      <input type="datetime-local" placeholder="Date & Time" name='datetime' value={input.datetime} onChange={onChangeHandl} style={{ colorScheme: 'dark' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg width={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.2498 2C7.03145 2.00411 4.84888 2.07958 3.46423 3.46423C2.07958 4.84888 2.00411 7.03145 2 11.2498H6.91352C6.56255 10.8114 6.30031 10.2943 6.15731 9.72228C5.61906 7.56926 7.56926 5.61906 9.72228 6.15731C10.2943 6.30031 10.8114 6.56255 11.2498 6.91352V2Z" fill="#ffffff"></path> <path d="M2 12.7498C2.00411 16.9681 2.07958 19.1506 3.46423 20.5353C4.84888 21.9199 7.03145 21.9954 11.2498 21.9995V14.1234C10.4701 15.6807 8.8598 16.7498 6.99976 16.7498C6.58555 16.7498 6.24976 16.414 6.24976 15.9998C6.24976 15.5856 6.58555 15.2498 6.99976 15.2498C8.53655 15.2498 9.82422 14.1831 10.1628 12.7498H2Z" fill="#ffffff"></path> <path d="M12.7498 21.9995C16.9681 21.9954 19.1506 21.9199 20.5353 20.5353C21.9199 19.1506 21.9954 16.9681 21.9995 12.7498H13.8367C14.1753 14.1831 15.463 15.2498 16.9998 15.2498C17.414 15.2498 17.7498 15.5856 17.7498 15.9998C17.7498 16.414 17.414 16.7498 16.9998 16.7498C15.1397 16.7498 13.5294 15.6807 12.7498 14.1234V21.9995Z" fill="#ffffff"></path> <path d="M21.9995 11.2498C21.9954 7.03145 21.9199 4.84888 20.5353 3.46423C19.1506 2.07958 16.9681 2.00411 12.7498 2V6.91352C13.1882 6.56255 13.7053 6.30031 14.2772 6.15731C16.4303 5.61906 18.3805 7.56926 17.8422 9.72228C17.6992 10.2943 17.437 10.8114 17.086 11.2498H21.9995Z" fill="#ffffff"></path> <path d="M9.35847 7.61252C10.47 7.8904 11.2498 8.88911 11.2498 10.0348V11.2498H10.0348C8.88911 11.2498 7.8904 10.47 7.61252 9.35847C7.34891 8.30403 8.30403 7.34891 9.35847 7.61252Z" fill="#ffffff"></path> <path d="M12.7498 10.0348V11.2498H13.9647C15.1104 11.2498 16.1091 10.47 16.387 9.35847C16.6506 8.30403 15.6955 7.34891 14.6411 7.61252C13.5295 7.8904 12.7498 8.88911 12.7498 10.0348Z" fill="#ffffff"></path> </g></svg>
                      </span>
                      <input type="text" placeholder="Offer" name="offer" value={input.offer} onChange={onChangeHandl} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M15.472 13.13l-1.633 1.225a.377.377 0 0 1-.468-.012l-2.706-2.256a.377.377 0 0 0-.509.023l-2.69 2.69a.377.377 0 0 1-.533 0 .377.377 0 0 0-.533 0l-.4.4V20h12v-4.8l-2.035-2.035a.377.377 0 0 0-.493-.035zM17 19H7v-3.104a1.376 1.376 0 0 0 1.174-.389l2.287-2.287 2.27 1.89a1.375 1.375 0 0 0 1.708.044l1.2-.9L17 15.614zm-4-7.979v-.043a.979.979 0 0 1 .979-.978A1.021 1.021 0 0 1 15 11.021a.979.979 0 0 1-.979.979h-.043a.981.981 0 0 1-.978-.979zM3 23h18V6.709L15.29 1H3zM15 2h.2L20 6.8V7h-5zM4 2h10v6h6v14H4z"></path></g></svg>
                      </span>
                      <input type="file" className='mt-4' name="image" onChange={(e) => { setInput(prev => ({ ...prev, image: e.target.files[0] })) }} />
                    </div>
                  </div>
                </div>
              </div>

              <section className="gradient-block-outer ckeditorInput">
                <div className="gradient-block-bg"><div className="gradient-block-bg-inner"></div></div>
                <div className='gradient-block-inner'>
                  <textarea value={input.description} name="description" onChange={onChangeHandl} className='form-control bg-transparent text-white' style={{ borderRadius: 10 }} rows={8} placeholder='Description'>

                  </textarea>
                </div>
              </section>

              <div className="input-button-wrapper mt-3">
                <button className="input-button">
                  <span className="input-button__inner">
                    <span className="input-button__text">Continue</span>
                    <span className="input-button__icon-outer">
                      <span className="input-button__icon-inner">
                        <i className="icon icon-double-chevron-dark" />
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </AdminTemplate>
  )
}