import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../constants';
import axios from 'axios';

export default function RightPassCard() {
  const token = Cookies.get("USER_TOKEN");
  const [passData, setPassData] = useState(null);

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    try {
      const response = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      let mData = response.data?.data?.promo_pass;
      const mQrCode = await axios.get("https://quickchart.io/qr?format=svg&size=100&dark=fff&light=000&text=" + JSON.stringify(mData));
      setPassData({ ...mData, QR: mQrCode.data });
    } catch (error) {
      console.log(error);
    }
  }

  const dateTime = (data) => {
    if (!data) return "";
    const date = new Date(data);
    let dd = date.getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let mm = (date.getMonth() + 1);
    mm = mm < 10 ? "0" + mm : mm;
    return dd + "/" + mm + "/" + (date.getFullYear() + 10);
  }

  return (
    <div className="enterx-card enterx-card--red">
      <img src="/assets/images/enterx-cards/card-red.svg" alt="..." />
      <div
        className="enterx-card__barcode"
        style={{ height: "85px" }}
        dangerouslySetInnerHTML={{ __html: passData?.QR }}
      />
      <img
        className="enterx-card__star"
        src="./assets/images/enterx-cards/star-red.svg"
        alt="..."
      />
      <span className="enterx-card__passno">{(passData?.pass_id)}</span>
      <span className="enterx-card__title1">
        Limited Offer Price Will Change Shortly
      </span>
      <span className="enterx-card__title2">My Music Night Show</span>
      <span className="enterx-card__title3">
        Offer: Prepare Yourself For
      </span>
      <span className="enterx-card__startxt">
        <span className="enterx-card__startxt1">E-Star</span>
        <span className="enterx-card__startxt2">30</span>
      </span>
      <span className="enterx-card__slots">00</span>
      <span className="enterx-card__totalused">00</span>
      <span className="enterx-card__lastused">N/A</span>
      <span className="enterx-card__validtill">{dateTime((passData?.updated_at))}</span>
      <span className="enterx-card__startext-red">E-star: 30</span>
      <span className="enterx-card__startext-silver">E-star: 50</span>
      <span className="enterx-card__startext-gold">E-star: 100</span>
      <span className="enterx-card__startext-platinum">
        E-star: 250
      </span>
      <span className="enterx-card__refercode">
        Referral Code: BIG799BAOH0
      </span>
    </div>
  )
}
