import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import AdminTemplate from './AdminTemplate';
import { axiosClient } from '../../constants';
import { useState } from 'react';
import Cookies from 'js-cookie';

const dataTemplate = {
  name: '',
  title: '',
  children: []
}

export default function AdminUserView() {
  const { email } = useParams();
  const [data, setData] = useState(null);
  const headers = { Authorization: "Token " + Cookies.get("ADMIN_TOKEN") }

  useEffect(() => {
    if (email) {
      axiosClient.get("/accounts/get-admin-sponsor-tree?email=" + email, { headers }).then(resp => {
        setData(resp.data.data);
        const mTemp = transformData(resp.data.data);
        setData(mTemp);
      }).catch(err => { console.log(err); })
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (!document.getElementById("script-node-chart")) {
        const script = document.createElement("script");
        script.id = "script-node-chart";
        script.innerHTML = `
        (function($){
          $(function() {
            var datascource = ${JSON.stringify(data)}
            
            // Vertical Tree
            var verticalLevel = 2;
            if( $(window).width() >= 992){
              verticalLevel = 10;
            }
              
            var oc = $('.chart-container').orgchart({
              'pan': false,
              'zoom': true,
              'data' : datascource,
              'nodeContent': 'title',
              'verticalLevel': verticalLevel,
              'zoominLimit': 1,
            });
  
          });
  
        })(jQuery);
        `;
        document.body.appendChild(script);
      }
    }
  }, [data]);

  const transformData = (inputData) => {
    const outputData = { ...dataTemplate };
    outputData.name = '';
    outputData.title = inputData.email;
    if (inputData.childs && inputData.childs.length > 0) {
      outputData.children = inputData.childs.map(transformData);
    }
    return outputData;
  }

  return (
    <AdminTemplate active="dashboard">
      <div className='container'>
        <div className="node-structure">
          <div className="chart-container"></div>
        </div>
      </div>
    </AdminTemplate>
  )
}