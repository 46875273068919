import React, { useEffect } from 'react'
import { axiosClient } from '../constants';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [otp, setOtp] = useState({
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    f: ''
  });
  const [otpSent, setOtpSent] = useState(false);

  const loginFun = async (e) => {
    e?.preventDefault();
    const formData = new FormData();
    formData.append("email", email)
    try {
      const data = await axiosClient.post("/accounts/forgot-password", formData);
      toast.success(data.data.message);
      setOtpSent(true);
      // Cookies.set("USER_TOKEN", data.data.token);
      // Cookies.set("USER_DATA", JSON.stringify(data.data.data));
      // const mStep = parseInt(data?.data?.data?.step);
      // console.log(mStep);
      // if (mStep && mStep < 4) {
      //   window.location.href = "/signup";
      // } else if (mStep == 4) {
      //   window.location.href = "/pass";
      // } else {
      //   window.location.href = "/dashboard";
      // }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const submitOTP = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const mOTP = otp.a + otp.b + otp.c + otp.d + otp.e + otp.f;
    formData.append("email", email)
    formData.append("otp", mOTP)
    formData.append("new_password", password)
    try {
      const data = await axiosClient.post("/accounts/change-password", formData);
      console.log(data.data.message);
      toast.success(data.data.message);
      window.location.href = "/login";
      // Cookies.set("USER_TOKEN", data.data.token);
      // Cookies.set("USER_DATA", JSON.stringify(data.data.data));
      // const mStep = parseInt(data?.data?.data?.step);
      // console.log(mStep);
      // if (mStep && mStep < 4) {
      //   window.location.href = "/signup";
      // } else if (mStep == 4) {
      //   window.location.href = "/pass";
      // } else {
      //   window.location.href = "/dashboard";
      // }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const onKeyUpFun = (e, prevData, nextData) => {
    if (e.keyCode === 8 || e.keyCode === 37) {
      const prev = document.getElementById("otpInput" + prevData);
      if (prev) {
        prev.select();
      }
    } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
      const next = document.getElementById("otpInput" + nextData);
      if (next) {
        next.select();
      }
    }
  }

  const handlePaste = (ev) => {
    const clip = ev.clipboardData.getData('text');
    if (!isNaN(clip) && Number.isInteger(parseInt(clip)) && clip.length >= 6) {
      setOtp({
        ...otp, a: clip[0], b: clip[1], c: clip[2], d: clip[3], e: clip[4], f: clip[5]
      })
      document.getElementById("otpInput6").select();
    }
  }

  useEffect(() => {
    if (Cookies.get("USER_TOKEN") && Cookies.get("USER_DATA")) {
      const mStep = JSON.parse(Cookies.get("USER_DATA"))?.step && parseInt(JSON.parse(Cookies.get("USER_DATA"))?.step);
      if (mStep && mStep < 4) {
        window.location.href = "/signup";
      } else if (mStep == 4) {
        window.location.href = "/pass";
      } else {
        window.location.href = "/dashboard";
      }
    }
  }, [])


  return (
    <>
      <div>
        <div className="bg-setup bg-setup--login">
          <div className="blobs" />
        </div>
        {otpSent ? (
          <div className="main-page">
            <div className="container signup-documentation">
              {/* Top actions btn row */}
              <div className="top-btns">
                <div className="top-btns__left">
                  <a href="/">
                    <img src="./assets/images/icons/back-icon.svg" alt="" />
                  </a>
                </div>
              </div>
              {/* ===================== */}
              <div className="sponsor">
                <div className="sponsor__left signup-5">
                  <div className="cards123 cards123--signup">
                    <div className="cards123__single">
                      <div className="cards123__bg" />
                      <div className="cards123__img">
                        <div className="cards123__bg-inner" />
                        <img src="../assets/images/signup/img5.png" alt="" />
                      </div>
                    </div>
                    <div className="cards123__single">
                      <div className="cards123__bg" />
                      <div className="cards123__img">
                        <div className="cards123__bg-inner" />
                        <img src="../assets/images/signup/img6.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sponsor__right">
                  <div className="w-100 mx-auto sponsor__right__inner">
                    <div className="auth-logo">
                      <img className="mx-auto" src="./assets/images/header-logo.svg" alt="logo" />
                    </div>
                    <div className="heading-welcome">
                      <h3 className="fw700">A Warm Welcome from EnterX!</h3>
                    </div>
                    <div className="sign-up-header otp-header">
                      <p className="fw500">
                        Insert the OTP sent to you via email/phone number.
                      </p>
                    </div>
                    <form className="mt-5 form" onSubmit={submitOTP}>
                      <div className="form-group form-group--leftIcon otp-row">
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.a} onChange={(e) => { setOtp(prev => ({ ...prev, a: e.target.value })); }} id="otpInput1" onPaste={handlePaste} onKeyUp={(e) => onKeyUpFun(e, '', 2)} />
                          </div>
                        </div>
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.b} onChange={(e) => { setOtp(prev => ({ ...prev, b: e.target.value })); }} id="otpInput2" onKeyUp={(e) => onKeyUpFun(e, 1, 3)} />
                          </div>
                        </div>
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.c} onChange={(e) => { setOtp(prev => ({ ...prev, c: e.target.value })); }} id="otpInput3" onKeyUp={(e) => onKeyUpFun(e, 2, 4)} />
                          </div>
                        </div>
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.d} onChange={(e) => { setOtp(prev => ({ ...prev, d: e.target.value })); }} id="otpInput4" onKeyUp={(e) => onKeyUpFun(e, 3, 5)} />
                          </div>
                        </div>
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.e} onChange={(e) => { setOtp(prev => ({ ...prev, e: e.target.value })); }} id="otpInput5" onKeyUp={(e) => onKeyUpFun(e, 4, 6)} />
                          </div>
                        </div>
                        <div className="input-text-outer otp-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <input maxLength={1} value={otp.f} onChange={(e) => { setOtp(prev => ({ ...prev, f: e.target.value })); }} id="otpInput6" onKeyUp={(e) => onKeyUpFun(e, 5, '')} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 text-center login-text">
                        <p className="fw400">
                          Don’t Received OTP?
                          <span>
                            <a className="fw500 cursor-pointer" onClick={loginFun}> Resend OTP.</a>
                          </span>
                        </p>
                      </div>

                      <div className="form-group form-group--leftIcon">
                        <div className="input-text-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 10V14M10.2676 11L13.7317 13M13.7314 11L10.2673 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M6.73241 10V14M4.99999 11L8.46409 13M8.46386 11L4.99976 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M17.2681 10V14M15.5356 11L18.9997 13M18.9995 11L15.5354 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C21.4816 5.82475 21.7706 6.69989 21.8985 8" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                            </span>
                            <input value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="New Password" />
                          </div>
                        </div>
                      </div>

                      <div className="input-button-wrapper btn-reponsive">
                        <button className="input-button">
                          <span className="input-button__inner">
                            <span className="input-button__text">Verify</span>
                            <span className="input-button__icon-outer">
                              <span className="input-button__icon-inner">
                                <i className="icon icon-double-chevron-dark" />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>

                      <div className="text-center login-text mt-2 register-responsive">
                        <p className="fw400">
                          Not a Member ?
                          <span>
                            <a className="fw500 ms-1" href="/signup"> Register Now!</a>
                          </span>
                        </p>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="main-page">
            <div className="container signup-documentation">
              {/* Top actions btn row */}
              <div className="top-btns">
                <div className="top-btns__left">
                  <a href="/">
                    <img src="./assets/images/icons/back-icon.svg" alt="" />
                  </a>
                </div>
              </div>
              {/* ===================== */}
              <div className="sponsor">
                <div className="sponsor__left signup-5">
                  <div className="cards123 cards123--signup">
                    <div className="cards123__single">
                      <div className="cards123__bg" />
                      <div className="cards123__img">
                        <div className="cards123__bg-inner" />
                        <img src="../assets/images/signup/img5.png" alt="" />
                      </div>
                    </div>
                    <div className="cards123__single">
                      <div className="cards123__bg" />
                      <div className="cards123__img">
                        <div className="cards123__bg-inner" />
                        <img src="../assets/images/signup/img6.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sponsor__right">
                  <div className="w-100 mx-auto sponsor__right__inner">
                    <div className="auth-logo">
                      <img className="mx-auto" src="./assets/images/header-logo.svg" alt="logo" />
                    </div>
                    <div className="heading-welcome">
                      <h3 className="fw700">A Warm Welcome from EnterX!</h3>
                    </div>
                    <div className="mt-3 sign-up-header">
                      <p className="fw500 text-center">
                        Forgot Password.
                      </p>
                    </div>
                    <form className="mt-5 form" onSubmit={loginFun}>
                      <div className="form-group form-group--leftIcon">
                        <div className="input-text-outer">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix"><i className="icon icon-user" /></span>
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} type="text" placeholder="Email / Phone Number" />
                          </div>
                        </div>
                      </div>

                      <div className="input-button-wrapper btn-reponsive">
                        <button onClick={loginFun} className="cursor-pointer input-button">
                          <span className="input-button__inner">
                            <span className="input-button__text">Send OTP</span>
                            <span className="input-button__icon-outer">
                              <span className="input-button__icon-inner">
                                <i className="icon icon-double-chevron-dark" />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>

                      <div className="text-center login-text mt-2 register-responsive">
                        <p className="fw400">
                          Not a Member ?
                          <span>
                            <a className="fw500 ms-1" href="/signup"> Register Now!</a>
                          </span>
                        </p>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}