import React from 'react'

export default function ThankYou() {
  return (
    <div>
      <div className="bg-setup">
        <div className="blobs" />
      </div>
      <div className="main-page">
        <div className="container">
          <div className="thankyou-back thankyou-height">
            {/* Top actions btn row */}
            <div className="top-btns top-btns--withSidebar">
              <div className="top-btns__left">
                <a href="/dashboard" className>
                  <img src="./assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
              <div className="top-btns__right">
                <a href="/" className="notify">
                  <div className="rounded-circle dot" />
                  <img src="./assets/images/icons/bell-icon.svg" alt="" />
                </a>
                <a href="/" className="logout">
                  <img src="./assets/images/icons/logout-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="thanks-mobile-section">
              <div className="gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner"></div>
                </div>
                <div className="gradient-block-inner center-section">
                  <h3 className="fw700">Thank You For Your <br /> Purchase!</h3>
                  <p className="text-center ts03 fw500">Your Slot is book successfully !</p>
                  <div className="centered-image">
                    <div className="gradient-block-outer left-content">
                      <div className="gradient-block-bg">
                        <div className="gradient-block-bg-inner"></div>
                      </div>
                      <div className="gradient-block-inner">
                        <div className="text-center slot-number">
                          <p className="fw400">Slot # 03</p>
                        </div>
                        <div className="text-center">
                          <h3 className="fw700">28</h3>
                        </div>
                        <div className="text-center">
                          <h5 className="fw400">November-2023</h5>
                        </div>
                      </div>
                    </div>
                    <div className="right-image">
                      <img src="./assets/images/thankyou.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
