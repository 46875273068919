import React from 'react'
import Template from '../components/Dashboard/Template'
import { useState } from 'react'
import useRazorpay from 'react-razorpay';
import Cookies from 'js-cookie';
import { APP_CONFIG, axiosClient } from '../constants';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

export default function PayDues() {
  const Razorpay = useRazorpay();
  const [currEle, setCurrEle] = useState("");
  const token = Cookies.get("USER_TOKEN");
  const [slotData, setSlotData] = useState([]);
  let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getData = async () => {
    try {
      const response = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      setSlotData(response.data?.data?.order_slots);
    } catch (error) {
      console.log(error);
    }
  }

  useState(() => {
    getData();
  }, []);

  function diff_hours(dt2, dt1) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  const metaTrans = async () => {
    const headers = {
      "Authorization": "Token " + token
    }
    try {
      const apiRes = await axiosClient.post("/main/init-cancel-payment", { is_meta_transaction: true, slot_date: currEle?.date }, { headers });
      const orderInfo = apiRes.data?.data;

      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const mAccounts = await provider.send("eth_requestAccounts", []);

        if (mAccounts?.length > 0) {
          const signer = provider.getSigner()
          const contract = new ethers.Contract(APP_CONFIG.SC_MAIN, APP_CONFIG.SC_MAIN_ABI, signer);
          const contract1 = new ethers.Contract(APP_CONFIG.SC_USDT, APP_CONFIG.SC_USDT_ABI, provider.getSigner());

          try {
            const usdtM = await contract1.allowance(mAccounts[0], APP_CONFIG.SC_MAIN);
            const amount1 = ethers.utils.parseEther("10000000");
            const amount = ethers.utils.parseEther("100");

            if (Number(usdtM) == 0) {
              const res1 = await contract1.approve(APP_CONFIG.SC_MAIN, amount1, { from: mAccounts[0] });
              await res1.wait();
              const res2 = await contract.Purchase(amount, orderInfo?.order_id, { from: mAccounts[0] });
              const trans = await res2.wait();
              const apiReq = {
                order_id: orderInfo.order_id,
                transaction_id: trans?.transactionHash
              }
              axiosClient.post("/main/meta-cancel-callback", apiReq, { headers }).then((response1) => {
                window.location.reload();
              }).catch((error1) => {
                toast.error("Internal Serever Error in Create Order!!!")
              })
            } else {
              const res2 = await contract.Purchase(amount, orderInfo?.order_id, { from: mAccounts[0] });
              res2.wait();
              const trans = await res2.wait();
              const apiReq = {
                order_id: orderInfo.order_id,
                transaction_id: trans?.transactionHash
              }
              axiosClient.post("/main/meta-cancel-callback", apiReq, { headers }).then((response1) => {
                window.location.reload();
              }).catch((error1) => {
                toast.error("Internal Serever Error in Create Order!!!")
              })
            }
          } catch (error) {
            console.log(error, "2");
            toast.error("Web3 Transaction Error!!!");
          }
        }
      }
    } catch (error) {
      toast.error("Inter Server Error in /INIT-API");
    }
  }

  const bankTrans = async () => {
    let orderInfo = {};
    try {
      const headers = {
        "Authorization": "Token " + token
      }
      const apiRes = await axiosClient.post("/main/init-cancel-payment", { slot_date: currEle?.date }, { headers });
      orderInfo = apiRes?.data?.data;

      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: "INR",
        name: "EnterX",
        description: "Test Transaction",
        image: window.location.href.replace("/pass", "") + "/assets/images/header-logo.svg",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClient.post("/main/payment-cancel-callback", response, { headers }).then((paymentCallbackResponse) => {
            window.location.reload();
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();

    } catch (error) {
      toast.error("Some Error in API!!!!");
    }
  }

  const onClickCancelSlot = async (e, data) => {
    e.preventDefault();
    setCurrEle(data);
  }

  return (
    <Template active="dashboard">
      <div className="dashboard-widget calendar-widget mt-md-5 py-md-5">
        <h1 className='my-3'>Clear All Your Dues: </h1>
        <div className="row calendar-slots w-100">
          {
            slotData?.filter(ele => new Date(ele.date) < new Date() && !ele.is_redeemed && !ele.is_cancelled)?.map((element, index) => {
              return (
                <div key={index} className='col-4'>
                  <div className="gradient-block-outer dashboard-gradient-block">
                    <div className="gradient-block-bg">
                      <div className="gradient-block-bg-inner" />
                    </div>
                    <a onClick={(e) => onClickCancelSlot(e, element)} data-bs-toggle="modal" data-bs-target="#confirmSlotModal" className="calendar-slots__edit">
                      <span><i className="icon icon-edit-large"></i></span>
                    </a>
                    <div className="gradient-block-inner">
                      <div className="calendar-slots__num mt-2">Slot # {index + 1}</div>
                      <div className="calendar-slots__date">{new Date(element.date).getDate() < 10 ? "0" + new Date(element.date).getDate() : new Date(element.date).getDate()}</div>
                      <div className="calendar-slots__month">{monthsArray[new Date(element.date).getMonth()]}, {new Date(element.date).getFullYear()}</div>
                      <div className='calendar-slots__num mt-1'>{element?.club_name}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      {/* Modal For Cancel Slot */}
      <div className="modal fade modal--style1 confirmSlotModal" id="confirmSlotModal" tabIndex={-1} aria-labelledby="confirmSlotModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="confirmSlotModalLabel">Pay Your Dues</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center">
              <p className="slot-desc mt-0 text-white" style={{ fontSize: "20px", fontWeight: "600" }}>You Need to pay 30$ of cancellation fee.</p>
              <div className="confirmSlotModal__date">
                <div className="slotDate gradient-block-outer">
                  <div className="gradient-block-bg">
                    <div className="gradient-block-bg-inner" />
                  </div>
                  <div className="gradient-block-inner">
                    <div className="text-center slot-number">
                      <p className="fw400">Slot</p>
                    </div>
                    <div className="text-center">
                      <h3 className="fw700">{new Date(currEle?.date).getDate()}</h3>
                    </div>
                    <div className="text-center">
                      <h5 className="fw400">{monthsArray[new Date(currEle?.date).getMonth()]}-{new Date(currEle?.date).getFullYear()}</h5>
                    </div>
                  </div>
                </div>
                <button onClick={metaTrans} className="border-0 bg-transparent paymethod paymethod1">
                  <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
                  <div className="paymethod__img">
                    <img src="../assets/images/metamask.svg" alt="" />
                  </div>
                </button>
                <button onClick={bankTrans} className="border-0 bg-transparent paymethod paymethod2">
                  <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
                  <div className="paymethod__img">
                    <img src="../assets/images/razorpay.svg" alt="" />
                  </div>
                </button>
              </div>
              {/* <p className="slot-desc">Note: You Can Cancel the slot before 48 hours of slot date.</p>
              <div className="row gx-lg-5 mt-4">
                <div className="col-lg-6 text-lg-end mb-3">
                  <button data-bs-dismiss="modal" aria-label="Close" className="button  text-white border-0 bg-transparent button--getstarted">
                    <span className="button__inner button__inner--cancel">
                      <span className="button__text">No! Don’t want to cancel it.</span>
                    </span>
                  </button>
                </div>
                <div className="col-lg-6 text-lg-start">
                  <button className="button  text-white border-0 bg-transparent button--red button--getstarted">
                    <span className="button__inner">
                      <span className="button__text">Yes! Cancel the slot.</span>
                      <span className=" button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

    </Template>
  )
}