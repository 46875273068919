import React, { useEffect, useState } from 'react'
import Template from '../components/Dashboard/Template'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import { APP_CONFIG, axiosClient } from '../constants';

export default function OfferView() {
  const { uuid } = useParams();
  const [data, setData] = useState(null);
  const headers = { Authorization: "Token " + Cookies.get("USER_TOKEN") }

  useEffect(() => {
    if (uuid) {
      axiosClient.get("/main/merchant-offer/" + uuid, { headers }).then(resp => {
        setData(resp.data);
      }).catch(error => {
        console.log(error);
      })
    }
  }, []);
  return (
    <Template active="dashboard">
      <div className="dashboard-widget calendar-widget mt-md-5 py-md-5">
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Title :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.title} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Offer Type :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.offer_type ? data?.offer_type.substr(0, 1).toUpperCase() + "" + data?.offer_type?.substr(1, data.offer_type.length) : ''} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Offer :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.offer} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Country :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.country} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Offer Date :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.offer_date} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-text-outer">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <span className="fw700 fst-italic fz16 input-field__prefix">Offer Time :</span>
                    <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.offer_time} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="form-group h-100">
                <div className="input-text-outer h-100 p-0">
                  <div className="input-bg">
                    <div className="input-bg-inner" />
                  </div>
                  <div className="input-field">
                    <img src={APP_CONFIG.API_URL.replace("/api", "") + data?.image} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container mt-4'>
            <h4>Description :</h4>
            <div className="input-text-outer h-100 p-0">
              <div className="input-bg">
                <div className="input-bg-inner" />
              </div>
              <div className='p-4 position-relative' style={{ color: "#ffffff", zIndex: 1, inset: "1px" }} dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}