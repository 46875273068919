import React from 'react'
import Template from '../components/Dashboard/Template'

export default function Offers() {
  return (
    <Template active="offers" topHeading="Our Offers">
      <div className="slider-section__heading">
        <div className="left">
          <img src="./assets/images/offer-emoji.svg" alt="" />
          <h2 className="fw600 mb-0 fz18">Latest Offers</h2>
        </div>
      </div>
      <section className="offers">
        <ul className="list-inline offers-cards">
          <li>
            <a href="#" className="offer-card offer-card--red">
              <div className="w-100 gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner" />
                </div>
                <img
                  src="./assets/images/offers-bg-01.png"
                  alt=""
                  className="bg-img"
                />
                <div className="gradient-block-inner offer-card__inner">
                  <div className="ribbon-large ribbon-large--red">
                    <div className="fw500 fz14">E-star</div>
                    <div className="fw600 fz20">30</div>
                  </div>
                  <div className="offer-content">
                    <h1 className="fw600">
                      RED <br />
                      Offer
                    </h1>
                    <p className="fw400 fz12">Total Offers: 200</p>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--red">
                        POP Music
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--red">
                        Open Air
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--red">
                        Club Party
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#" className="offer-card offer-card--silver">
              <div className="w-100 gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner" />
                </div>
                <img
                  src="./assets/images/offers-bg-02.png"
                  alt=""
                  className="bg-img"
                />
                <div className="gradient-block-inner offer-card__inner">
                  <div className="ribbon-large ribbon-large--silver">
                    <div className="fw500 fz14">E-star</div>
                    <div className="fw600 fz20">30</div>
                  </div>
                  <div className="offer-content">
                    <h1 className="fw600">
                      SILVER <br />
                      Offer
                    </h1>
                    <p className="fw400 fz12">Total Offers: 200</p>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--silver">
                        POP Music
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--silver">
                        Open Air
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--silver">
                        Club Party
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#" className="offer-card offer-card--gold">
              <div className="w-100 gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner" />
                </div>
                <img
                  src="./assets/images/offers-bg-03.png"
                  alt=""
                  className="bg-img"
                />
                <div className="gradient-block-inner offer-card__inner">
                  <div className="ribbon-large ribbon-large--gold">
                    <div className="fw500 fz14">E-star</div>
                    <div className="fw600 fz20">30</div>
                  </div>
                  <div className="offer-content">
                    <h1 className="fw600">
                      GOLD <br />
                      Offer
                    </h1>
                    <p className="fw400 fz12">Total Offers: 200</p>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--gold">
                        POP Music
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--gold">
                        Open Air
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--gold">
                        Club Party
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#" className="offer-card offer-card--platinum">
              <div className="w-100 gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner" />
                </div>
                <img
                  src="./assets/images/offers-bg-04.png"
                  alt=""
                  className="bg-img"
                />
                <div className="gradient-block-inner offer-card__inner">
                  <div className="ribbon-large ribbon-large--platinum">
                    <div className="fw500 fz14">E-star</div>
                    <div className="fw600 fz20">30</div>
                  </div>
                  <div className="offer-content">
                    <h1 className="fw600">
                      PLATINUM <br />
                      Offer
                    </h1>
                    <p className="fw400 fz12">Total Offers: 200</p>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--platinum">
                        POP Music
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--platinum">
                        Open Air
                      </div>
                    </li>
                    <li>
                      <div className="text-nowrap tag tag-color tag-color--platinum">
                        Club Party
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div className="d-none d-lg-block offers-illustration">
          <img src="./assets/images/offers-illustration.svg" alt="" />
        </div>
      </section>
    </Template>
  )
}
