import React from 'react'
import { useState } from 'react'
import { axiosClient } from '../constants';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

export default function Merchant() {
  const [input, setInput] = useState({
    business_name: '',
    contact_name: '',
    designation: '',
    location: '',
    nature_of_business: '',
    offer: '',
    email: '',
    phone: '',
  })

  const onChangeHandl = (e) => {
    setInput(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const register = (e) => {
    e.preventDefault();
    const mData = {
      business_name: input.business_name,
      designation: input.designation,
      mobile_num: input.phone,
      nature_of_business: input.nature_of_business,
      contact_name: input.contact_name,
      location: input.location,
      email: input.email,
      offer: input.offer
    }

    axiosClient.post("/main/merchant", mData).then(resp => {
      toast.success("Your request submitted successfully. We will get back to you.")
      window.location.href = "/";
    }).catch(error => {
      console.log(error.response);
      toast.error("Error")
    })
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `$( ".input-field__countrycode" ).selectmenu();`;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div>
        <div className="bg-setup">
          <div className="blobs" />
        </div>
        <div className="main-page">
          <div className="container signup-documentation">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a href="/">
                  <img src="/assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <form className="mt-4 form signup-form" onSubmit={register}>

              <div className="auth-logo d-flex justify-content-center signup-logo-header">
                <img src="./assets/images/header-logo.svg" alt="logo" />
              </div>
              <div className="sign-up-header mt-2 text-center signup-text-header">
                <p className="fw500">
                  Help Us Identify You.
                </p>
              </div>
              <div className="sponsor sponsor-center">
                <div className="sponsor__left signup-5 d-block">
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" width={24} xmlSpace="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <g> <path fill="#fff" d="M470.537,137.504H41.471C18.565,137.504,0,156.077,0,178.976v56.797l211.507,44.607V252.1h87.772v28.28 L512,235.489v-56.513C512,156.077,493.435,137.504,470.537,137.504z" /> <path fill="#fff" d="M299.279,369.129h-87.772v-57.017L14.633,273.012V439.81c0,22.898,18.557,41.47,41.455,41.47h399.824 c22.898,0,41.463-18.572,41.463-41.47V272.721l-198.096,39.39V369.129z" /> <rect x="233.452" y="274.044" fill="#fff" width="43.882" height="73.132" /> <path fill="#fff" d="M193.786,72.206c0.008-1.703,0.638-3.057,1.75-4.208c1.127-1.103,2.49-1.718,4.176-1.734h112.577 c1.686,0.016,3.058,0.631,4.185,1.734c1.103,1.151,1.726,2.505,1.733,4.208v29.627h35.546V72.206 c0.008-11.41-4.665-21.875-12.143-29.329c-7.446-7.485-17.934-12.166-29.321-12.158H199.712 c-11.394-0.008-21.875,4.673-29.32,12.158c-7.47,7.454-12.158,17.918-12.135,29.329v29.627h35.529V72.206z" /> </g> </g></svg>
                      </span>
                      <input type="text" placeholder="Business Name" name='business_name' value={input.business_name} onChange={onChangeHandl} />
                    </div>
                  </div>
                  <div className="input-text-outer mb-3">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg height="24px" width="24px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill='#fff' d="M364.032,355.035c-3.862-1.446-8.072-3.436-12.35-5.794l-71.57,98.935l-5.09-64.814h-38.033l-5.091,64.814 l-71.569-98.935c-4.408,2.466-8.656,4.487-12.361,5.794c-37.478,13.193-129.549,51.136-123.607,122.21 C25.787,494.301,119.582,512,256.006,512c136.413,0,230.208-17.699,231.634-34.755 C493.583,406.102,401.273,368.961,364.032,355.035z"></path> <path fill='#fff' d="M171.501,208.271c5.21,29.516,13.966,55.554,25.494,68.38c0,15.382,0,26.604,0,35.587 c0,0.902-0.158,1.852-0.416,2.833l40.41,19.462v28.545h38.033v-28.545l40.39-19.452c-0.258-0.981-0.416-1.932-0.416-2.843 c0-8.983,0-20.205,0-35.587c11.548-12.826,20.304-38.864,25.514-68.38c12.143-4.338,19.096-11.281,27.762-41.658 c9.231-32.358-13.876-31.258-13.876-31.258c18.69-61.873-5.922-120.022-47.124-115.753c-28.426-49.73-123.627,11.36-153.48,7.102 c0,17.055,7.112,29.842,7.112,29.842c-10.379,19.69-6.378,58.951-3.446,78.809c-1.704-0.03-22.602,0.188-13.728,31.258 C152.405,196.99,159.338,203.934,171.501,208.271z"></path> </g> </g></svg>
                      </span>
                      <input type="text" placeholder="Designation" name="designation" value={input.designation} onChange={onChangeHandl} />
                    </div>
                  </div>
                  <div className="input-text-outer mb-3">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix"><img src="../assets/images/icons/phone.svg" /></span>
                      <span className="input-field__prefix">
                        <select className="input-field__countrycode">
                          <option data-countrycode="GB" value={44} selected>UK (+44)</option>
                          <option data-countrycode="US" value={1}>USA (+1)</option>
                          <option data-countrycode="DZ" value={213}>Algeria (+213)</option>
                          <option data-countrycode="AD" value={376}>Andorra (+376)</option>
                          <option data-countrycode="AO" value={244}>Angola (+244)</option>
                          <option data-countrycode="AI" value={1264}>Anguilla (+1264)</option>
                          <option data-countrycode="AG" value={1268}>Antigua &amp; Barbuda (+1268)</option>
                          <option data-countrycode="AR" value={54}>Argentina (+54)</option>
                          <option data-countrycode="AM" value={374}>Armenia (+374)</option>
                          <option data-countrycode="AW" value={297}>Aruba (+297)</option>
                          <option data-countrycode="AU" value={61}>Australia (+61)</option>
                          <option data-countrycode="AT" value={43}>Austria (+43)</option>
                          <option data-countrycode="AZ" value={994}>Azerbaijan (+994)</option>
                          <option data-countrycode="BS" value={1242}>Bahamas (+1242)</option>
                          <option data-countrycode="BH" value={973}>Bahrain (+973)</option>
                          <option data-countrycode="BD" value={880}>Bangladesh (+880)</option>
                          <option data-countrycode="BB" value={1246}>Barbados (+1246)</option>
                          <option data-countrycode="BY" value={375}>Belarus (+375)</option>
                          <option data-countrycode="BE" value={32}>Belgium (+32)</option>
                          <option data-countrycode="BZ" value={501}>Belize (+501)</option>
                          <option data-countrycode="BJ" value={229}>Benin (+229)</option>
                          <option data-countrycode="BM" value={1441}>Bermuda (+1441)</option>
                          <option data-countrycode="BT" value={975}>Bhutan (+975)</option>
                          <option data-countrycode="BO" value={591}>Bolivia (+591)</option>
                          <option data-countrycode="BA" value={387}>Bosnia Herzegovina (+387)</option>
                          <option data-countrycode="BW" value={267}>Botswana (+267)</option>
                          <option data-countrycode="BR" value={55}>Brazil (+55)</option>
                          <option data-countrycode="BN" value={673}>Brunei (+673)</option>
                          <option data-countrycode="BG" value={359}>Bulgaria (+359)</option>
                          <option data-countrycode="BF" value={226}>Burkina Faso (+226)</option>
                          <option data-countrycode="BI" value={257}>Burundi (+257)</option>
                          <option data-countrycode="KH" value={855}>Cambodia (+855)</option>
                          <option data-countrycode="CM" value={237}>Cameroon (+237)</option>
                          <option data-countrycode="CA" value={1}>Canada (+1)</option>
                          <option data-countrycode="CV" value={238}>Cape Verde Islands (+238)</option>
                          <option data-countrycode="KY" value={1345}>Cayman Islands (+1345)</option>
                          <option data-countrycode="CF" value={236}>Central African Republic (+236)</option>
                          <option data-countrycode="CL" value={56}>Chile (+56)</option>
                          <option data-countrycode="CN" value={86}>China (+86)</option>
                          <option data-countrycode="CO" value={57}>Colombia (+57)</option>
                          <option data-countrycode="KM" value={269}>Comoros (+269)</option>
                          <option data-countrycode="CG" value={242}>Congo (+242)</option>
                          <option data-countrycode="CK" value={682}>Cook Islands (+682)</option>
                          <option data-countrycode="CR" value={506}>Costa Rica (+506)</option>
                          <option data-countrycode="HR" value={385}>Croatia (+385)</option>
                          <option data-countrycode="CU" value={53}>Cuba (+53)</option>
                          <option data-countrycode="CY" value={90392}>Cyprus North (+90392)</option>
                          <option data-countrycode="CY" value={357}>Cyprus South (+357)</option>
                          <option data-countrycode="CZ" value={42}>Czech Republic (+42)</option>
                          <option data-countrycode="DK" value={45}>Denmark (+45)</option>
                          <option data-countrycode="DJ" value={253}>Djibouti (+253)</option>
                          <option data-countrycode="DM" value={1809}>Dominica (+1809)</option>
                          <option data-countrycode="DO" value={1809}>Dominican Republic (+1809)</option>
                          <option data-countrycode="EC" value={593}>Ecuador (+593)</option>
                          <option data-countrycode="EG" value={20}>Egypt (+20)</option>
                          <option data-countrycode="SV" value={503}>El Salvador (+503)</option>
                          <option data-countrycode="GQ" value={240}>Equatorial Guinea (+240)</option>
                          <option data-countrycode="ER" value={291}>Eritrea (+291)</option>
                          <option data-countrycode="EE" value={372}>Estonia (+372)</option>
                          <option data-countrycode="ET" value={251}>Ethiopia (+251)</option>
                          <option data-countrycode="FK" value={500}>Falkland Islands (+500)</option>
                          <option data-countrycode="FO" value={298}>Faroe Islands (+298)</option>
                          <option data-countrycode="FJ" value={679}>Fiji (+679)</option>
                          <option data-countrycode="FI" value={358}>Finland (+358)</option>
                          <option data-countrycode="FR" value={33}>France (+33)</option>
                          <option data-countrycode="GF" value={594}>French Guiana (+594)</option>
                          <option data-countrycode="PF" value={689}>French Polynesia (+689)</option>
                          <option data-countrycode="GA" value={241}>Gabon (+241)</option>
                          <option data-countrycode="GM" value={220}>Gambia (+220)</option>
                          <option data-countrycode="GE" value={7880}>Georgia (+7880)</option>
                          <option data-countrycode="DE" value={49}>Germany (+49)</option>
                          <option data-countrycode="GH" value={233}>Ghana (+233)</option>
                          <option data-countrycode="GI" value={350}>Gibraltar (+350)</option>
                          <option data-countrycode="GR" value={30}>Greece (+30)</option>
                          <option data-countrycode="GL" value={299}>Greenland (+299)</option>
                          <option data-countrycode="GD" value={1473}>Grenada (+1473)</option>
                          <option data-countrycode="GP" value={590}>Guadeloupe (+590)</option>
                          <option data-countrycode="GU" value={671}>Guam (+671)</option>
                          <option data-countrycode="GT" value={502}>Guatemala (+502)</option>
                          <option data-countrycode="GN" value={224}>Guinea (+224)</option>
                          <option data-countrycode="GW" value={245}>Guinea - Bissau (+245)</option>
                          <option data-countrycode="GY" value={592}>Guyana (+592)</option>
                          <option data-countrycode="HT" value={509}>Haiti (+509)</option>
                          <option data-countrycode="HN" value={504}>Honduras (+504)</option>
                          <option data-countrycode="HK" value={852}>Hong Kong (+852)</option>
                          <option data-countrycode="HU" value={36}>Hungary (+36)</option>
                          <option data-countrycode="IS" value={354}>Iceland (+354)</option>
                          <option selected data-countrycode="IN" value={91}>India (+91)</option>
                          <option data-countrycode="ID" value={62}>Indonesia (+62)</option>
                          <option data-countrycode="IR" value={98}>Iran (+98)</option>
                          <option data-countrycode="IQ" value={964}>Iraq (+964)</option>
                          <option data-countrycode="IE" value={353}>Ireland (+353)</option>
                          <option data-countrycode="IL" value={972}>Israel (+972)</option>
                          <option data-countrycode="IT" value={39}>Italy (+39)</option>
                          <option data-countrycode="JM" value={1876}>Jamaica (+1876)</option>
                          <option data-countrycode="JP" value={81}>Japan (+81)</option>
                          <option data-countrycode="JO" value={962}>Jordan (+962)</option>
                          <option data-countrycode="KZ" value={7}>Kazakhstan (+7)</option>
                          <option data-countrycode="KE" value={254}>Kenya (+254)</option>
                          <option data-countrycode="KI" value={686}>Kiribati (+686)</option>
                          <option data-countrycode="KP" value={850}>Korea North (+850)</option>
                          <option data-countrycode="KR" value={82}>Korea South (+82)</option>
                          <option data-countrycode="KW" value={965}>Kuwait (+965)</option>
                          <option data-countrycode="KG" value={996}>Kyrgyzstan (+996)</option>
                          <option data-countrycode="LA" value={856}>Laos (+856)</option>
                          <option data-countrycode="LV" value={371}>Latvia (+371)</option>
                          <option data-countrycode="LB" value={961}>Lebanon (+961)</option>
                          <option data-countrycode="LS" value={266}>Lesotho (+266)</option>
                          <option data-countrycode="LR" value={231}>Liberia (+231)</option>
                          <option data-countrycode="LY" value={218}>Libya (+218)</option>
                          <option data-countrycode="LI" value={417}>Liechtenstein (+417)</option>
                          <option data-countrycode="LT" value={370}>Lithuania (+370)</option>
                          <option data-countrycode="LU" value={352}>Luxembourg (+352)</option>
                          <option data-countrycode="MO" value={853}>Macao (+853)</option>
                          <option data-countrycode="MK" value={389}>Macedonia (+389)</option>
                          <option data-countrycode="MG" value={261}>Madagascar (+261)</option>
                          <option data-countrycode="MW" value={265}>Malawi (+265)</option>
                          <option data-countrycode="MY" value={60}>Malaysia (+60)</option>
                          <option data-countrycode="MV" value={960}>Maldives (+960)</option>
                          <option data-countrycode="ML" value={223}>Mali (+223)</option>
                          <option data-countrycode="MT" value={356}>Malta (+356)</option>
                          <option data-countrycode="MH" value={692}>Marshall Islands (+692)</option>
                          <option data-countrycode="MQ" value={596}>Martinique (+596)</option>
                          <option data-countrycode="MR" value={222}>Mauritania (+222)</option>
                          <option data-countrycode="YT" value={269}>Mayotte (+269)</option>
                          <option data-countrycode="MX" value={52}>Mexico (+52)</option>
                          <option data-countrycode="FM" value={691}>Micronesia (+691)</option>
                          <option data-countrycode="MD" value={373}>Moldova (+373)</option>
                          <option data-countrycode="MC" value={377}>Monaco (+377)</option>
                          <option data-countrycode="MN" value={976}>Mongolia (+976)</option>
                          <option data-countrycode="MS" value={1664}>Montserrat (+1664)</option>
                          <option data-countrycode="MA" value={212}>Morocco (+212)</option>
                          <option data-countrycode="MZ" value={258}>Mozambique (+258)</option>
                          <option data-countrycode="MN" value={95}>Myanmar (+95)</option>
                          <option data-countrycode="NA" value={264}>Namibia (+264)</option>
                          <option data-countrycode="NR" value={674}>Nauru (+674)</option>
                          <option data-countrycode="NP" value={977}>Nepal (+977)</option>
                          <option data-countrycode="NL" value={31}>Netherlands (+31)</option>
                          <option data-countrycode="NC" value={687}>New Caledonia (+687)</option>
                          <option data-countrycode="NZ" value={64}>New Zealand (+64)</option>
                          <option data-countrycode="NI" value={505}>Nicaragua (+505)</option>
                          <option data-countrycode="NE" value={227}>Niger (+227)</option>
                          <option data-countrycode="NG" value={234}>Nigeria (+234)</option>
                          <option data-countrycode="NU" value={683}>Niue (+683)</option>
                          <option data-countrycode="NF" value={672}>Norfolk Islands (+672)</option>
                          <option data-countrycode="NP" value={670}>Northern Marianas (+670)</option>
                          <option data-countrycode="NO" value={47}>Norway (+47)</option>
                          <option data-countrycode="OM" value={968}>Oman (+968)</option>
                          <option data-countrycode="PW" value={680}>Palau (+680)</option>
                          <option data-countrycode="PA" value={507}>Panama (+507)</option>
                          <option data-countrycode="PG" value={675}>Papua New Guinea (+675)</option>
                          <option data-countrycode="PY" value={595}>Paraguay (+595)</option>
                          <option data-countrycode="PE" value={51}>Peru (+51)</option>
                          <option data-countrycode="PH" value={63}>Philippines (+63)</option>
                          <option data-countrycode="PL" value={48}>Poland (+48)</option>
                          <option data-countrycode="PT" value={351}>Portugal (+351)</option>
                          <option data-countrycode="PR" value={1787}>Puerto Rico (+1787)</option>
                          <option data-countrycode="QA" value={974}>Qatar (+974)</option>
                          <option data-countrycode="RE" value={262}>Reunion (+262)</option>
                          <option data-countrycode="RO" value={40}>Romania (+40)</option>
                          <option data-countrycode="RU" value={7}>Russia (+7)</option>
                          <option data-countrycode="RW" value={250}>Rwanda (+250)</option>
                          <option data-countrycode="SM" value={378}>San Marino (+378)</option>
                          <option data-countrycode="ST" value={239}>Sao Tome &amp; Principe (+239)</option>
                          <option data-countrycode="SA" value={966}>Saudi Arabia (+966)</option>
                          <option data-countrycode="SN" value={221}>Senegal (+221)</option>
                          <option data-countrycode="CS" value={381}>Serbia (+381)</option>
                          <option data-countrycode="SC" value={248}>Seychelles (+248)</option>
                          <option data-countrycode="SL" value={232}>Sierra Leone (+232)</option>
                          <option data-countrycode="SG" value={65}>Singapore (+65)</option>
                          <option data-countrycode="SK" value={421}>Slovak Republic (+421)</option>
                          <option data-countrycode="SI" value={386}>Slovenia (+386)</option>
                          <option data-countrycode="SB" value={677}>Solomon Islands (+677)</option>
                          <option data-countrycode="SO" value={252}>Somalia (+252)</option>
                          <option data-countrycode="ZA" value={27}>South Africa (+27)</option>
                          <option data-countrycode="ES" value={34}>Spain (+34)</option>
                          <option data-countrycode="LK" value={94}>Sri Lanka (+94)</option>
                          <option data-countrycode="SH" value={290}>St. Helena (+290)</option>
                          <option data-countrycode="KN" value={1869}>St. Kitts (+1869)</option>
                          <option data-countrycode="SC" value={1758}>St. Lucia (+1758)</option>
                          <option data-countrycode="SD" value={249}>Sudan (+249)</option>
                          <option data-countrycode="SR" value={597}>Suriname (+597)</option>
                          <option data-countrycode="SZ" value={268}>Swaziland (+268)</option>
                          <option data-countrycode="SE" value={46}>Sweden (+46)</option>
                          <option data-countrycode="CH" value={41}>Switzerland (+41)</option>
                          <option data-countrycode="SI" value={963}>Syria (+963)</option>
                          <option data-countrycode="TW" value={886}>Taiwan (+886)</option>
                          <option data-countrycode="TJ" value={7}>Tajikstan (+7)</option>
                          <option data-countrycode="TH" value={66}>Thailand (+66)</option>
                          <option data-countrycode="TG" value={228}>Togo (+228)</option>
                          <option data-countrycode="TO" value={676}>Tonga (+676)</option>
                          <option data-countrycode="TT" value={1868}>Trinidad &amp; Tobago (+1868)</option>
                          <option data-countrycode="TN" value={216}>Tunisia (+216)</option>
                          <option data-countrycode="TR" value={90}>Turkey (+90)</option>
                          <option data-countrycode="TM" value={7}>Turkmenistan (+7)</option>
                          <option data-countrycode="TM" value={993}>Turkmenistan (+993)</option>
                          <option data-countrycode="TC" value={1649}>Turks &amp; Caicos Islands (+1649)</option>
                          <option data-countrycode="TV" value={688}>Tuvalu (+688)</option>
                          <option data-countrycode="UG" value={256}>Uganda (+256)</option>
                          {/* <option data-countryCode="GB" value="44">UK (+44)</option> */}
                          <option data-countrycode="UA" value={380}>Ukraine (+380)</option>
                          <option data-countrycode="AE" value={971}>United Arab Emirates (+971)</option>
                          <option data-countrycode="UY" value={598}>Uruguay (+598)</option>
                          {/* <option data-countryCode="US" value="1">USA (+1)</option> */}
                          <option data-countrycode="UZ" value={7}>Uzbekistan (+7)</option>
                          <option data-countrycode="VU" value={678}>Vanuatu (+678)</option>
                          <option data-countrycode="VA" value={379}>Vatican City (+379)</option>
                          <option data-countrycode="VE" value={58}>Venezuela (+58)</option>
                          <option data-countrycode="VN" value={84}>Vietnam (+84)</option>
                          <option data-countrycode="VG" value={84}>Virgin Islands - British (+1284)</option>
                          <option data-countrycode="VI" value={84}>Virgin Islands - US (+1340)</option>
                          <option data-countrycode="WF" value={681}>Wallis &amp; Futuna (+681)</option>
                          <option data-countrycode="YE" value={969}>Yemen (North)(+969)</option>
                          <option data-countrycode="YE" value={967}>Yemen (South)(+967)</option>
                          <option data-countrycode="ZM" value={260}>Zambia (+260)</option>
                          <option data-countrycode="ZW" value={263}>Zimbabwe (+263)</option>
                        </select>
                      </span>
                      <input type="text" placeholder="Phone Number" max={10} maxLength={10} name='phone' value={input.phone} onChange={onChangeHandl} />
                    </div>
                  </div>

                  <div className="input-text-outer mb-3">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" width={24} xmlSpace="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <g> <path fill="#fff" d="M470.537,137.504H41.471C18.565,137.504,0,156.077,0,178.976v56.797l211.507,44.607V252.1h87.772v28.28 L512,235.489v-56.513C512,156.077,493.435,137.504,470.537,137.504z" /> <path fill="#fff" d="M299.279,369.129h-87.772v-57.017L14.633,273.012V439.81c0,22.898,18.557,41.47,41.455,41.47h399.824 c22.898,0,41.463-18.572,41.463-41.47V272.721l-198.096,39.39V369.129z" /> <rect x="233.452" y="274.044" fill="#fff" width="43.882" height="73.132" /> <path fill="#fff" d="M193.786,72.206c0.008-1.703,0.638-3.057,1.75-4.208c1.127-1.103,2.49-1.718,4.176-1.734h112.577 c1.686,0.016,3.058,0.631,4.185,1.734c1.103,1.151,1.726,2.505,1.733,4.208v29.627h35.546V72.206 c0.008-11.41-4.665-21.875-12.143-29.329c-7.446-7.485-17.934-12.166-29.321-12.158H199.712 c-11.394-0.008-21.875,4.673-29.32,12.158c-7.47,7.454-12.158,17.918-12.135,29.329v29.627h35.529V72.206z" /> </g> </g></svg>
                      </span>
                      <input type="text" placeholder="Nature of business" name='nature_of_business' value={input.nature_of_business} onChange={onChangeHandl} />
                    </div>
                  </div>
                </div>
                <div className="sponsor__right align-items-baseline">
                  <div className="w-100 mx-auto sponsor__right__inner">
                    <div className="input-text-outer mb-3">
                      <div className="input-bg"><div className="input-bg-inner" /></div>
                      <div className="input-field">
                        <span className="input-field__prefix"><i className="icon icon-user" /></span>
                        <input type="text" placeholder="Contact Name" name='contact_name' value={input.contact_name} onChange={onChangeHandl} />
                      </div>
                    </div>

                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <svg width="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </span>
                        <input type="text" placeholder="Location" name='location' value={input.location} onChange={onChangeHandl} />
                      </div>
                    </div>

                    <div className="input-text-outer mb-3">
                      <div className="input-bg"><div className="input-bg-inner" /></div>
                      <div className="input-field">
                        <span className="input-field__prefix"><i className="icon icon-user" /></span>
                        <input type="text" placeholder="Email" name='email' value={input.email} onChange={onChangeHandl} />
                      </div>
                    </div>

                    <div className="input-text-outer mb-3">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <svg width="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m20.749 12 1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path></g></svg>
                        </span>
                        <input type="text" placeholder="Add Your Offer for ENTERX Members" name='offer' value={input.offer} onChange={onChangeHandl} />
                      </div>
                    </div>

                    <div className="input-button-wrapper">
                      <button className="input-button">
                        <span className="input-button__inner">
                          <span className="input-button__text">Continue</span>
                          <span className="input-button__icon-outer">
                            <span className="input-button__icon-inner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  )
}
