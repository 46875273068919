import React, { useEffect, useState } from 'react'
import Signup1 from '../components/Signup/Signup1'
import Signup2 from '../components/Signup/Signup2';
import Signup3 from '../components/Signup/Signup3';
import Signup4 from '../components/Signup/Signup4';
import Cookies from 'js-cookie';
import Signup7 from '../components/Signup/Signup7';
import { axiosClient } from '../constants';

export default function Signup() {
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(Cookies.get("USER_TOKEN"));

  useEffect(() => {
    getUserData();
  }, [])

  const getUserData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-user-details", { headers: { Authorization: "Token " + token } });
      setUserData(response.data.data);
      const mStep = (parseInt(response?.data?.data?.step) + 1);
      if(mStep === 5){
        window.location.href = "/pass";
      } else if(mStep > 5){
        window.location.href = "/dashboard";
      } else {
        setStep(mStep)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [step, setStep] = useState(userData?.step ? (parseInt(userData?.step) + 1) : 1);
  // const [step, setStep] = useState(4);
  
  const [input1, setInput1] = useState({
    "email": "",
    "phone": "",
    "referralEmail": "",
    "fName": '',
    "lName": '',
    "dob": '',
    "gender": "male",
    "account_type": "",
    "password": "",
    "rpassword": "",
  })

  return (
    <>
      {step == 1 && (<Signup4 setStep={setStep} input1={input1} setInput1={setInput1} />)}
      {step == 2 && (<Signup1 setStep={setStep} input1={input1} setInput1={setInput1} />)}
      {step == 3 && (<Signup2 setStep={setStep} setInput1={setInput1} input1={input1} />)}
      {step == 4 && (<Signup3 setStep={setStep} input1={input1} setToken={setToken} />)}
      {step == 7 && (<Signup7 setStep={setStep} token={token} />)}
    </>
  )
}
