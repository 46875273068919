import React from 'react'
import { axiosClient } from '../../constants';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default function Signup4({ setStep, setInput1 }) {

  return (
    <div>
      <div className="bg-setup bg-setup--login">
        <div className="blobs" />
      </div>
      <div className="main-page">
        <div className="container">
          <div className="top-btns">
            <div className="top-btns__left">
              <a className='cursor-pointer' href="/">
                <img src="/assets/images/icons/back-icon.svg" alt="" />
              </a>
            </div>
          </div>

          <div className="sponsor">
              <div className="sponsor__left position-relative sponsor__left1">
                <div className="cards123 cards123--signup">
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img1.png" alt="" />
                    </div>
                  </div>
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sponsor__right">
                <div className="onboarding-section-content">
                  <div className="mb-xxl-3 auth-logo">
                    <img className="mx-auto" src="../assets/images/header-logo.svg" alt="logo" />
                  </div>
                  <h3 className="mx-auto fw700">A Warm Welcome from EnterX!!</h3>
                  <div className="mx-auto content-onboarding-text">
                    <p className="my-0 fw500">
                      There are two ways to get register with us.
                    </p>
                  </div>
                  <div className="cust-btn text-center">
                    <button onClick={() => { setInput1(prev => ({ ...prev, account_type: "user" })); setStep(2); }} className="border-0 text-white button onboard-btn">
                      <span className="button__inner">
                        <span className="button-text">User</span>
                        <span className="button__iconwrapperouter">
                          <span className="button__iconwrapperinner">
                            <i className="icon icon-double-chevron" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="part-btn text-center">
                    <button onClick={() => { setInput1(prev => ({ ...prev, account_type: "independent_distributor" })); setStep(2); }} className="border-0 text-white button  onboard-btn">
                      <span className="button__inner">
                        <span className="button-text">Independent Distributor</span>
                        <span className=" button__iconwrapperouter">
                          <span className="button__iconwrapperinner">
                            <i className="icon icon-double-chevron" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="onboarding-step-indicator text-end d-none d-lg-block">
                    <div className="step-indicator">
                      <span />
                      <span className="active" />
                      <span />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
