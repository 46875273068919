import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../constants';
import { toast } from 'react-toastify';

const mScript1 = `
CKEDITOR.on("instanceCreated", function(event) {
  event.editor.on("change", function () {
      document.getElementById("my-ckeditor-value").value = (event.editor.getData());
  });
});
CKEDITOR.replace('editor', {
  uiColor: '#dadada',
  height: 100,
  toolbarLocation: 'top',
  allowedContent: true,
  fullPage: true,
  removePlugins: 'showborders',
  on: {
      instanceReady: function(ev) {
          this.dataProcessor.writer.setRules('p', {
              indent: false,
              breakBeforeOpen: true,
              breakAfterOpen: false,
              breakBeforeClose: false,
              breakAfterClose: true
          });
      }
  },
  toolbar: [{
          name: 'clipboard',
          groups: ['clipboard', 'undo'],
          items: ['Undo', 'Redo']
      },
      {
          name: 'document',
          groups: ['mode', 'document', 'doctools'],
          items: ['Source']
      },
      {
          name: 'styles',
          items: ['Format', 'Styles']
      },
      {
          name: 'basicstyles',
          groups: ['basicstyles', 'cleanup'],
          items: ['Bold', 'Italic', 'Underline', 'Strike', '-']
      },
      {
          name: 'colors',
          items: ['TextColor', 'BGColor']
      },
      {
          name: 'paragraph',
          items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight',
              'JustifyBlock', '-', 'Outdent', 'Indent', '-', 'BidiLtr', 'BidiRtl', '-', 'Blockquote'
          ]
      },
      {
          name: 'basicstyles',
          groups: ['basicstyles', 'cleanup'],
          items: ['Strike', '-', 'RemoveFormat']
      },
      {
          name: 'editing',
          groups: ['find', 'selection', 'spellchecker'],
          items: ['Scayt']
      },
      {
          name: 'links',
          items: ['Link', 'Unlink']
      },
      {
          name: 'insert',
          items: ['Smiley', 'Image', 'Table', 'HorizontalRule']
      },
      {
          name: 'tools',
          items: ['Maximize']
      },
      {
          name: 'others',
          items: ['-']
      },
  ],
});
`;

export default function SendEmail() {
  const [mTags, setMTags] = useState([]);
  const [unqmail, setUnqmail] = useState([]);
  const [tempMail, setTempMail] = useState([]);
  const [data, setData] = useState(null);
  const token = Cookies.get("USER_TOKEN");
  const [input, setInput] = useState({
    content: '',
    subject: ''
  })

  const getSponsorData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      let temp = [];
      response.data.data?.childs.forEach(ele1 => {
        temp.push(ele1.email);
        ele1.childs.forEach(ele2 => {
          temp.push(ele2.email);
        })
      })
      setTempMail(temp);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSponsorData();
  }, []);

  useEffect(() => {
    if (tempMail.length > 0) {
      [].forEach.call(document.getElementsByClassName("tags-input"), function (el) {
        let hiddenInput = document.createElement("input");
        let mainInput = document.createElement("input");
        let tags = [];
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("id", "hiddenemails");
        hiddenInput.setAttribute("name", "hiddenemails");
        mainInput.setAttribute("id", "multiemails");
        mainInput.setAttribute(
          "placeholder",
          " "
        );
        mainInput.setAttribute(
          "disabled",
          "true"
        );
        mainInput.setAttribute("type", "text");
        mainInput.classList.add("main-input");
        mainInput.addEventListener("input", function () {
          let enteredTags = mainInput.value.split(/[ ,;]+/);
          if (enteredTags.length > 1) {
            enteredTags.forEach(function (t) {
              let filteredTag = filterTag(t);
              if (filteredTag.length > 0) addTag(filteredTag);
            });
            mainInput.value = "";
          }
        });
        mainInput.addEventListener("keydown", function (e) {
          let keyCode = e.which || e.keyCode;
          if (keyCode === 8 && mainInput.value.length === 0 && tags.length > 0) {
            removeTag(tags.length - 1);
          }
          if (keyCode === 13 && mainInput.value.length > 0 && tags.length > 0) {
            addTag(mainInput.value);
            mainInput.value = "";
          }
        });
        el.appendChild(mainInput);
        el.appendChild(hiddenInput);
        tempMail.forEach(myEmail => {
          addTag(myEmail);
        })
        function addTag(email) {
          email = email.toLowerCase();
          if (validateEmail(email)) {
            let temp_unqmail = [...new Set(unqmail)];
            if (temp_unqmail.indexOf(email) == -1) {
              temp_unqmail.push(email);
              setUnqmail(temp_unqmail);
              let tag = {
                text: email,
                element: document.createElement("span")
              };
              tag.element.classList.add("tag");
              tag.element.textContent = tag.text;
              let closeBtn = document.createElement("span");
              closeBtn.classList.add("close");
              closeBtn.addEventListener("click", function () {
                removeTag(tags.indexOf(tag));
                let temp = mTags;
                temp.splice(temp.indexOf(tag), 1)
                setMTags(temp);
              });
              tag.element.appendChild(closeBtn);
              tags.push(tag);
              let temp = mTags;
              temp.push(tag);
              setMTags(temp);
              el.insertBefore(tag.element, mainInput);
              refreshTags();
            }
          } else {
            document.getElementById("multiemails").style.border = "solid 2px FF725E";
          }
        }
        function validateEmail(email) {
          return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        }
        function removeTag(index) {
          let tag = tags[index];
          tags.splice(index, 1);
          unqmail.splice(index, 1);
          el.removeChild(tag.element);
          refreshTags();
        }
        function refreshTags() {
          let tagsList = [];
          tags.forEach(function (t) {
            tagsList.push(t.text);
          });
          hiddenInput.value = tagsList.join(",");
        }
        function filterTag(tag) {
          return tag.replace(/[^a-z0-9\+\-\.\@\#\_]/gi, "");
        }
      });
    }
  }, [tempMail]);

  useEffect(() => {
    const script2 = document.createElement("script");
    script2.innerHTML = mScript1;
    document.body.append(script2);
  }, []);

  const sendMailFun = async (e) => {
    e.preventDefault();
    try {
      let mContent = document.getElementById("my-ckeditor-value").value;
      if (input.subject && mContent) {
        const data = {
          to_list: mTags.map(ele => ele.text).join(","),
          subject: input.subject,
          content: mContent,
        }
        const res = await axiosClient.post("/main/send-mail", data, { headers: { Authorization: "Token " + token } });
        toast.success("Mail Sent Successfully.");
        window.location.href = "/profile";
      } else {
        toast.error("Subject and Body Required!!!");
      }
    } catch (error) {
      toast.error("Internal Server Error!!!");
    }
  }

  return (
    <>
      <div>
        <div className="bg-setup bg-setup--login">
          <div className="blobs" />
        </div>
        <div className="main-page">
          <div className="container signup-documentation">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a href="/profile">
                  <img src="../assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="sendemail">
              <div className="sendemail__left">
                <img src="../assets/images/signup1.svg" alt="" />
              </div>
              <div className="sendemail__right">
                <form className="form" onSubmit={sendMailFun}>
                  <h1 className="page-title">Send Email</h1>
                  <div className="input-label">
                    To
                  </div>
                  <div className="input-text-outer input-tags-input-outer mb-4">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <div className="tags-input" id="home-tab-pane" />
                    </div>
                  </div>
                  <div className="input-label">
                    Subject
                  </div>
                  <div className="input-text-outer mb-4">
                    <div className="input-bg"><div className="input-bg-inner" /></div>
                    <div className="input-field">
                      <input type="text" placeholder="Subject" value={input.subject} onChange={(e) => setInput({ ...input, subject: e.target.value })} />
                    </div>
                  </div>
                  <div className="input-label">
                    Message
                  </div>
                  <input type="hidden" id="my-ckeditor-value" />
                  <div className="ckeditor-wrapper mb-4">
                    <textarea id="editor" type="text" placeholder="Message" defaultValue={""} />
                  </div>
                  <div className="input-button-wrapper input-button-wrapper--sendemail">
                    <button className="input-button ">
                      <span className="input-button__inner">
                        <span className="input-button__text">Send Email</span>
                        <span className=" input-button__icon-outer">
                          <span className="input-button__icon-inner">
                            <i className="icon icon-double-chevron-dark" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
