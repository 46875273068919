import Cookies from 'js-cookie';
import React from 'react'

export default function AdminTemplate({ children, active }) {

  const logout = () => {
    Cookies.remove("ADMIN_DATA");
    Cookies.remove("ADMIN_TOKEN");
    window.location.href = "/";
  }

  return (
    <>
      <div className="bg-setup">
        <div className="blobs" />
      </div>

      {/* SideBar */}
      <div className="gradient-block-outer admin-sidebar sidebar-bg-none sidebar">
        <div className="gradient-block-bg gradient-block-bg--verticalCard">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner sidebar__inner">
          <div className="logo-small">
            <img src="/assets/images/logo-small.svg" alt="Logo small image" />
          </div>
          <ul className="list-unstyled">
            <li>
              <a href="/admin/dashboard" className={`app-links ${active == "dashboard" ? 'active' : ''}`}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Dashboard">
                <svg width={23} height={24} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className="fill-icon" d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="/admin/verify" className={`app-links ${active == "verify" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Levels">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.9375 1.75H3.9375C2.7125 1.75 1.75 2.7125 1.75 3.9375V10.9375C1.75 12.1625 2.7125 13.125 3.9375 13.125H10.9375C12.1625 13.125 13.125 12.1625 13.125 10.9375V3.9375C13.125 2.7125 12.1625 1.75 10.9375 1.75ZM11.375 10.9375C11.375 11.2 11.2 11.375 10.9375 11.375H3.9375C3.675 11.375 3.5 11.2 3.5 10.9375V3.9375C3.5 3.675 3.675 3.5 3.9375 3.5H10.9375C11.2 3.5 11.375 3.675 11.375 3.9375V10.9375Z" fill="white" />
                  <path d="M8.75 5.25H6.125C5.6 5.25 5.25 5.6 5.25 6.125V8.75C5.25 9.275 5.6 9.625 6.125 9.625H8.75C9.275 9.625 9.625 9.275 9.625 8.75V6.125C9.625 5.6 9.275 5.25 8.75 5.25ZM7.875 7.875H7V7H7.875V7.875ZM24.0625 1.75H17.0625C15.8375 1.75 14.875 2.7125 14.875 3.9375V10.9375C14.875 12.1625 15.8375 13.125 17.0625 13.125H24.0625C25.2875 13.125 26.25 12.1625 26.25 10.9375V3.9375C26.25 2.7125 25.2875 1.75 24.0625 1.75ZM24.5 10.9375C24.5 11.2 24.325 11.375 24.0625 11.375H17.0625C16.8 11.375 16.625 11.2 16.625 10.9375V3.9375C16.625 3.675 16.8 3.5 17.0625 3.5H24.0625C24.325 3.5 24.5 3.675 24.5 3.9375V10.9375Z" fill="white" />
                  <path d="M21.875 5.25H19.25C18.725 5.25 18.375 5.6 18.375 6.125V8.75C18.375 9.275 18.725 9.625 19.25 9.625H21.875C22.4 9.625 22.75 9.275 22.75 8.75V6.125C22.75 5.6 22.4 5.25 21.875 5.25ZM21 7.875H20.125V7H21V7.875ZM1.75 24.0625C1.75 25.2875 2.7125 26.25 3.9375 26.25H10.9375C12.1625 26.25 13.125 25.2875 13.125 24.0625V17.0625C13.125 15.8375 12.1625 14.875 10.9375 14.875H3.9375C2.7125 14.875 1.75 15.8375 1.75 17.0625V24.0625ZM3.5 17.0625C3.5 16.8 3.675 16.625 3.9375 16.625H10.9375C11.2 16.625 11.375 16.8 11.375 17.0625V24.0625C11.375 24.325 11.2 24.5 10.9375 24.5H3.9375C3.675 24.5 3.5 24.325 3.5 24.0625V17.0625Z" fill="white" />
                  <path d="M6.125 22.75H8.75C9.275 22.75 9.625 22.4 9.625 21.875V19.25C9.625 18.725 9.275 18.375 8.75 18.375H6.125C5.6 18.375 5.25 18.725 5.25 19.25V21.875C5.25 22.4 5.6 22.75 6.125 22.75ZM7 20.125H7.875V21H7V20.125ZM24.0625 14.875H17.0625C15.8375 14.875 14.875 15.8375 14.875 17.0625V24.0625C14.875 25.2875 15.8375 26.25 17.0625 26.25H24.0625C25.2875 26.25 26.25 25.2875 26.25 24.0625V17.0625C26.25 15.8375 25.2875 14.875 24.0625 14.875ZM24.5 24.0625C24.5 24.325 24.325 24.5 24.0625 24.5H17.0625C16.8 24.5 16.625 24.325 16.625 24.0625V17.0625C16.625 16.8 16.8 16.625 17.0625 16.625H24.0625C24.325 16.625 24.5 16.8 24.5 17.0625V24.0625Z" fill="white" />
                  <path d="M21.875 18.375H19.25C18.725 18.375 18.375 18.725 18.375 19.25V21.875C18.375 22.4 18.725 22.75 19.25 22.75H21.875C22.4 22.75 22.75 22.4 22.75 21.875V19.25C22.75 18.725 22.4 18.375 21.875 18.375ZM21 21H20.125V20.125H21V21Z" fill="white" />
                </svg>
              </a>
            </li>
            <li>
              <a href="/admin/withdrawal" className={`app-links ${active == "withdrawal" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Levels">
                <svg viewBox="0 0 24 24" width="32" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                  <path className={`${active == "withdrawal" ? 'fill-icon' : ''}`} fill-rule="evenodd" clip-rule="evenodd" d="M21.1009 8.00353C21.0442 7.99996 20.9825 7.99998 20.9186 8L20.9026 8.00001H18.3941C16.3264 8.00001 14.5572 9.62757 14.5572 11.75C14.5572 13.8724 16.3264 15.5 18.3941 15.5H20.9026L20.9186 15.5C20.9825 15.5 21.0442 15.5001 21.1009 15.4965C21.9408 15.4434 22.6835 14.7862 22.746 13.8682C22.7501 13.808 22.75 13.7431 22.75 13.683L22.75 13.6667V9.83334L22.75 9.81702C22.75 9.75688 22.7501 9.69199 22.746 9.6318C22.6835 8.71381 21.9408 8.05657 21.1009 8.00353ZM18.1717 12.75C18.704 12.75 19.1355 12.3023 19.1355 11.75C19.1355 11.1977 18.704 10.75 18.1717 10.75C17.6394 10.75 17.2078 11.1977 17.2078 11.75C17.2078 12.3023 17.6394 12.75 18.1717 12.75Z" fill="#fff"></path>
                  <path className={`${active == "withdrawal" ? 'fill-icon' : ''}`} fill-rule="evenodd" clip-rule="evenodd" d="M20.9179 17C21.067 16.9961 21.1799 17.1342 21.1394 17.2778C20.9387 17.9902 20.62 18.5975 20.1088 19.1088C19.3604 19.8571 18.4114 20.1892 17.239 20.3469C16.0998 20.5 14.6442 20.5 12.8064 20.5H10.6936C8.85583 20.5 7.40019 20.5 6.26098 20.3469C5.08856 20.1892 4.13961 19.8571 3.39124 19.1088C2.64288 18.3604 2.31076 17.4114 2.15314 16.239C1.99997 15.0998 1.99998 13.6442 2 11.8064V11.6936C1.99998 9.85583 1.99997 8.40019 2.15314 7.26098C2.31076 6.08856 2.64288 5.13961 3.39124 4.39124C4.13961 3.64288 5.08856 3.31076 6.26098 3.15314C7.40019 2.99997 8.85582 2.99998 10.6936 3L12.8064 3C14.6442 2.99998 16.0998 2.99997 17.239 3.15314C18.4114 3.31076 19.3604 3.64288 20.1088 4.39124C20.62 4.90252 20.9386 5.50974 21.1394 6.22218C21.1799 6.36575 21.067 6.50387 20.9179 6.5L18.394 6.50001C15.5574 6.50001 13.0571 8.74091 13.0571 11.75C13.0571 14.7591 15.5574 17 18.394 17L20.9179 17ZM7 15.5C6.58579 15.5 6.25 15.1642 6.25 14.75V8.75C6.25 8.33579 6.58579 8 7 8C7.41421 8 7.75 8.33579 7.75 8.75V14.75C7.75 15.1642 7.41421 15.5 7 15.5Z" fill="#fff"></path>
                </g>
                </svg>
              </a>
            </li>
            <li>
              <a href="/admin/merchant" className={`app-links ${active == "merchant" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Levels">
                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#ffffff" stroke-width="1.5"></path>
                    <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M17 11V10C17 8.11438 17 7.17157 16.4142 6.58579C15.8284 6 14.8856 6 13 6H11C9.11438 6 8.17157 6 7.58579 6.58579C7 7.17157 7 8.11438 7 10V11" stroke="#ffffff" stroke-width="1.5"></path>
                    <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M5 11H19" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                    <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M8 16H16" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <a href="/admin/offer" className={`app-links ${active == "offer" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Levels">
                <svg width="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 9.3345 19.8412 6.93964 18 5.29168M8 16L16 8M17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke={`${active == "offer" ? 'red' : '#ffffff'}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className={`dashboard dashboard1 ps-0 py-lg-5`}>
        <div className="dashboard-top-btn-row pt-lg-5 mt-lg-3">
          {/* Top actions btn row */}
          <div className="top-btns top-btns--withSidebar">
            <div className="gradient-block-bg">
              <div className="gradient-block-bg-inner"></div>
            </div>
            <div className='gradient-block-inner'>
              <div className="top-btns__left">
                <div className="logo-small me-2">
                  <div className="logo-small__bg1">
                    <div className="logo-small__bg2">
                      <img src="/assets/images/logo-small.svg" alt="Logo small image" />
                    </div>
                  </div>
                </div>
                <h2 className="d-none d-lg-block text-white mb-0 fz20 fw600">Dashboard</h2>
              </div>

              <ul className="nav-links">
                <li>
                  <a href="/admin/dashboard" className={`app-links ${active == "dashboard" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Dashboard">
                    <svg width={23} height={24} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z" className="fill-icon" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="/admin/verify" className={`app-links ${active == "verify" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Dashboard">
                    <svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${active == "verify" ? 'fill-icon' : ''}`} d="M10.9375 1.75H3.9375C2.7125 1.75 1.75 2.7125 1.75 3.9375V10.9375C1.75 12.1625 2.7125 13.125 3.9375 13.125H10.9375C12.1625 13.125 13.125 12.1625 13.125 10.9375V3.9375C13.125 2.7125 12.1625 1.75 10.9375 1.75ZM11.375 10.9375C11.375 11.2 11.2 11.375 10.9375 11.375H3.9375C3.675 11.375 3.5 11.2 3.5 10.9375V3.9375C3.5 3.675 3.675 3.5 3.9375 3.5H10.9375C11.2 3.5 11.375 3.675 11.375 3.9375V10.9375Z" fill="white" />
                      <path className={`${active == "verify" ? 'fill-icon' : ''}`} d="M8.75 5.25H6.125C5.6 5.25 5.25 5.6 5.25 6.125V8.75C5.25 9.275 5.6 9.625 6.125 9.625H8.75C9.275 9.625 9.625 9.275 9.625 8.75V6.125C9.625 5.6 9.275 5.25 8.75 5.25ZM7.875 7.875H7V7H7.875V7.875ZM24.0625 1.75H17.0625C15.8375 1.75 14.875 2.7125 14.875 3.9375V10.9375C14.875 12.1625 15.8375 13.125 17.0625 13.125H24.0625C25.2875 13.125 26.25 12.1625 26.25 10.9375V3.9375C26.25 2.7125 25.2875 1.75 24.0625 1.75ZM24.5 10.9375C24.5 11.2 24.325 11.375 24.0625 11.375H17.0625C16.8 11.375 16.625 11.2 16.625 10.9375V3.9375C16.625 3.675 16.8 3.5 17.0625 3.5H24.0625C24.325 3.5 24.5 3.675 24.5 3.9375V10.9375Z" fill="white" />
                      <path className={`${active == "verify" ? 'fill-icon' : ''}`} d="M21.875 5.25H19.25C18.725 5.25 18.375 5.6 18.375 6.125V8.75C18.375 9.275 18.725 9.625 19.25 9.625H21.875C22.4 9.625 22.75 9.275 22.75 8.75V6.125C22.75 5.6 22.4 5.25 21.875 5.25ZM21 7.875H20.125V7H21V7.875ZM1.75 24.0625C1.75 25.2875 2.7125 26.25 3.9375 26.25H10.9375C12.1625 26.25 13.125 25.2875 13.125 24.0625V17.0625C13.125 15.8375 12.1625 14.875 10.9375 14.875H3.9375C2.7125 14.875 1.75 15.8375 1.75 17.0625V24.0625ZM3.5 17.0625C3.5 16.8 3.675 16.625 3.9375 16.625H10.9375C11.2 16.625 11.375 16.8 11.375 17.0625V24.0625C11.375 24.325 11.2 24.5 10.9375 24.5H3.9375C3.675 24.5 3.5 24.325 3.5 24.0625V17.0625Z" fill="white" />
                      <path className={`${active == "verify" ? 'fill-icon' : ''}`} d="M6.125 22.75H8.75C9.275 22.75 9.625 22.4 9.625 21.875V19.25C9.625 18.725 9.275 18.375 8.75 18.375H6.125C5.6 18.375 5.25 18.725 5.25 19.25V21.875C5.25 22.4 5.6 22.75 6.125 22.75ZM7 20.125H7.875V21H7V20.125ZM24.0625 14.875H17.0625C15.8375 14.875 14.875 15.8375 14.875 17.0625V24.0625C14.875 25.2875 15.8375 26.25 17.0625 26.25H24.0625C25.2875 26.25 26.25 25.2875 26.25 24.0625V17.0625C26.25 15.8375 25.2875 14.875 24.0625 14.875ZM24.5 24.0625C24.5 24.325 24.325 24.5 24.0625 24.5H17.0625C16.8 24.5 16.625 24.325 16.625 24.0625V17.0625C16.625 16.8 16.8 16.625 17.0625 16.625H24.0625C24.325 16.625 24.5 16.8 24.5 17.0625V24.0625Z" fill="white" />
                      <path className={`${active == "verify" ? 'fill-icon' : ''}`} d="M21.875 18.375H19.25C18.725 18.375 18.375 18.725 18.375 19.25V21.875C18.375 22.4 18.725 22.75 19.25 22.75H21.875C22.4 22.75 22.75 22.4 22.75 21.875V19.25C22.75 18.725 22.4 18.375 21.875 18.375ZM21 21H20.125V20.125H21V21Z" fill="white" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="/admin/withdrawal" className={`app-links  ${active == "withdrawal" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Withdrawal">
                    <svg viewBox="0 0 24 24" width="32" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path className={`${active == "withdrawal" ? 'fill-icon' : ''}`} fill-rule="evenodd" clip-rule="evenodd" d="M21.1009 8.00353C21.0442 7.99996 20.9825 7.99998 20.9186 8L20.9026 8.00001H18.3941C16.3264 8.00001 14.5572 9.62757 14.5572 11.75C14.5572 13.8724 16.3264 15.5 18.3941 15.5H20.9026L20.9186 15.5C20.9825 15.5 21.0442 15.5001 21.1009 15.4965C21.9408 15.4434 22.6835 14.7862 22.746 13.8682C22.7501 13.808 22.75 13.7431 22.75 13.683L22.75 13.6667V9.83334L22.75 9.81702C22.75 9.75688 22.7501 9.69199 22.746 9.6318C22.6835 8.71381 21.9408 8.05657 21.1009 8.00353ZM18.1717 12.75C18.704 12.75 19.1355 12.3023 19.1355 11.75C19.1355 11.1977 18.704 10.75 18.1717 10.75C17.6394 10.75 17.2078 11.1977 17.2078 11.75C17.2078 12.3023 17.6394 12.75 18.1717 12.75Z" fill="#fff"></path>
                      <path className={`${active == "withdrawal" ? 'fill-icon' : ''}`} fill-rule="evenodd" clip-rule="evenodd" d="M20.9179 17C21.067 16.9961 21.1799 17.1342 21.1394 17.2778C20.9387 17.9902 20.62 18.5975 20.1088 19.1088C19.3604 19.8571 18.4114 20.1892 17.239 20.3469C16.0998 20.5 14.6442 20.5 12.8064 20.5H10.6936C8.85583 20.5 7.40019 20.5 6.26098 20.3469C5.08856 20.1892 4.13961 19.8571 3.39124 19.1088C2.64288 18.3604 2.31076 17.4114 2.15314 16.239C1.99997 15.0998 1.99998 13.6442 2 11.8064V11.6936C1.99998 9.85583 1.99997 8.40019 2.15314 7.26098C2.31076 6.08856 2.64288 5.13961 3.39124 4.39124C4.13961 3.64288 5.08856 3.31076 6.26098 3.15314C7.40019 2.99997 8.85582 2.99998 10.6936 3L12.8064 3C14.6442 2.99998 16.0998 2.99997 17.239 3.15314C18.4114 3.31076 19.3604 3.64288 20.1088 4.39124C20.62 4.90252 20.9386 5.50974 21.1394 6.22218C21.1799 6.36575 21.067 6.50387 20.9179 6.5L18.394 6.50001C15.5574 6.50001 13.0571 8.74091 13.0571 11.75C13.0571 14.7591 15.5574 17 18.394 17L20.9179 17ZM7 15.5C6.58579 15.5 6.25 15.1642 6.25 14.75V8.75C6.25 8.33579 6.58579 8 7 8C7.41421 8 7.75 8.33579 7.75 8.75V14.75C7.75 15.1642 7.41421 15.5 7 15.5Z" fill="#fff"></path>
                    </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="/admin/merchant" className={`app-links  ${active == "merchant" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="merchant">
                    <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#ffffff" stroke-width="1.5"></path>
                        <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M17 11V10C17 8.11438 17 7.17157 16.4142 6.58579C15.8284 6 14.8856 6 13 6H11C9.11438 6 8.17157 6 7.58579 6.58579C7 7.17157 7 8.11438 7 10V11" stroke="#ffffff" stroke-width="1.5"></path>
                        <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M5 11H19" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                        <path className={`${active == "merchant" ? 'fill-icon' : ''}`} d="M8 16H16" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="/admin/offer" className={`app-links  ${active == "offer" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="merchant">
                    <svg width="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 9.3345 19.8412 6.93964 18 5.29168M8 16L16 8M17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke={`${active == "offer" ? 'red' : '#ffffff'}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </a>
                </li>

              </ul>

              <div className="top-btns__right">
                <div className="user cursor-pointer">
                  <div className="position-relative rounded-circle user__img">
                    <div className="rounded-circle bg-white w-100 h-100 fz20 fw600 initials">
                      A
                    </div>
                    <div className="rounded-circle dot" />
                  </div>
                  <div className="user__info">
                    <h3 className="fw600 fz20 mb-0 text-white"></h3>
                    Admin
                  </div>
                </div>
                <a href="#" className="notify">
                  <div className="rounded-circle dot" />
                  <img src="/assets/images/icons/bell-icon.svg" alt="" />
                </a>
                <a onClick={(e) => { e.preventDefault(); logout(); }} className="logout cursor-pointer">
                  <img src="/assets/images/icons/logout-icon.svg" alt="" />
                </a>
              </div>
            </div>
          </div>

          {children}

        </div>

      </div>

      <footer className="footer footer2 mt-2 text-white">
        <div className="container">
          <div className="footer-top-strip" />
          <div className="row">
            <div className="col-12 order-3 order-lg-1 col-sm-12 col-lg-3 mb-4 mb-lg-0">
              <div className="footer__logo">
                <img src="/assets/images/footer-logo.svg" alt="..." />
              </div>
              {/* <div className="footer__txt">
                YOU GET TO SKIP THE HUSSEL OF PAYING COVER CHARGES OR STANDING IN
                LONG QUES OR BEING REJECTED ENTRY IN CLUB TITOS, the No. 1 Club of
                Goa, FOR THE NEXT 10 YEARS.
              </div> */}
              <div className="footer__followicons">
                {/* <div className="text-center">
                  <h3 className="mb-3">Follow Us:</h3>
                </div> */}
                <div className="row">
                  <div className="col-6 text-end text-lg-center">
                    <a className="facebook__social text-white" href><i className="icon-fb" /> <span>Facebook</span></a>
                  </div>
                  <div className="col-6 text-start text-lg-center">
                    <a className="facebook__social text-white" href><i className="icon-tw" /> <span>Twitter</span></a>
                  </div>
                  <div className="col-6 text-end text-lg-center">
                    <a className="facebook__social text-white" href><i className="icon-li" /> <span>LinkedIn</span></a>
                  </div>
                  <div className="col-6 text-start text-lg-center">
                    <a className="facebook__social text-white" href><i className="icon-in" /> <span>Instagram</span></a>
                  </div>
                </div>
              </div>
              <div className="footer__copytxt">
                © Copyright 2023. All right reserved.
              </div>
            </div>
            <div className="col-12 order-1 order-lg-2 col-md-6 col-lg-6 ps-lg-5 mb-3 mb-lg-0">
              <h3 className="footer__appbtnstitle text-center d-md-none">Subscribe to our Newsletters</h3>
              <div className="footer__subscribewrapper">
                <form>
                  <div className="footer__subscribe">
                    <input ype="email" required placeholder="Your email address" />
                    <button type="submit">Subscribe</button>
                  </div>
                </form>
              </div>
              <div className="footer__links">
                <div className="row">
                  <div className="col">
                    <ul>
                      <li><a href>How it works?</a></li>
                      <li><a href>Refer Earn</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul>
                      <li><a href>Privacy Policy</a></li>
                      <li><a href>Terms and Conditions</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 order-2 order-lg-3 col-md-6 col-lg-3 mb-3 mb-lg-0">
              <div className="footer__appbtns">
                <h3 className="footer__appbtnstitle text-center">Download our app</h3>
                <div className="row">
                  <div className="col-6 col-md-12 text-end text-md-center">
                    <a className="footer__appbtn-gplay" href />
                  </div>
                  <div className="col-6 col-md-12 text-start text-md-center">
                    <a className="footer__appbtn-appstore" href />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}