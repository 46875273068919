import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../constants';

export default function Signup7({ setStep, input1, setInput1, setJoinNowFlag }) {
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);

  useEffect(()=>{
    axiosClient.get("/accounts/get-public-sponsor").then(resp=>{
      setData(resp.data.data);
    }).catch(err=>{
      console.log(err);
    })
  },[])

  const onSubmit = (e) => {
    e.preventDefault();
    setInput1({ ...input1, referralEmail: selected });
    setStep(3);
  }

  console.log(selected);
  return (
    <div>
      <div className="bg-setup bg-setup--login">
        <div className="blobs" />
      </div>
      <div className="main-page">
        <div className="container signup-documentation">
          {/* Top actions btn row */}
          <div className="top-btns">
            <div className="top-btns__left">
              <a href="/signup" className>
                <img src="../assets/images/icons/back-icon.svg" alt="" />
              </a>
            </div>
          </div>
          {/* ===================== */}
          <div className="sponsor sponsor-center">
            <div className="sponsor__left signup-5">
              <div className="cards123 cards123--signup">
                <div className="cards123__single">
                  <div className="cards123__bg" />
                  <div className="cards123__img">
                    <div className="cards123__bg-inner" />
                    <img src="../assets/images/signup/img3.png" alt="" />
                  </div>
                </div>
                <div className="cards123__single">
                  <div className="cards123__bg" />
                  <div className="cards123__img">
                    <div className="cards123__bg-inner" />
                    <img src="../assets/images/signup/img4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor__right">
              <div className="w-100 mx-auto sponsor__right__inner">
                <div className="auth-logo mb-3 signup-logo-header ">
                  <img className src="../assets/images/header-logo.svg" alt="logo" />
                </div>
                <div className="sign-up-header signup-text-header">
                  <p className="fw500">
                    EnterX provides you with 3 Sponsors who you can choose from and they will guide you to achieve your goals.
                  </p>
                </div>
                <form className="form signup-form" onSubmit={onSubmit}>
                  {
                    data.map(ele => {
                      return (
                        <div className="input-text-outer mb-4">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix">
                              <img className="me-3" src="../assets/images/icons/user.svg" alt="#" />
                              <span>{ele.first_name} {ele.last_name}</span></span>
                            <span className="input-field__suffix">
                              <div className="gender-checkboxes d-flex justify-content-end">
                                <div className="check-container male-check">
                                  <label className=" ">
                                    <input type="radio" name="sponsors" value={ele.email} checked={ele.email === selected} onChange={(e) => setSelected(e.target.value)} />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      );
                    })
                  }
                  {/* <div className="input-text-outer mb-4">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix">
                        <img className="me-3" src="../assets/images/icons/user.svg" alt="#" />
                        <span>Amar</span></span>
                      <span className="input-field__suffix">
                        <div className="gender-checkboxes d-flex justify-content-end">
                          <div className="check-container male-check">
                            <label className=" ">
                              <input type="radio" name="sponsors" defaultValue="Amar" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="input-text-outer mb-4">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix"><img className="me-3" src="../assets/images/icons/user.svg" alt="#" /> <span>Akhbar</span></span>
                      <span className="input-field__suffix">
                        <div className="gender-checkboxes d-flex justify-content-end">
                          <div className="check-container male-check">
                            <label className=" ">
                              <input type="radio" name="sponsors" defaultValue="Akhbar" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="input-text-outer mb-5">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="input-field__prefix"><img className="me-3" src="../assets/images/icons/user.svg" alt="#" /> <span>Anthony</span></span>
                      <span className="input-field__suffix">
                        <div className="gender-checkboxes d-flex justify-content-end">
                          <div className="check-container male-check">
                            <label className=" ">
                              <input type="radio" name="sponsors" defaultValue="Anthony" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div> */}
                  <div className="input-button-wrapper">
                    <button type='submit' className="input-button ">
                      <span className="input-button__inner">
                        <span className="input-button__text">Continue</span>
                        <span className="input-button__icon-outer ">
                          <span className="input-button__icon-inner">
                            <i className="icon icon-double-chevron-dark" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
