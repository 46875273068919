import { toast } from "react-toastify";
import abiMain from "../abis/Enterx.json";
import abiUSDT from "../abis/USDT.json";
import axiosClient from "./axiosClient";

const APP_CONFIG = {
  API_URL: "https://enterxapi.demosimplicity.com/api",
  // SC_MAIN: "0xC639BF25E44B90eA77D0215d2Fa79B04Fc0A6548",
  SC_MAIN: "0xB52559fCdC7BB3B2FE6dF50FA1A864B363536526",
  SC_MAIN_ABI: abiMain,
  SC_USDT: "0x2e6F649d16d77d59c49649687f9D6CdD25FDeBED",
  SC_USDT_ABI: abiUSDT,
  SC_ESTAR: "0x0c6a4B3182279A381E390a9a990e61f40DB58841",
  SC_ESTAR_ABI: abiUSDT,
}

const unsecuredCopyToClipboard = (text) => { 
  const textArea = document.createElement("textarea"); 
  textArea.value=text; 
  document.body.appendChild(textArea); 
  textArea.focus();
  textArea.select(); 
  try{
    document.execCommand('copy')
  } catch(err){
    console.error('Unable to copy to clipboard',err)
  }
  document.body.removeChild(textArea)
};

const copyContent = (text) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    unsecuredCopyToClipboard(text);
  }
  toast.success('Text Copy Successfully.');
}

const formatAddr = (data) => {
  return data && data.substr(0, 4) + "..." + data.substr(data.length - 5, data.length);
}

export { APP_CONFIG, axiosClient, copyContent, formatAddr };