import React from 'react'
import { toast } from 'react-toastify';

export default function LevelSilver({ data, slotData }) {

  const checkLevel = () => {
    if (data.childs.length >= 25) {
      window.location.href = "/book-slot"
    } else {
      toast.error('You are Red Level member. To book a slot in Silver you should have minimum 25 direct sales.')
    }
  }

  return (
    <div className="dashboard-widget dashboard-widget--levels">
      <div className="dashboard-widget__left levels-left pt-0">
        <div className="level-section">
          <h3 className="fw700">About SILVER</h3>
        </div>
        <div className="level-description">
          <p className="fw400">With the Silver Pass, not only you should get earnings up to $5,000 in direct sales, but you also get a 'Red Carpet Entry' access to the most prestigious events and venues.
          </p>
          <p className="fw400 mt-0">But that's not all - the Silver Pass knows how to celebrate! Members receive one complimentary Bottle of Champagne at Club Tito's, every year. You can toast to your accomplishments, milestones, and unforgettable moments while enjoying the taste of this luxurious beverage.
          </p>
          <p className="fw400 mt-0">By making sales to friends, you can attend exciting events in Dubai and even get free entry to Club Tito's in Goa. And the best part? You can earn money while enjoying these incredible experiences!
          </p>
        </div>
        <div className="counters">
          <div className="estar-counts">
            <img src="../assets/images/icons/bullet-white.svg" alt="img" />
            <h3 className="fw600">E-Star Count: 100</h3>
          </div>
          <div className="estar-counts">
            <img src="../assets/images/icons/bullet-white.svg" alt="img" />
            <h3 className="fw600">Total No Of Slots:03</h3>
          </div>
          <div className="estar-counts">
            <img src="../assets/images/icons/bullet-white.svg" alt="img" />
            <h3 className="fw600">Offers</h3>
          </div>
        </div>
        <div className="level-details">
          <p className="fw400">
            RED Card holders can be offered by some privileges like:
          </p>
        </div>
        <div className="pass__levels mb-5">
          <div className="pass__levels-single">
            <div className="pass__levels-silver-2 pass__levels-badge2">
              Red Carpet Entry
            </div>
            <div className="pass__levels-silver-3 pass__levels-badge3">
              Complimentary Champagne
            </div>
          </div>
        </div>
        <div className="signup-btn">
          {
            slotData ? <>
              {
                data.childs.length < 25 && slotData.length < 3 ?
                  <button onClick={checkLevel} style={{ border: 0 }} className="text-white button button--red button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Buy a Slot</span>
                      <span className="button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                  : <button style={{ border: 0 }} className="text-white button button--grey button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Purchased</span>
                    </span>
                  </button>
              }
            </> : <></>
          }
        </div>
      </div>
      <div className="dashboard-widget__right">
        <div className="cards123 cards123--levels cards123--silver">
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img2.png" alt="" />
            </div>
          </div>
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
