import React, { useEffect } from 'react'

export default function Pass() {

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
    $(function() {
      $( "#my_date_picker" ).datepicker({
        firstDay: 1,
        showOtherMonths: true,
      });
    });
    $(".cards-carousel").owlCarousel({
      center: true,
      loop: true,
      nav: true,
      autoplay: true,
      autoplayTimeout: 8000,
      autoplaySpeed: 2000,
      autoplayHoverPause: true,
      stagePadding: 100,
      responsive: {
        0: {
          items: 1,
          stagePadding: 0,
        },
        600: {
          items: 1.4,
          stagePadding: 25,
        },
        1200: {
          items: 1.5,
          stagePadding: 50,
        },
        
      },
    });
    `;
    script.async = true;
    document.body.appendChild(script);
  }, [])

  return (
    <>
      <div className="bg-setup">
        <div className="blobs">
        </div>
      </div>
      <div className="container">
        {/* Top actions btn row */}
        <div className="top-btns">
          <div className="top-btns__left">
            <a href="/">
              <img src="../assets/images/icons/back-icon.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <section className="passSection">
        <div className="container">
          <h1 className="page__title">The Best Way To Have Fun!</h1>
        </div>
        <div className="container-fluid px-0">
          <div className="cards-carousel-wrapper">
            <div className="cards-carousel owl-carousel owl-theme">
              <a href="/pass/dubai">
                <h2 className="passSectionCarouselTitle">DUBAI <span className="separator">|</span> <span className="logo" /><img src="../assets/images/logo-white.svg" alt="" /></h2>
                <div className="card111">
                  <img className="card111__img" src="../assets/images/enterx-cards/card-red-dubai.svg" alt="..." />
                  <img className="card111__barcode" src="../assets/images/enterx-cards/qr2.svg" alt="..." />
                  <div className="card111__limited">LIMITED OFFER PRICE WILL CHANGE SHORTLY</div>
                  <div className="card111__number">#000001</div>
                  <div className="card111__code">Referral Code: BIG799BAOH01</div>
                  <div className="card111__noOfSlots">03</div>
                  <div className="card111__totalUsed">01</div>
                  <div className="card111__lastUsed">March-30-2023</div>
                  <div className="card111__validTill">March-30-2023</div>
                  <div className="card111__starsRed"></div>
                  <div className="card111__starsSilver">Direct Sales: 25</div>
                  <div className="card111__starsGold">Direct Sales: 50</div>
                  <div className="card111__starsPlatinum">Direct Sales: 100</div>
                </div>
              </a>
              <a href="/pass/europe">
                <h2 className="passSectionCarouselTitle">EUROPE <span className="separator">|</span> <span className="logo" /><img src="../assets/images/logo-white.svg" alt="" /></h2>
                <div className="card111">
                  <img className="card111__img" src="../assets/images/enterx-cards/card-red-europe.svg" alt="..." />
                  <img className="card111__barcode" src="../assets/images/enterx-cards/qr2.svg" alt="..." />
                  <div className="card111__limited">LIMITED OFFER PRICE WILL CHANGE SHORTLY</div>
                  <div className="card111__number">#000001</div>
                  <div className="card111__code">Referral Code: BIG799BAOH01</div>
                  <div className="card111__noOfSlots">03</div>
                  <div className="card111__totalUsed">01</div>
                  <div className="card111__lastUsed">March-30-2023</div>
                  <div className="card111__validTill">March-30-2023</div>
                  <div className="card111__starsRed"></div>
                  <div className="card111__starsSilver">Direct Sales: 25</div>
                  <div className="card111__starsGold">Direct Sales: 50</div>
                  <div className="card111__starsPlatinum">Direct Sales: 100</div>
                </div>
              </a>
              <a href="/pass/london">
                <h2 className="passSectionCarouselTitle">LONDON <span className="separator">|</span> <span className="logo" /><img src="../assets/images/logo-white.svg" alt="" /></h2>
                <div className="card111">
                  <img className="card111__img" src="../assets/images/enterx-cards/card-red-london.svg" alt="..." />
                  <img className="card111__barcode" src="../assets/images/enterx-cards/qr2.svg" alt="..." />
                  <div className="card111__limited">LIMITED OFFER PRICE WILL CHANGE SHORTLY</div>
                  <div className="card111__number">#000001</div>
                  <div className="card111__code">Referral Code: BIG799BAOH01</div>
                  <div className="card111__noOfSlots">03</div>
                  <div className="card111__totalUsed">01</div>
                  <div className="card111__lastUsed">March-30-2023</div>
                  <div className="card111__validTill">March-30-2023</div>
                  <div className="card111__starsRed"></div>
                  <div className="card111__starsSilver">Direct Sales: 25</div>
                  <div className="card111__starsGold">Direct Sales: 50</div>
                  <div className="card111__starsPlatinum">Direct Sales: 100</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
