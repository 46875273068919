import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import QrReader from 'react-qr-scanner'
import { axiosClient } from '../../constants';
import { toast } from 'react-toastify';
import AdminTemplate from './AdminTemplate';
// To use Html5QrcodeScanner (more info below)
import { Html5QrcodeScanner } from "html5-qrcode";

// To use Html5Qrcode (more info below)
import { Html5Qrcode } from "html5-qrcode";

export default function AdminVerify() {
  const [data, setData] = useState(null);
  const { uuid } = useParams();
  const [showQR, setShowQR] = useState(false);
  const [QRData, setQRData] = useState("");
  const headers = {
    Authorization: "Token " + Cookies.get("ADMIN_TOKEN")
  }

  useEffect(() => {
    if (document.getElementById("reader")) {
      console.log("reader");
      function onScanSuccess(decodedText, decodedResult) {
        // handle the scanned code as you like, for example:
        window.location.href = "/admin/verify/" + decodedText;
      }

      function onScanFailure(error) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.warn(`Code scan error = ${error}`);
      }

      let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        /* verbose= */ false);
      html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    }
  }, [showQR])

  useEffect(() => {
    if (Cookies.get("ADMIN_TOKEN") && Cookies.get("ADMIN_DATA")) { } else {
      window.location.href = "/admin";
    }
    if (uuid) {
      axiosClient.get("/main/get-order-obj?order_uuid=" + uuid, { headers }).then(resp => {
        setData(resp.data.data);
      }).catch(error => {
        window.location.href = "/admin/verify";
        console.log(error);
      })
    }
  }, []);

  useEffect(() => {
    if (QRData) {
      window.location.href = "/admin/verify/" + QRData?.text;
    }
  }, [QRData]);

  const previewStyle = {
    height: 240,
    width: 320,
  }

  const confirmEntry = () => {
    let y = new Date().getFullYear();
    let m = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
    let d = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());

    const currDate = y + "-" + m + "-" + d;

    let flag = 0;

    data?.order_slots?.forEach((ele, index) => {
      if (ele.date == currDate) {
        flag = 1;
        axiosClient.post("/main/redeem-slot", { slot_date: ele.date, user_uuid: data?.user }, { headers }).then(resp => {
          toast.success("Slot Confirmed Successfully.");
          window.location.href = "/admin/dashboard";
        }).catch(error => {
          console.log(error);
        })
      } else {
        flag = 2;
      }
    })
    if (flag == 0) {
      toast.error("No Slots are available for Confirm!!!!");
    } else if (flag == 2) {
      toast.error('No Slots are available for today to Confirm!!!!');
    }

  }

  return (
    <AdminTemplate active="verify">
      <div className='scannerWrapper'>
        {
          uuid && data ?
            <div className='container d-flex flex-column justify-content-center align-items-center'>
              <div className='col-md-6'>
                <div className="form-group">
                  <div className="input-text-outer">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="fw700 fst-italic fz16 input-field__prefix">Email :</span>
                      <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.user?.email} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-group">
                  <div className="input-text-outer">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="fw700 fst-italic fz16 input-field__prefix">Phone :</span>
                      <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.user?.phone} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>

                <div className="form-group">
                  <div className="input-text-outer">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="fw700 fst-italic fz16 input-field__prefix">Pass ID :</span>
                      <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.order_id} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-group">
                  <div className="input-text-outer">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="fw700 fst-italic fz16 input-field__prefix">Location :</span>
                      <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.promo_pass?.pass_name} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-group">
                  <div className="input-text-outer">
                    <div className="input-bg">
                      <div className="input-bg-inner" />
                    </div>
                    <div className="input-field">
                      <span className="fw700 fst-italic fz16 input-field__prefix">Slots :</span>
                      <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={data?.order_slots?.map((ele, index) => index == data?.order_slots.length - 1 ? ele.date : ele.date + ",")} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-25 mx-auto input-button-wrapper btn-reponsive">
                <button onClick={confirmEntry} className="input-button">
                  <span className="input-button__inner">
                    <span className="input-button__text">Confirm Entry</span>
                    <span className="input-button__icon-outer">
                      <span className="input-button__icon-inner">
                        <i className="icon icon-double-chevron-dark" />
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
            :
            <div className='container text-white'>
              {!showQR && <div className="w-25 mx-auto input-button-wrapper btn-reponsive">
                <button onClick={() => { setShowQR(true); }} className="input-button">
                  <span className="input-button__inner">
                    <span className="input-button__text">Open Scanner</span>
                    <span className="input-button__icon-outer">
                      <span className="input-button__icon-inner">
                        <i className="icon icon-double-chevron-dark" />
                      </span>
                    </span>
                  </span>
                </button>
              </div>}
              {
                showQR && <>
                  {/* <QrReader
                  facingMode={"environment"}
                  delay={100}
                  style={previewStyle}
                  onError={(error) => { console.log(error); }}
                  onScan={(data) => { if (data) { setShowQR(false); setQRData(data); } }}
                /> */}
                  <div id="reader" className='mx-auto w-100'
                    style={{ maxWidth: "600px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                  </div>
                  <div className="w-25 mt-4 mx-auto input-button-wrapper btn-reponsive">
                    <button onClick={() => { setShowQR(false); }} className="input-button">
                      <span className="input-button__inner">
                        <span className="input-button__text">Close Scanner</span>
                        <span className="input-button__icon-outer">
                          <span className="input-button__icon-inner">
                            <i className="icon icon-double-chevron-dark" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </>
              }
              {
                QRData && <h2 className='mx-auto'>{QRData?.text}</h2>
              }
            </div>
        }
      </div>
    </AdminTemplate>
  )
}