import React, { useEffect, useState } from 'react'
import Splash from '../components/Splash';
import Cookies from 'js-cookie';

export default function Home() {
  const [splash, setSplash] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (Cookies.get("USER_TOKEN") && Cookies.get("USER_DATA")) {
      const mStep = JSON.parse(Cookies.get("USER_DATA"))?.step && parseInt(JSON.parse(Cookies.get("USER_DATA"))?.step);
      if (mStep && mStep < 4) {
        window.location.href = "/signup";
      } else if (mStep == 4) {
        window.location.href = "/pass";
      } else {
        window.location.href = "/dashboard";
      }
    }
  }, [])

  return (
    <>
      {
        splash ? <Splash /> :
          (
            <>
              <div className="bg-setup bg-setup--login">
                <div className="blobs" />
              </div>
              <div className="main-page">
                <div className="container">
                  {/* Top actions btn row */}
                  <div className="top-btns d-none d-lg-block">
                  </div>
                  {/* ===================== */}
                  <div className="sponsor">
                    <div className="sponsor__left position-relative sponsor__left1">
                      <div className="cards123 cards123--signup">
                        <div className="cards123__single">
                          <div className="cards123__bg" />
                          <div className="cards123__img">
                            <div className="cards123__bg-inner" />
                            <img src="/assets/images/signup/img1.png" loading="lazy" alt="" />
                          </div>
                        </div>
                        <div className="cards123__single">
                          <div className="cards123__bg" />
                          <div className="cards123__img">
                            <div className="cards123__bg-inner" />
                            <img src="/assets/images/signup/img2.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sponsor__right">
                      <div className="w-100 mx-auto sponsor__right__inner">
                        <div className="onboarding-section-content">
                          <div className="auth-logo reponsive-logo">
                            <img className="mx-auto" src="../assets/images/header-logo.svg" alt="logo" />
                          </div>
                          <h3 className="mx-auto fw700">
                            Welcome to EnterX
                          </h3>
                          <div className="mx-auto content-onboarding-text">
                            <p className="fw500">
                              Register now with EnterX and experience offers accross the globe
                            </p>
                          </div>
                          <div className="text-center onboarding-btn">
                            <a className="button button--red button--getstarted" href="/signup">
                              <span className="button__inner">
                                <span className="button__text">Register Now</span>
                                <span className="button__iconwrapperouter">
                                  <span className="button__iconwrapperinner">
                                    <i className="icon icon-double-chevron-dark" />
                                  </span>
                                </span>
                              </span>
                            </a>
                          </div>
                          <div className="mt-2 text-center login-text">
                            <p className="fw400">Already have an account ? 
                              <span><a className="fw500 ms-1" href="/login"> Login Here!</a></span>
                            </p>
                          </div>
                          <div className="mt-0 text-center login-text">
                            <p className="fw400">Register as 
                              <span><a className="fw500" href="/merchant"> Merchant!</a></span>
                            </p>
                          </div>
                          <div className="text-end onboarding-step-indicator d-none d-lg-block">
                            <div className="step-indicator">
                              <span className="active" />
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </>
          )
      }
    </>
  )
}