import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Pass from "./pages/Pass";
import Sponsor from "./pages/Sponsor";
import Dashboard from "./pages/Dashboard";
import Calendar from "./pages/Calendar";
import Offers from "./pages/Offers";
import Levels from "./pages/Levels";
import ThankYou from "./pages/ThankYou";
import ThankYouPass from "./pages/ThankYouPass";
import Login from "./pages/Login";
import Cookies from "js-cookie";
import Profile from "./pages/Profile";
import BookSlot from "./pages/BookSlot";
import SendEmail from "./pages/SendEmail";
import PassView from "./pages/PassView";
import { useEffect } from "react";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminVerify from "./pages/Admin/AdminVerify";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminUserView from "./pages/Admin/AdminUserView";
import AdminWithdrawal from "./pages/Admin/AdminWithdrawal";
import Merchant from "./pages/Merchant";
import AdminMerchant from "./pages/Admin/AdminMerchant";
import AdminOffer from "./pages/Admin/AdminOffer";
import AdminAddOffer from "./pages/Admin/AdminAddOffer";
import AdminViewOffer from "./pages/Admin/AdminViewOffer";
import OfferView from "./pages/OfferView";
import ForgotPassword from "./pages/ForgotPassword";
import PayDues from "./pages/PayDues";

export default function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = window.location.origin + "/assets/scripts/scripts.js";
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark" />
      <Routes>
        {/* Without Auth Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        {/* With Auth Routes  */}
        <Route path="/dashboard" element={<AuthRoute element={<Dashboard />} />} />
        <Route path="/offer/:uuid" element={<AuthRoute element={<OfferView />} />} />
        <Route path="/pass" element={<AuthRoute element={<Pass />} />} />
        <Route path="/pass/:passType" element={<AuthRoute element={<PassView />} />} />
        <Route path="/profile" element={<AuthRoute element={<Profile />} />} />
        <Route path="/send-email" element={<AuthRoute element={<SendEmail />} />} />
        <Route path="/book-slot" element={<AuthRoute element={<BookSlot />} />} />
        <Route path="/sponsor" element={<AuthRoute element={<Sponsor />} />} />
        <Route path="/calendar" element={<AuthRoute element={<Calendar />} />} />
        <Route path="/offers" element={<AuthRoute element={<Offers />} />} />
        <Route path="/levels" element={<AuthRoute element={<Levels />} />} />
        <Route path="/levels/:level" element={<AuthRoute element={<Levels />} />} />
        <Route path="/paydues" element={<AuthRoute element={<PayDues />} />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/thankyoupass" element={<ThankYouPass />} />
        <Route path="/merchant" element={<Merchant />} />

        {/* Admin Routes */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/verify" element={<AdminVerify />} />
        <Route path="/admin/verify/:uuid" element={<AdminVerify />} />
        <Route path="/admin/user" element={<AdminUserView />} />
        <Route path="/admin/user/:email" element={<AdminUserView />} />
        <Route path="/admin/withdrawal" element={<AdminWithdrawal />} />
        <Route path="/admin/merchant" element={<AdminMerchant />} />
        <Route path="/admin/offer" element={<AdminOffer />} />
        <Route path="/admin/offer/add" element={<AdminAddOffer />} />
        <Route path="/admin/offer/view/:uuid" element={<AdminViewOffer />} />
      </Routes>
    </>
  )
}

const AuthRoute = ({ element }) => {
  if (Cookies.get("USER_TOKEN")) {
    return element;
  } else {
    window.location.href = "/";
  }
}