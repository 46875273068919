import React from 'react'

export default function ThankYouPass() {
  return (
    <>
      <div className="bg-setup">
        <div className="blobs" />
      </div>
      <div className="main-page">
        <div className="container">
          <div className="thankyou-back">
            {/* Top actions btn row */}
            <div className="top-btns top-btns--withSidebar">
              <div className="top-btns__left">
                <a href="/dashboard">
                  <img src="../assets/images/icons/home.svg" alt="" />
                </a>
              </div>
              <div className="top-btns__right">
                <a href="#" className="notify">
                  <div className="rounded-circle dot" />
                  <img src="../assets/images/icons/bell-icon.svg" alt="" />
                </a>
                <a href="/" className="logout">
                  <img src="../assets/images/icons/logout-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="thanks-mobile-section">
              <div className="gradient-block-outer">
                <div className="gradient-block-bg">
                  <div className="gradient-block-bg-inner" />
                </div>
                <div className="gradient-block-inner center-section">
                  <h3 className="fw700">
                    Thank You For Your <br />
                    Purchase!
                  </h3>
                  {/* <p className="text-center fw500">Now You Own RED Level.</p> */}
                  <div className="centered-image">
                    <img className="mx-auto d-none d-md-block" src="/assets/images/thankyou2.svg" alt="img" />
                    <div className="d-md-none in-mobile">
                      <img src="/assets/images/thankyou2.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
