import React from 'react'

export default function Splash() {
  return (
    <div className="splash splash--loader">
      <div className="animating-bg">
        <img src="../assets/images/splash_logo.svg" alt="EnterX Logo" />
      </div>
    </div>
  )
}
