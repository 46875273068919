import React from 'react'
import { toast } from 'react-toastify';

export default function LevelGold({ data, slotData }) {

  const checkLevel = () => {
    if (data.childs.length >= 50) {
      window.location.href = "/book-slot"
    } else if (data.childs.length >= 25) {
      toast.error('You are Silver Level member. To book a slot in Gold you should have minimum 50 direct sales.')
    } else {
      toast.error('You are Red Level member. To book a slot in Gold you should have minimum 50 direct sales.')
    }
  }

  return (
    <div className="dashboard-widget dashboard-widget--levels">
      <div className="dashboard-widget__left levels-left pt-0">
        <div className="level-section">
          <h3 className="fw700">About GOLD</h3>
        </div>
        <div className="level-description">
          <p className="fw400">With the 'Gold Pass', not only can you earn up to $15,000 in direct sales but also enjoy privileges like an exclusive 'Red Carpet Entry.' Every year, you will get one free 'Bottle of Champagne' to enjoy at the venue. Gold Pass holders can live it up at the annual 'Yacht Party' with amazing views and the company of the most perceptive individuals.
          </p>
          <p className="fw400 mt-0">
            By making sales to friends, you can attend exciting events in Dubai and get free entry to Club Tito's in Goa. And the best part? You can earn money while enjoying these incredible experiences!
          </p>
        </div>
        <div className="counters">
          <div className="estar-counts">
            <img src="../assets/images/icons/bullet-circle.svg" alt="img" />
            <h3 className="fw600">Direct Sales: 50</h3>
          </div>
        </div>
        <div className="level-details">
          <p className="fw400">Gold Pass holders are offered by privileges like:</p>
        </div>
        <div className="pass__levels mb-5">
          <div className="pass__levels-single">
            <div className="pass__levels-gold-2 pass__levels-badge2">
              Red Carpet Entry
            </div>
            <div className="pass__levels-gold-3 pass__levels-badge3">
              Complimentary Champagne
            </div>
            <div className="pass__levels-gold-4 pass__levels-badge4">
              Yacht Party
            </div>
          </div>
        </div>
        <div className="signup-btn">
          {
            slotData ? <>
              {
                data.childs.length < 50 && slotData.length < 3 ?
                  <button onClick={checkLevel} style={{ border: 0 }} className="text-white button button--red button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Buy a Slot</span>
                      <span className="button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                  : <button style={{ border: 0 }} className="text-white button button--grey button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Purchased</span>
                    </span>
                  </button>
              }
            </> : <></>
          }
        </div>
      </div>
      <div className="dashboard-widget__right">
        <div className="cards123 cards123--levels cards123--gold">
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img2.png" alt="" />
            </div>
          </div>
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img3.png" alt="" />
            </div>
          </div>
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}