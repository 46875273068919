import React from 'react'
import AdminTemplate from './AdminTemplate'
import { useState } from 'react'
import { useEffect } from 'react';
import { axiosClient } from '../../constants';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export default function AdminWithdrawal() {
  const [data, setData] = useState(null);
  const [currElement, setCurrElement] = useState(null);
  const headers = { Authorization: "Token " + Cookies.get("ADMIN_TOKEN") }

  useEffect(() => {
    if (data) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#exampleDataTable');`;
      document.body.appendChild(script);
    }
  }, [data]);

  useEffect(() => {
    axiosClient.get("/accounts/get-withdraw-request", { headers }).then(resp => {
      console.log(resp.data.data);
      setData(resp.data.data);
    }).catch(error => {
      console.log(error);
    })
  }, []);

  const approveRequest = () => {
    axiosClient.patch("/accounts/withdraw-earning", { withdraw_id: currElement.uuid, is_approved: true }, { headers }).then(resp => {
      toast.success("Withdrawal Reuqest Approved Successfully.")
      window.location.reload();
    }).catch(error => {
      toast.error("Internal Server Error !!!");
    })
  }

  return (
    <AdminTemplate active='withdrawal'>
      <div className='container text-white'>
        <h2>All Withdrawal Requests</h2>
        <div className="gradient-block-outer">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner"></div>
          </div>
          <div className="gradient-block-inner doc-block2">
            <div className="table-responsive">
              <table id="exampleDataTable" className="table table-striped">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Fees</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.map((elem, index) => (
                      <tr key={index}>
                        <td>{elem?.user?.first_name} {elem?.user?.last_name}</td>
                        <td>{elem?.user?.email}</td>
                        <td>{elem?.user?.phone}</td>
                        <td>{elem.amount}</td>
                        <td>{elem.is_web3 ? "Metamask" : "Razorpay"}</td>
                        <td>{elem.fee}%</td>
                        <td>{elem.created_at}</td>
                        <td>
                          {
                            elem?.is_approved ? "Approved" :
                              <>
                                <button className='bg-transparent border-0' onClick={() => { setCurrElement(elem) }} data-bs-toggle="modal" data-bs-target="#confirmModal">
                                  <svg width='32' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17 9L9.99998 16L6.99994 13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </button>
                                <button className='bg-transparent border-0'>
                                  <svg width='32' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </button>
                              </>
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal For Cancel Slot */}
        <div className="modal fade modal--style1 confirmModal" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
              <div className="modal-header modal-header--noborder">
                <h2 className="modal-title text-center" id="confirmModalLabel">!!! Confirmation !!!</h2>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body text-center p-4 pb-5 px-5">
                <p>Are you sure you want to approve this transaction ?</p>
                <div className="row gx-lg-5 mt-4">
                  <div className="col-lg-6">
                    <button data-bs-dismiss="modal" aria-label="Close" className="button button-slide text-white border-0 bg-transparent button--getstarted">
                      <span className="button__inner button__inner--cancel">
                        <span className="button__text">No! Don’t want to appove it.</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <button onClick={() => { approveRequest() }} className="button button-slide text-white border-0 bg-transparent button--red button--getstarted">
                      <span className="button__inner">
                        <span className="button__text">Yes! Approve it.</span>
                        <span className="button-slide__arrow button__iconwrapperouter">
                          <span className="button__iconwrapperinner">
                            <i className="icon icon-double-chevron-dark" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminTemplate>
  )
}