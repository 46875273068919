import React from 'react'

export default function LevelRed({ slotData }) {

  return (
    <div className="dashboard-widget dashboard-widget--levels">
      <div className="dashboard-widget__left levels-left pt-0">
        <div className="level-section">
          <h3 className="fw700">About RED</h3>
        </div>
        <div className="level-description">
          <p className="fw400">
            The Red Pass ensures 'Hassle-Free Access' to a curated selection of top-tier clubs, granting members the
            freedom to explore and indulge in the vibrant energy of nightlife. Say goodbye to long queues, the Red
            Pass opens doors to unforgettable nights filled with music, dancing, and unforgettable memories. Become a
            Red Pass member today and embrace a lifestyle of exclusivity, where the party never ends.
          </p>
          <p className="fw400 mt-0">
            By making sales to friends, you can gain access to a range of events in Dubai while also enjoying a
            complimentary offer for free entry to Club Tito's in Goa. Moreover, you have the opportunity to earn money
            while indulging in these remarkable experiences!
          </p>
        </div>
        <div className="counters">
          <div className="estar-counts">
            <img src="../assets/images/icons/bullet-red.svg" alt="img" />
            <h3 className="fw600">Payment: $100</h3>
          </div>
        </div>
        <div className="level-details">
          <p className="fw400">
            Red Pass holders are offered privileges like:
          </p>
        </div>
        <div className="pass__levels mb-5">
          <div className="pass__levels-single">
            <div className="pass__levels-red-2 pass__levels-badge2">
              Free Entry
            </div>
          </div>
        </div>
        <div className="buttons">
          {
            slotData ? <>
              {
                slotData.length < 3 ?
                  <button onClick={() => { window.location.href = "/book-slot" }} style={{ border: 0 }} className="text-white button button--red button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Buy a Slot</span>
                      <span className="button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                  : <button style={{ border: 0 }} className="text-white button button--grey button--getstarted levels-btn">
                    <span className="button__inner">
                      <span className="button__text">Purchased</span>
                    </span>
                  </button>
              }
            </> : <></>
          }

        </div>
      </div>
      <div className="dashboard-widget__right">
        <div className="cards123 cards123--levels cards123--red">
          <div className="cards123__single">
            <div className="cards123__bg" />
            <div className="cards123__img">
              <div className="cards123__bg-inner" />
              <img src="../assets/images/levels/img1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}