import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../constants';
import { copyContent } from "../constants";
import { toast } from 'react-toastify';
import axios from 'axios';
import QRCode from 'react-qr-code';

export default function Profile() {
  const token = Cookies.get("USER_TOKEN");
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [userData, setUserData] = useState(null);
  const [passData, setPassData] = useState(null);
  const [sponsorData, setSponsorData] = useState(null);
  const [input, setInput] = useState({
    account_number: '',
    bank_name: '',
    ifsc_code: '',
    account_number1: ''
  })

  useEffect(() => {
    if (sponsorData) {
      const script = document.createElement("script");
      script.innerHTML = `
      (function($){

        $(function() {

          $('.toggle-social-share').click(function(){
            $('.social-share').toggle('slow');
          });


          var datascource = {
            'name': '',
            'title': '${sponsorData?.email}',
            "children": [
              ${sponsorData.childs?.length >= 0 ?
          sponsorData.childs.map(ele => {
            return `{ 'name': '', 'title': '${ele.email}',
                          "children": [
                            ${ele.childs.map(ele2 => {
              return `{ 'name': '', 'title': '${ele2.email}' }`;
            })
              }
                          ]
                        }`
          }) : ""}
            ]
          };
          
          // Vertical Tree
          var verticalLevel = 2;
          if( $(window).width() >= 992){
            verticalLevel = 4;
          }
            
          var oc = $('.chart-container').orgchart({
            'pan': true,
            'zoom': true,
            'data' : datascource,
            'nodeContent': 'title',
            'verticalLevel': verticalLevel,
            'zoominLimit': 1,
          });

        });

      })(jQuery);`;
      script.type = "text/javascript";
      document.body.append(script);
    }
  }, [sponsorData]);

  useEffect(() => {
    getData();
    getPassData();
    getSponsorData();
  }, []);

  const getPassData = async () => {
    try {
      const response = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      let mData = response.data?.data;
      // const urlData = mData.uuid;
      // const mQrCode = await axios.get("https://quickchart.io/qr?format=svg&size=100&text=" + urlData);
      setPassData({ ...mData });
    } catch (error) {
      console.log(error);
    }
  }

  const dateTime = (data) => {
    if (!data) return "";
    const monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date(data);
    let dd = date.getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let mm = (date.getMonth());
    return monthsArray[mm] + "-" + dd + "-" + (date.getFullYear() + 10);
  }

  const getData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-user-details", { headers: { Authorization: "Token " + token } });
      setUserData(response.data.data);
    } catch (error) {

    }
  }

  const getSponsorData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      setSponsorData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getBankData = async () => {
    try {
      const response = await axiosClient.get("/accounts/updatebankdetails", { headers: { Authorization: "Token " + token } });
      setInput({
        account_number: response.data?.data?.account_number,
        account_number1: response.data?.data?.account_number,
        bank_name: response.data?.data?.bank_name,
        ifsc_code: response.data?.data?.ifsc_code
      })
    } catch (error) {
      console.log(error);
    }
  }

  const logout = () => {
    Cookies.remove("USER_DATA");
    Cookies.remove("USER_TOKEN");
    window.location.href = "/";
  }

  const onChangeHand = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  const submitBankDetails = async (e) => {
    e.preventDefault();
    if (input.account_number === input.account_number1) {
      try {
        const mFData = new FormData();
        mFData.append("account_number", input.account_number);
        mFData.append("bank_name", input.bank_name);
        mFData.append("ifsc_code", input.ifsc_code);

        const response = await axiosClient.post("/accounts/updatebankdetails", mFData, { headers: { Authorization: "Token " + token } });
        toast.success(response.data?.message);
        setShow2(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Both account numbers must be same!!!")
    }
  }

  return (
    <>
      <div>
        <div className="bg-setup">
          <div className="blobs" />
        </div>
        <main className="overflow-hidden main-page">
          <div className="container">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a href="/dashboard">
                  <img src="../assets/images/icons/home.svg" alt="" />
                </a>
              </div>
              <div className="top-btns__right">
                <button className="notify bg-transparent border-0">
                  <div className="rounded-circle dot" />
                  <img src="../assets/images/icons/bell-icon.svg" alt="" />
                </button>
                <button onClick={logout} className="logout bg-transparent border-0">
                  <img src="../assets/images/icons/logout-icon.svg" alt="" />
                </button>
              </div>
            </div>
            {/* ===================== */}
            <div className="page-myprofile--outer">
              <div className="page-myprofile">
                <h1 className="page-title">My Profile</h1>
                <div className="page-myprofile-inner">
                  <div className="myprofile__left">
                    <div className="form-group">
                      <div className="input-text-outer">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="fw700 fst-italic fz16 input-field__prefix">Referrel ID:</span>
                          <input type="text" disabled={true} className="fz16 fw700 fst-italic" value={userData?.email} />
                          <button className="cursor-pointer input-field__suffix toggle-social-share bg-transparent border-0"><i className="icon icon-share" /></button>
                          <button onClick={() => { copyContent(window.location.origin + "/signup?refferid=" + userData?.email) }} className="cursor-pointer input-field__suffix bg-transparent border-0"><i className="icon icon-copy" /></button>
                        </div>
                      </div>
                    </div>
                    <div className="social-share" style={{ display: 'none' }}>
                      <div className="social-share__icons">
                        <a className="social-share__item" target="_blank"><img src="../assets/images/icons/social-share-google.svg" alt="Google" /></a>
                        <a className="social-share__item" target="_blank"><img src="../assets/images/icons/social-share-telegram.svg" alt="Telegram" /></a>
                        <a className="social-share__item" target="_blank"><img src="../assets/images/icons/social-share-instagram.svg" alt="Instagram" /></a>
                        <a className="social-share__item" target="_blank"><img src="../assets/images/icons/social-share-whatsapp.svg" alt="WhatsApp" /></a>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-text-outer">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="fz16 input-field__prefix">Sponsor ID:</span>
                          <input type="text" disabled={true} className="fz16" value={userData?.sponsor_address} />
                          <button className="input-field__suffix bg-transparent border-0"><i className="icon icon-copy" /></button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='row mt-3'>
                      <div className="col-6 input-button-wrapper">
                        <button onClick={() => { setShow1(true); }} className="input-button button--red">
                          <span className="input-button__inner">
                            <span className="text-white fz20 fw600 input-button__text">Show Downline</span>
                            <span className="button__iconwrapperouter">
                              <span className="button__iconwrapperinner">
                                <i className="icon icon-double-chevron-dark" />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="col-6 input-button-wrapper">
                        <button onClick={() => { sponsorData?.childs?.length > 0 ? window.location.href = "/send-email" : toast.error("You Have Not Refer To Anyone Yet, Please Refer To Continue!!!") }} className="input-button button--red">
                          <span className="input-button__inner">
                            <span className="text-white fz20 fw600 input-button__text">Send Email</span>
                            <span className="button__iconwrapperouter">
                              <span className="button__iconwrapperinner">
                                <i className="icon icon-double-chevron-dark" />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div> */}
                    {/* <div className="myprofile__illustration">
                      <img src="../assets/images/sponsor-illustration.svg" alt="" />
                    </div> */}
                    <div className='my-pass-details mt-4'>
                      <h2 className="passSectionCarouselTitle">{passData?.promo_pass?.pass_name} <span className="separator">|</span> <span className="logo" /><img src="/assets/images/logo-white.svg" alt="" /></h2>
                      <div className="card111 card111--1">
                        <img className="card111__img" src={`/assets/images/enterx-cards/card-red-${passData?.promo_pass?.pass_name?.toLowerCase()}.svg`} alt="..." />
                        <div className="mybarcode-profile card111__barcode h-100 w-100">
                          {passData?.uuid && <QRCode value={passData?.uuid} />}
                        </div>
                        {/* <img className="card111__barcode" src="../assets/images/enterx-cards/qr2.svg" alt="..." /> */}
                        {/* <div className="card111__limited">LIMITED OFFER PRICE WILL CHANGE SHORTLY</div> */}
                        <div className="card111__number">{passData?.order_id}</div>
                        {/* <div className="card111__code">Referral Code: BIG799BAOH01</div> */}
                        <div className="card111__noOfSlots">{passData?.order_slots?.length}</div>
                        <div className="card111__totalUsed">0</div>
                        <div className="card111__lastUsed"></div>
                        <div className="card111__validTill">{dateTime((passData?.created_at))}</div>
                        <div className="card111__starsRed"></div>
                        <div className="card111__starsSilver" style={{ fontSize: "10px" }}>Direct Sales: 25</div>
                        <div className="card111__starsGold" style={{ fontSize: "10px" }}>Direct Sales: 50</div>
                        <div className="card111__starsPlatinum" style={{ fontSize: "10px" }}>Direct Sales: 100</div>
                      </div>
                    </div>
                  </div>
                  <div className="myprofile__right">
                    <div>
                      <div className="input-text-outer mb-4">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="input-field__prefix"><i className="icon icon-user" /></span>
                          <input type="text" placeholder="First Name" disabled value={userData?.first_name} />
                        </div>
                      </div>
                      <div className="input-text-outer mb-4">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="input-field__prefix"><i className="icon icon-user" /></span>
                          <input type="text" placeholder="Last Name" disabled value={userData?.last_name} />
                        </div>
                      </div>
                      <div className="input-text-outer mb-4">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="input-field__prefix"><img src="../assets/images/icons/email.svg" /></span>
                          <input type="text" placeholder="Email" disabled value={userData?.email} />
                        </div>
                      </div>
                      <div className="input-text-outer mb-4">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="input-field__prefix"><img src="../assets/images/icons/phone.svg" /></span>
                          <input type="text" placeholder="Phone Number" disabled value={userData?.phone} />
                        </div>
                      </div>
                      <div className="d-flex signup_detail mb-4">
                        <label className="fw500 d-flex">
                          <img src="../assets/images/icons/user.svg" alt="#" />Gender
                        </label>
                        <div className="gender-checkboxes d-flex justify-content-end">
                          <div className="check-container male-check">
                            <label className=" ">
                              <input type="checkbox" checked={userData?.gender === "male" ? true : false} />
                              <span className="checkmark" />
                              <div className="fz16 fw400">
                                Male
                              </div>
                            </label>
                          </div>
                          <div className="check-container male-check">
                            <label className=" ">
                              <input type="checkbox" checked={userData?.gender === "female" ? true : false} />
                              <span className="checkmark" />
                              <div className="fz16 fw400">
                                Female
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="input-text-outer mb-4">
                        <div className="input-bg">
                          <div className="input-bg-inner" />
                        </div>
                        <div className="input-field">
                          <span className="input-field__prefix"><i className="icon icon-calendar" /></span>
                          <input type="text" placeholder="DOB" disabled value={userData?.date_of_birth} />
                        </div>
                      </div>
                      <div className="signup-btn">
                        <button onClick={() => { getBankData(); setShow2(true); }} className="input-button button--red">
                          <span className="input-button__inner">
                            <span className="text-white fz20 fw600 input-button__text">Bank Information</span>
                            <span className="button__iconwrapperouter">
                              <span className="button__iconwrapperinner">
                                <i className="icon icon-double-chevron-dark" />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Modal */}
        <div className={`modal fade modal--style2 ${show1 ? 'show' : ''}`} style={{ display: `${show1 ? 'block' : 'none'}` }} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modalDownlineLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="modalDownlineLabel">Downline</h1>
                <button onClick={() => setShow1(false)} type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span>×</span></button>
              </div>
              <div className="modal-body text-center">
                <div className="node-structure">
                  <div className="chart-container" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <div className={`modal fade modal--style2 ${show2 ? 'show' : ''}`} style={{ display: `${show2 ? 'block' : 'none'}` }} data-bs-backdrop="static">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="modalBankInfoLabel">Bank Information</h1>
                <button type="button" className="close" onClick={() => { setShow2(false); }}><span>×</span></button>
              </div>
              <div className="modal-body">
                <div className="form-bankinfo">
                  <form className="form" onSubmit={submitBankDetails}>
                    <div className="input-label">
                      IFSC Code <span className="required">*</span>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <input type="text" placeholder="IFSC Code" value={input.ifsc_code} name="ifsc_code" onChange={onChangeHand} />
                      </div>
                    </div>
                    <div className="input-label">
                      Bank Name <span className="required">*</span>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <input type="text" placeholder="Bank Name" value={input.bank_name} name="bank_name" onChange={onChangeHand} />
                      </div>
                    </div>
                    <div className="input-label">
                      Account Number <span className="required">*</span>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <input type="text" placeholder="Account Number" value={input.account_number} name="account_number" onChange={onChangeHand} />
                      </div>
                    </div>
                    <div className="input-label">
                      Confirm Account Number <span className="required">*</span>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <input type="text" placeholder="Confirm Account Number" value={input.account_number1} name="account_number1" onChange={onChangeHand} />
                      </div>
                    </div>
                    <div className="d-flex signup_detail mb-4">
                      <div className="gender-checkboxes d-flex justify-content-end">
                        <div className="check-container male-check">
                          <label className=" ">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            <div className="fz16 fw400">
                              Lorem ipsum set amet
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="signup-btn">
                      <button className="input-button button--red">
                        <span className="input-button__inner">
                          <span className="text-white fz20 fw600 input-button__text">Save Bank Information</span>
                          <span className="button__iconwrapperouter">
                            <span className="button__iconwrapperinner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>{/* /. form-bankinfo */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
