import React, { useEffect, useState } from 'react'
import { APP_CONFIG, axiosClient } from '../../constants';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Signup7 from './Signup7';

export default function Signup1({ setStep, input1, setInput1 }) {
  const [isAdult, setIsAdult] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [joinNowFlag, setJoinNowFlag] = useState(false);
  const [input, setInput] = useState({
    sponsor: '',
  });
  const [searchParams] = useSearchParams();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isAdult) {
      toast.error("Please Accept India 18+");
      return;
    }
    if (!isPrivacy) {
      toast.error("Please Accept Terms and Conditions");
      return;
    }
    if (!input.sponsor) {
      toast.error("Please Enter Sponsor Code !!!!");
      return;
    }
    axiosClient.get("/accounts/is_user_valid?email=" + input.sponsor).then(resp => {
      if (resp?.data?.data?.status) {
        setInput1({ ...input1, referralEmail: input.sponsor });
        setStep(3);
      } else {
        toast.warning("This sponsor email is invalid!!!");
      }
    }).catch(() => { });

  }

  useEffect(() => {
    if (searchParams.get("refferid")) {
      setInput({ ...input, sponsor: searchParams.get("refferid") });
    }
  }, []);

  const joinNow = () => {
    setJoinNowFlag(true);
  }

  return (
    <>
      {
        joinNowFlag ?
          <Signup7 setJoinNowFlag={setJoinNowFlag} setStep={setStep} input1={input1} setInput1={setInput1} />
          :
          <div>
            <div className="bg-setup bg-setup--login">
              <div className="blobs" />
            </div>
            <div className="main-page">
              <div className="container">
                {/* Top actions btn row */}
                <div className="top-btns">
                  <div className="top-btns__left">
                    <a className='cursor-pointer' role="button" onClick={() => setStep(1)}>
                      <img src="./assets/images/icons/back-icon.svg" alt="" />
                    </a>
                  </div>
                </div>
                {/* ===================== */}
                <div className="sponsor">
                  <div className="sponsor__left">
                    <div className="cards123 cards123--signup">
                      <div className="cards123__single">
                        <div className="cards123__bg" />
                        <div className="cards123__img">
                          <div className="cards123__bg-inner" />
                          <img src="../assets/images/signup/img1.png" alt="" />
                        </div>
                      </div>
                      <div className="cards123__single">
                        <div className="cards123__bg" />
                        <div className="cards123__img">
                          <div className="cards123__bg-inner" />
                          <img src="../assets/images/signup/img2.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsor__right">
                    <div className="w-100 mx-auto sponsor__right__inner">
                      <div className="auth-logo">
                        <img src="./assets/images/header-logo.svg" alt="logo" />
                      </div>
                      <div className="sign-up-header">
                        <h3 className="fw700">A Warm Welcome from EnterX!</h3>
                        <p className="fw500">
                          Sign Up Now!
                        </p>
                      </div>
                      <form className="form" onSubmit={onSubmit}>
                        <div className="form-group form-group--leftIcon mb-3">
                          <div className="input-text-outer">
                            <div className="input-bg">
                              <div className="input-bg-inner" />
                            </div>
                            <div className="input-field">
                              <span className="input-field__prefix"><i className="icon icon-user" /></span>
                              <input type="text" value={input.sponsor} onChange={(e) => { setInput({ ...input, sponsor: e.target.value }) }} placeholder="Enter Referral Email" />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 fz20">
                          Don't have a Referral Email? <a className="text-red underlined" role='button' onClick={joinNow}>Join Now</a>
                        </div>
                        <div className="form-group form-group--leftIcon mb-0">
                          <div className="input-text-outer">
                            <div className="input-field px-0">
                              <span className="input-field__suffix">
                                {/* switch toggle */}
                                <label className="gradient-block-outer switch">
                                  <div className="gradient-block-bg">
                                    <div className="gradient-block-bg-inner" />
                                  </div>
                                  <input type="checkbox" />
                                  <span className="gradient-block-inner slider" />
                                </label>
                                {/* -------------- */}
                              </span>
                              <div className="fz16 no-input-text">I’m not a Robot.</div>
                              <a href="#" className="input-field__suffix"><img src="../assets/images/captcha-emoji.svg" alt="" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="checkbox-cover">
                          <div className="check-container">
                            <label className=" ">
                              <input type="checkbox" value={isAdult} onChange={() => setIsAdult(!isAdult)} />
                              <span className="checkmark" />
                              <div className="fz16 fw400">
                                I am at least 18 years of age
                              </div>
                            </label>
                          </div>
                          <div className="check-container">
                            <label>
                              <input type="checkbox" value={isPrivacy} onChange={() => setIsPrivacy(!isPrivacy)} />
                              <span className="checkmark" />
                              <div className="fz16 fw400">
                                By clicking You agree to
                                <img className="logo-image-small" src="./assets/images/header-logo.svg" alt="logo" /> <a className="underlined" href="">Privacy Policy</a> and <a className="underlined" href="">Terms & Conditions.</a>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="input-button-wrapper">
                          <button className="input-button">
                            <span className="input-button__inner">
                              <span className="input-button__text">Next</span>
                              <span className="input-button__icon-outer">
                                <span className="input-button__icon-inner">
                                  <i className="icon icon-double-chevron-dark" />
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}
