import React from 'react'
import { copyContent } from '../../constants'
import { toast } from 'react-toastify'

export default function PassDetailsComponent({ data, sponsorData }) {
  return (
    <>
      {data && (
        <>
          {
            parseInt(sponsorData?.childs?.length) < 25 ? <RedCard data={data} sponsorData={sponsorData} />
              : parseInt(sponsorData?.childs?.length) < 50 ? <SilverCard data={data} sponsorData={sponsorData} />
                : parseInt(sponsorData?.childs?.length) < 100 ? <GoldCard data={data} sponsorData={sponsorData} />
                  : <PlatinumCard data={data} sponsorData={sponsorData} />
          }
        </>
      )}
    </>
  )
}

const RedCard = ({ data, sponsorData }) => (
  <div className="passDetails2 passDetails2--red">
    <div className="passDetails2__inner">
      <img className="passDetails2__label" alt="..." src="/assets/images/pass/label-red.svg" />
      <div className="passDetails2__title">PASS DETAILS</div>
      <div className="passDetails2__underTitle">
        <div className="passDetails2__block cursor-pointer" data-bs-toggle="modal" data-bs-target="#paymentMethodModal">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="../assets/images/pass/pass-star-red.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {data?.redeemable_earning} E-Stars
              </div>
              <div className="passDetails2__starText">
                Stars Earned
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block block-disabled cursor-pointer" data-bs-toggle="modal" data-bs-target="#underMaturity">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="/assets/images/pass/pass-star-grey.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount" style={{ webkitTextFillColor: 'grey' }}>
                {data?.earning} E-Stars
              </div>
              <div className="passDetails2__starText" style={{ webkitTextFillColor: 'grey' }}>
                Under Maturity
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__user" alt="..." src="../assets/images/pass/icon-user.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {sponsorData?.childs?.length}
              </div>
              <div className="passDetails2__starText">
                Direct Sale
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="passDetails2__referral">
        <div className="gradient-block-bg">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner">
          <span className="name">Referrel ID: {data?.email}</span>
          <a className="btnCircle" role="button" onClick={() => copyContent(window.location.origin + "/signup?refferid=" + data?.email)}>
            <span className="btnCircle__inner">
              <img src="../assets/images/pass/icon-copy.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
      <div className="passDetails2__shareTxt">
        Make Money <span>Refer now!</span>
      </div>
      <div className="passDetails2__share">
        <a className="btnCircle" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.origin + "/signup?refferid=" + data?.email} target='__blank'>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-fb.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-li.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-in.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-tw.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-wa.svg" alt="" />
          </span>
        </a>
      </div>
      <a className="button button--getstarted" href="/levels">
        <span className="button__inner">
          <span className="button__text">Wants to know more?</span>
          <span className="button__iconwrapperouter">
            <span className="button__iconwrapperinner">
              <i className="icon icon-double-chevron-dark" />
            </span>
          </span>
        </span>
      </a>
    </div>
  </div>
)

const SilverCard = ({ data, sponsorData }) => (
  <div className="passDetails2 passDetails2--silver">
    <div className="passDetails2__inner">
      <img className="passDetails2__label" alt="..." src="/assets/images/pass/label-silver.svg" />
      <div className="passDetails2__title">PASS DETAILS</div>
      <div className="passDetails2__underTitle">
        <div className="passDetails2__block cursor-pointer">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="../assets/images/pass/pass-star-silver.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {data?.redeemable_earning} E-Stars
              </div>
              <div className="passDetails2__starText">
                Stars Earned
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block cursor-pointer">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="/assets/images/pass/pass-star-grey.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount" style={{ webkitTextFillColor: 'grey' }}>
                {data?.earning} E-Stars
              </div>
              <div className="passDetails2__starText" style={{ webkitTextFillColor: 'grey' }}>
                Stars Earned
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__user" alt="..." src="../assets/images/pass/icon-user.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {sponsorData?.childs?.length}
              </div>
              <div className="passDetails2__starText">
                Direct Sale
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="passDetails2__referral">
        <div className="gradient-block-bg">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner">
          <span className="name">Referrel ID: {data?.email}</span>
          <a className="btnCircle" role="button" onClick={() => copyContent(window.location.origin + "/signup?refferid=" + data?.email)}>
            <span className="btnCircle__inner">
              <img src="../assets/images/pass/icon-copy.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
      <div className="passDetails2__shareTxt">
        Make Money <span>Refer now!</span>
      </div>
      <div className="passDetails2__share">
        <a className="btnCircle" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.origin + "/signup?refferid=" + data?.email} target='__blank'>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-fb.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-li.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-in.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-tw.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-wa.svg" alt="" />
          </span>
        </a>
      </div>
      <a className="button button--getstarted" href="/levels">
        <span className="button__inner">
          <span className="button__text">Wants to know more?</span>
          <span className="button__iconwrapperouter">
            <span className="button__iconwrapperinner">
              <i className="icon icon-double-chevron-dark" />
            </span>
          </span>
        </span>
      </a>
    </div>
  </div>
)

const GoldCard = ({ data, sponsorData }) => (
  <div className="passDetails2 passDetails2--gold">
    <div className="passDetails2__inner">
      <img className="passDetails2__label" alt="..." src="../assets/images/pass/label-gold.svg" />
      <div className="passDetails2__title">PASS DETAILS</div>
      <div className="passDetails2__underTitle">
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="../assets/images/pass/pass-star-gold.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {data?.earning} E-Stars
              </div>
              <div className="passDetails2__starText">
                Stars Earned
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__user" alt="..." src="../assets/images/pass/icon-user.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {sponsorData?.childs?.length}
              </div>
              <div className="passDetails2__starText">
                Direct Sale
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="passDetails2__referral">
        <div className="gradient-block-bg">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner">
          <span className="name">Referrel ID: {data?.email}</span>
          <a className="btnCircle" role="button" onClick={() => copyContent(window.location.origin + "/signup?refferid=" + data?.email)}>
            <span className="btnCircle__inner">
              <img src="../assets/images/pass/icon-copy.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
      <div className="passDetails2__shareTxt">
        Make Money <span>Refer now!</span>
      </div>
      <div className="passDetails2__share">
        <a className="btnCircle" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.origin + "/signup?refferid=" + data?.email} target='__blank'>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-fb.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-li.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-in.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-tw.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-wa.svg" alt="" />
          </span>
        </a>
      </div>
      <a className="button button--getstarted" href="/levels">
        <span className="button__inner">
          <span className="button__text">Wants to know more?</span>
          <span className="button__iconwrapperouter">
            <span className="button__iconwrapperinner">
              <i className="icon icon-double-chevron-dark" />
            </span>
          </span>
        </span>
      </a>
    </div>
  </div>
)

const PlatinumCard = ({ data, sponsorData }) => (
  <div className="passDetails2 passDetails2--platinum">
    <div className="passDetails2__inner">
      <img className="passDetails2__label" alt="..." src="../assets/images/pass/label-platinum.svg" />
      <div className="passDetails2__title">PASS DETAILS</div>
      <div className="passDetails2__underTitle">
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__star" alt="..." src="../assets/images/pass/pass-star-platinum.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {data?.earning} E-Stars
              </div>
              <div className="passDetails2__starText">
                Stars Earned
              </div>
            </div>
          </div>
        </div>
        <div className="passDetails2__block">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner" />
          </div>
          <div className="gradient-block-inner">
            <img className="passDetails2__user" alt="..." src="../assets/images/pass/icon-user.svg" />
            <div className="passDetails2__text2">
              <div className="passDetails2__starCount">
                {sponsorData?.childs?.length}
              </div>
              <div className="passDetails2__starText">
                Direct Sale
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="passDetails2__referral">
        <div className="gradient-block-bg">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner">
          <span className="name">Referrel ID: {data?.email}</span>
          <a className="btnCircle" role="button" onClick={() => copyContent(window.location.origin + "/signup?refferid=" + data?.email)}>
            <span className="btnCircle__inner">
              <img src="../assets/images/pass/icon-copy.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
      <div className="passDetails2__shareTxt">
        Make Money <span>Refer now!</span>
      </div>
      <div className="passDetails2__share">
        <a className="btnCircle" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.origin + "/signup?refferid=" + data?.email} target='__blank'>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-fb.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-li.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-in.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-tw.svg" alt="" />
          </span>
        </a>
        <a className="btnCircle" href>
          <span className="btnCircle__inner">
            <img src="../assets/images/pass/icon-wa.svg" alt="" />
          </span>
        </a>
      </div>
      <a className="button button--getstarted" href="/levels">
        <span className="button__inner">
          <span className="button__text">Wants to know more?</span>
          <span className="button__iconwrapperouter">
            <span className="button__iconwrapperinner">
              <i className="icon icon-double-chevron-dark" />
            </span>
          </span>
        </span>
      </a>
    </div>
  </div>
)