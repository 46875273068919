import React, { useEffect, useState } from 'react'
import { APP_CONFIG, axiosClient, copyContent } from '../constants';
import Cookies from 'js-cookie';

const dataTemplate = {
  name: '',
  title: '',
  children: []
}

export default function Sponsor() {
  const [data, setData] = useState(null);
  const token = Cookies.get("USER_TOKEN");
  const userData = JSON.parse(Cookies.get("USER_DATA") ? Cookies.get("USER_DATA") : "");

  useEffect(() => {
    if (data) {
      if (!document.getElementById("mySponsorScript")) {
        const script = document.createElement("script");
        script.innerHTML = `
      (function($){

        $(function() {
          $('.toggle-social-share').click(function(){
            $('.social-share').toggle('slow');
          });
          var datascource = ${JSON.stringify(data)};
          
          // Vertical Tree
          var verticalLevel = 2;
          if( $(window).width() >= 992){
            verticalLevel = 10;
          }
            
          var oc = $('.chart-container').orgchart({
            'pan': true,
            'zoom': true,
            'data' : datascource,
            'nodeContent': 'title',
            'verticalLevel': verticalLevel,
            'zoominLimit': 1,
          });

        });

      })(jQuery);
      `;
        script.id = "mySponsorScript";
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [data]);

  const transformData = (inputData) => {
    const outputData = { ...dataTemplate };
    outputData.name = '';
    outputData.title = inputData.first_name + " " + inputData.last_name;
    outputData.email = inputData.email;
    if (inputData.childs && inputData.childs.length > 0) {
      outputData.children = inputData.childs.map(transformData);
    }
    return outputData;
  }

  const getData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      setData(response.data.data);
      const mTemp = transformData(response.data.data);
      mTemp.title = mTemp.title + ` <br/>${mTemp.email}`;
      mTemp.children.forEach((ele, index) => {
        console.log(ele);
        mTemp.children[index].title = ele.title + ` <br/>${ele.email}`;
      });
      setData(mTemp);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <main className="overflow-hidden main-page">
        <div className="container">
          {/* Top actions btn row */}
          <div className="top-btns">
            <div className="top-btns__left">
              <a href="/dashboard">
                <img src="./assets/images/icons/home.svg" alt="" />
              </a>
            </div>
            <div className="top-btns__right">
              <a href="#" className="notify">
                <div className="rounded-circle dot" />
                <img src="./assets/images/icons/bell-icon.svg" alt="" />
              </a>
              <a href="/" className="logout">
                <img src="./assets/images/icons/logout-icon.svg" alt="" />
              </a>
            </div>
          </div>
          {/* ===================== */}
          <div className="sponsor d-block">
            {/* <div className="sponsor__left"> */}
            <h1 className="text-center fw700">About Your Downline</h1>
            {/* </div> */}
            {/* <div className="sponsor__right"> */}
            {/* <div className="w-100 mx-auto sponsor__right__inner"> */}

            <div className="node-structure flex-column">
              <div className="chart-container"></div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </main>
    </>
  )
}
